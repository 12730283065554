import React, {
  ReactElement,
  ChangeEventHandler,
  useEffect,
  useState
} from 'react';
import { Grid } from '@material-ui/core';
import FieldsPanel from './FieldsPanel';
import MatInput from './MatInput';
import { ITeamSettings } from 'src/redux/model';
import { MessageOutlined } from '@material-ui/icons';

type PropTypes = {
  values: ITeamSettings;
  handleChange: ChangeEventHandler;
  checkTouched: Function;
  saveTeamSettings: Function;
  settingsLoading: boolean;
};

export default function({
  values,
  handleChange,
  checkTouched,
  saveTeamSettings,
  settingsLoading
}: PropTypes): ReactElement {
  //
  //
  const [touched, setTouched] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSave = async () => {
    setIsSubmitting(true);
    await saveTeamSettings(values);
    setIsSubmitting(false);
  };
  useEffect(() => {
    let touched: boolean = checkTouched([
      'first_sms_content',
      'assignment_confirmation_text',
      'purchase_confirmation_text'
    ]);
    setTouched(touched);
  }, [values]);

  return (
    <FieldsPanel
      title="SMS SETTINGS"
      icon={<MessageOutlined />}
      {...{ values, handleSave, settingsLoading, touched, isSubmitting }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MatInput
            name="first_sms_content"
            label="First SMS"
            value={values?.first_sms_content || ''}
            onChange={handleChange}
            rows={5}
            disabled={settingsLoading || isSubmitting}
          />
        </Grid>

        <Grid item xs={12}>
          <MatInput
            name="assignment_confirmation_text"
            label="Assignment Confirmation Text"
            value={values?.assignment_confirmation_text || ''}
            onChange={handleChange}
            rows={5}
            disabled={settingsLoading || isSubmitting}
          />
        </Grid>

        <Grid item xs={12}>
          <MatInput
            name="purchase_confirmation_text"
            label="Purchse Confirmation Text"
            value={values?.purchase_confirmation_text || ''}
            onChange={handleChange}
            rows={5}
            disabled={settingsLoading || isSubmitting}
          />
        </Grid>
      </Grid>
    </FieldsPanel>
  );
}
