import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Chip,
  Button,
  colors,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Slide,
  FormControl,
  OutlinedInput,
  InputLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Icon,
  InputAdornment,
  CircularProgress,
  Divider,
  Paper
} from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import { getPreviewEvent, addNewEvent, getEvents } from 'src/redux/actions';
import EmptyImage from 'src/img/empty.png';

const useStyles = makeStyles(theme => ({
  root: {},
  newEventList: {
    '& .MuiListItem-root': {
      paddingLeft: 0,
      paddingRight: 0
    },
    '& .MuiListItemAvatar-root': {
      minWidth: 40
    },

    '& .MuiListItemText-primary': {
      color: theme.palette.text.secondary,
      fontWeight: 'bold'
    }
  },
  tableRow: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  selectedRow: {
    backgroundColor: colors.grey['200']
  },
  button: {
    padding: '0px 9px'
  }
}));

const EventTable = ({
  events,
  selectedPlan,
  selectedEvent,
  handleEventSelection
}) => {
  const classes = useStyles();
  const { enqueueSnackbar: showAlert } = useSnackbar();
  const dispatch = useDispatch();
  const { activeTeam } = useSelector(state => state);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);
  const [eventDialogOpen, setEventDialogOpen] = useState(false);
  const [eventCode, setEventCode] = useState('');
  const [preview, setPreview] = useState(null);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const handleEventDialogClose = () => {
    setEventDialogOpen(false);
  };

  const handleEventDialogOpen = () => {
    setEventDialogOpen(true);
  };

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const resetInput = () => {
    setEventCode('');
    setPreview(null);
  };

  const handleInputChange = (event, type) => {
    let value = event.target.value;
    if (type === 'eventcode') setEventCode(value);
  };

  const handleGetPreview = async event => {
    event.preventDefault();
    setPreviewLoading(true);
    try {
      let result = await getPreviewEvent(activeTeam.team_id, eventCode);
      if (result.status === 200) {
        let preview = result.data.preview;
        setPreview(preview);
      }
    } catch (error) {
      resetInput();
      showAlert('Failed to preview event code', { variant: 'error' });
    }
    setPreviewLoading(false);
  };

  const handleAddEvent = async event => {
    setSaveLoading(true);
    if (preview === null) {
      showAlert('Please insert an event code and click preview first', {
        variant: 'warning'
      });
      setSaveLoading(false);
      return;
    }

    try {
      let result = await addNewEvent(
        activeTeam.team_id,
        eventCode,
        selectedPlan.id
      );
      if (result.status === 200) {
        await dispatch(getEvents(activeTeam.team_id, selectedPlan.id));
        setEventDialogOpen(false);
        resetInput();
        showAlert('Event saved successfully', { variant: 'success' });
      }
    } catch (error) {
      showAlert('Something went wrong, please try again', { variant: 'error' });
    }
    setSaveLoading(false);
  };

  return (
    <Paper className={clsx(classes.root)} variant="outlined">
      {events && events.length != undefined && events.length > 0 ? (
        <React.Fragment>
          <Box
            p={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5">Events In Plan</Typography>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={handleEventDialogOpen}
              className={classes.button}
            >
              ADD EVENT
            </Button>
          </Box>
          <Divider />

          <PerfectScrollbar>
            <Box minWidth={1050}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Event Code</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Is current event</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>

                {/* (limit > 0
                ? tableData.slice(page * limit, page * limit + limit)
                : tableData
              ) */}
                <TableBody>
                  {events.map(data => (
                    <TableRow
                      hover
                      key={data.id}
                      className={clsx(
                        classes.tableRow,
                        data.id === selectedEvent.id ? classes.selectedRow : ''
                      )}
                      onClick={() => handleEventSelection(data)}
                    >
                      <TableCell>
                        <Typography color="textSecondary" variant="subtitle2">
                          {data.eventCode ? data.eventCode : 'unavailable'}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography color="textSecondary" variant="subtitle2">
                          {data.description ? data.description : 'unavailable'}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography color="textSecondary" variant="subtitle2">
                          {data.date
                            ? moment(data.date).format('MMM Do YYYY')
                            : 'unavailable'}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography color="textSecondary" variant="subtitle2">
                          {data.time ? data.time : 'unavailable'}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        {data.isCurrentEvent === 1 ? (
                          <Chip label="YES" size="small" />
                        ) : data.isCurrentEvent === 0 ? (
                          <Chip label="NO" size="small" color="primary" />
                        ) : (
                          <Typography color="textSecondary" variant="subtitle2">
                            unavailable
                          </Typography>
                        )}
                      </TableCell>

                      <TableCell align="center">
                        {!data.isCurrentEvent && (
                          <IconButton size="small">
                            <DeleteOutlineIcon fontSize="small" />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          {/* <TablePagination
        component="div"
        count={tableData.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      /> */}
        </React.Fragment>
      ) : (
        <Box textAlign="center" p={8} pl={4} pr={4}>
          <img src={EmptyImage} style={{ width: 128 }} />
          <Typography gutterBottom variant="body1" color="textSecondary">
            Please add events to this plan
          </Typography>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={handleEventDialogOpen}
            style={{ marginTop: 5 }}
          >
            ADD EVENT
          </Button>
        </Box>
      )}

      <Dialog
        open={eventDialogOpen}
        // onClose={handleEventDialogClose}
        aria-labelledby="event-dialog-title"
      >
        <DialogContent>
          <Box mb={2}>
            <Typography variant="h4">Add an event code</Typography>
          </Box>
          <Box>
            <form onSubmit={handleGetPreview}>
              <FormControl variant="outlined" size="small" required>
                <InputLabel htmlFor="add-event-code">Event code</InputLabel>
                <OutlinedInput
                  id="add-event-code"
                  value={eventCode}
                  onChange={event => handleInputChange(event, 'eventcode')}
                  endAdornment={
                    <InputAdornment>
                      <Button
                        size="small"
                        type="submit"
                        disabled={previewLoading}
                      >
                        Preview
                        {previewLoading && (
                          <CircularProgress
                            size={10}
                            style={{
                              marginLeft: 3,
                              marginTop: -2,
                              color: '#bdbdbd'
                            }}
                          />
                        )}
                      </Button>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </form>
          </Box>

          {preview != null && (
            <Box>
              <List className={classes.newEventList}>
                <ListItem>
                  <ListItemAvatar>
                    <DescriptionOutlinedIcon color="primary" fontSize="small" />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Description"
                    secondary={preview.event_description}
                  />
                </ListItem>

                <ListItem>
                  <ListItemAvatar>
                    <CalendarTodayOutlinedIcon
                      color="primary"
                      fontSize="small"
                    />
                  </ListItemAvatar>
                  <ListItemText primary="Date" secondary={preview.event_date} />
                </ListItem>

                <ListItem>
                  <ListItemAvatar>
                    <QueryBuilderOutlinedIcon
                      color="primary"
                      fontSize="small"
                    />
                  </ListItemAvatar>
                  <ListItemText primary="Time" secondary={preview.event_time} />
                </ListItem>

                {/* <ListItem>
                  <ListItemAvatar>
                    <BookmarkBorderOutlinedIcon
                      color="primary"
                      fontSize="small"
                    />
                  </ListItemAvatar>
                  <ListItemText primary="Current event" secondary="test text" />
                </ListItem> */}
              </List>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleEventDialogClose();
              resetInput();
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={saveLoading}
            onClick={handleAddEvent}
          >
            Save
            {saveLoading && (
              <CircularProgress
                size={12}
                style={{ marginLeft: 3, marginTop: -2, color: '#bdbdbd' }}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

EventTable.propTypes = {
  // className: PropTypes.string
  //   tableData: PropTypes.array.isRequired
};

export default EventTable;
