import React, { useState } from 'react';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AssignmentType, ReduxState } from 'src/types';
import { updateAssignments, sellSeat, errorToast } from 'src/redux/actions';
import { toast } from 'react-toastify';

interface PropTypes {
  assignment: AssignmentType;
}

export const SellSeat = ({ assignment }: PropTypes) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { activeTeam, selectedEvent, selectedPlan, assignments } = useSelector(
    (state: ReduxState) => state
  );
  const data = assignment;
  const [loading, setLoading] = useState<boolean>(false);

  const handleSellSeat = async () => {
    setLoading(true);

    // Distructuring required properties
    let { team_id: teamId } = activeTeam;
    let { eventCode } = selectedEvent;
    let { planCode } = selectedPlan;
    let { id: assignmentId } = data;

    try {
      // Calling sell seat api
      let response = await sellSeat({
        teamId,
        eventCode,
        planCode,
        assignmentId
      });

      if (response.status === 200) {
        // If response is success update the assignment table
        await updateAssignments(teamId, eventCode);
        toast.success(response.data.message);
      }
    } catch (error) {
      errorToast(error);
    }

    setLoading(false);
  };

  return (
    <Button
      size="small"
      variant="outlined"
      color="primary"
      onClick={handleSellSeat}
      className={styles.sellSeatButton}
      disabled={
        loading || data?.TM_order_number !== null || !(data?.seats !== null)
      }
    >
      Sell seat
      {loading && (
        <CircularProgress
          style={{ marginLeft: 4, color: 'gray', marginTop: '-2px' }}
          size={10}
        />
      )}
    </Button>
  );
};

const useStyles = makeStyles(theme => ({
  sellSeatButton: {
    fontSize: 12
  }
}));
