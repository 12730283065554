import React, {
  createContext,
  EventHandler,
  SyntheticEvent,
  useContext,
  useEffect,
  useState
} from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';

type SidebarToggleType = 'open' | 'shrink' | 'close';
interface InteractionType {
  state: 'idle' | 'mouseover' | 'mouseleave';
  inTime: number | null;
  outTime: number | null;
}
interface ContextPropType {
  isOpen: boolean;
  sidebarWidth: number;
  toggleSidebar: (arg: SidebarToggleType) => any;
  handleInteraction: EventHandler<SyntheticEvent>;
  pinSidebar: (arg: boolean) => any;
  isPinned: boolean;
}

const SidebarContext = createContext(null);

export const SidebarProvider = ({ children }): JSX.Element => {
  const openStateWidth = 250;
  const shrinkStateWidth = 68;
  const closeStateWidth = 0;
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  // Sidebar states
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [sidebarWidth, setSidebarWidth] = useState<number>(openStateWidth);
  const [isPinned, setIsPinned] = useState<boolean>();

  // Using this flag while checking localstorage
  const [isChecking, setIsChecking] = useState<boolean>(true);

  // User interaction state
  let autoShrinkDuration = 8000; // ms
  let autoShrinkTimer = null;

  // General sidebar toggler
  const toggleSidebar = (type: SidebarToggleType) => {
    switch (type) {
      case 'open':
        setIsOpen(true);
        setSidebarWidth(openStateWidth);
        return;
      case 'shrink':
        setIsOpen(false);
        setSidebarWidth(shrinkStateWidth);
        return;
      case 'close':
        setIsOpen(false);
        setSidebarWidth(closeStateWidth);
        return;
    }
  };

  /** Shrink sidebar automatically after some time if no activity in the sidebar, works for only desktop.
   * @param shrink boolean ( true will start the scheduler and false will stop the scheduler)
   */
  const autoShrink = (shrink: boolean) => {
    if (shrink) {
      autoShrinkTimer = setTimeout(() => {
        toggleSidebar('shrink');
      }, autoShrinkDuration);
    } else {
      clearTimeout(autoShrinkTimer);
      autoShrinkTimer = null;
    }
  };

  /** Event handler function : tracking mouseover and mouseleave interaction, and start stop the scheduler.  */
  const handleInteraction = event => {
    event.persist();

    // if it is mobile device, don't run autoShrink for better UX.
    // if sidebar is pinned, then don't need to run autoShrink
    if (!sm && !isPinned && !isChecking && isOpen) {
      // Disabled sidebar auto shring as per requirement, for enable just uncomment next two line
      //-----------------------------------------------------------------------------------------
      // event.type === 'mouseover' && autoShrink(false);
      // event.type === 'mouseleave' && autoShrink(true);
    }
  };

  /** Pin button handler, update the pinned status in localstorage and update internal state.
   * Once pin status (true) is saved to storage, it will prevent auto close.
   * @param isPinned boolean
   */
  const pinSidebar = isPinned => {
    localStorage.setItem('ss_sidebar_pinned', JSON.stringify(isPinned));
    setIsPinned(isPinned);
  };

  /** Helper function to check the pin status at localstorage for the first load
   * and update the pin state. Also running the autoShrink scheduler for first time
   * if isPinned false
   */
  const checkSavedPinStatus = () => {
    try {
      const isPinned = JSON.parse(
        localStorage.getItem('ss_sidebar_pinned')
      ) as boolean;
      isPinned && setIsPinned(isPinned);
    } catch (error) {
      console.log(error);
    }
    setIsChecking(false);
  };

  const handleResponsiveScreen = () => {
    sm && toggleSidebar('close');
    !sm && isOpen && toggleSidebar('open');
    !sm && !isOpen && toggleSidebar('shrink');
  };

  useEffect(checkSavedPinStatus, []);
  useEffect(handleResponsiveScreen, [sm]);

  return (
    <SidebarContext.Provider
      value={{
        isOpen,
        sidebarWidth,
        handleInteraction,
        toggleSidebar,
        pinSidebar,
        isPinned
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = (): ContextPropType => {
  return useContext(SidebarContext);
};
