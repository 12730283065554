import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  Button,
  makeStyles,
  colors
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  avatar: {
    width: 48,
    height: 48,
    borderRadius: 4
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  brown: {
    backgroundColor: colors.brown[500]
  },
  purple: {
    backgroundColor: colors.deepPurple[500]
  },
  blue: {
    backgroundColor: colors.blue[500]
  }
}));

const QuickActionCard = ({ className, action, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const navigatePage = () => {
    navigate(action.link, { replace: true });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Box display="flex" justifyContent="center" mb={3} mt={1}>
          <Avatar
            alt="Product"
            variant="square"
            className={clsx(classes.avatar, classes[action.mediaClass])}
          >
            <action.media />
          </Avatar>
        </Box>
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h4"
        >
          {action.title}
        </Typography>
        <Typography align="center" color="textSecondary" variant="body1">
          {action.description}
        </Typography>
        <Box display="flex" justifyContent="center" mt={3}>
          <Button
            variant="contained"
            color="primary"
            className={classes.actionButton}
            disableElevation
            onClick={navigatePage}
          >
            Click here
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

QuickActionCard.propTypes = {
  className: PropTypes.string,
  action: PropTypes.object.isRequired
};

export default QuickActionCard;
