import React, { ReactEventHandler } from 'react';
import {
  Box,
  FormControlLabel,
  InputLabel,
  makeStyles,
  colors,
  Checkbox
} from '@material-ui/core';

interface PropTypes {
  label: string;
  checked: boolean;
  name: string;
  title: string;
  onChange?: ReactEventHandler;
}

export const FormikCheckbox = ({
  label,
  checked,
  title,
  name,
  onChange
}: PropTypes): JSX.Element => {
  const styles = useStyles();

  return (
    <Box>
      <InputLabel className={styles.title}>{title}</InputLabel>
      <FormControlLabel
        className={styles.checkboxLabel}
        label={label}
        control={
          <Checkbox
            checked={checked}
            name={name}
            size="medium"
            color="primary"
            onChange={onChange}
          />
        }
      />
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
    fontSize: 16
  },

  checkboxLabel: {
    '& .MuiFormControlLabel-label': {
      color: theme.palette.text.secondary,
      fontSize: 16
    }
  }
}));
