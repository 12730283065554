import {
  DashboardOutlined,
  ListAltOutlined,
  PeopleAltOutlined,
  AccountBoxOutlined,
  AccountTreeOutlined,
  VerticalSplitOutlined,
  SettingsOutlined
} from '@material-ui/icons';
import { NavItemType } from 'src/types';

export const nav_items: NavItemType[] = [
  // {
  //   href: '/dashboard',
  //   icon: DashboardOutlined,
  //   title: 'Dashboard'
  // },
  {
    href: '/assignments',
    icon: ListAltOutlined,
    title: 'Assignments'
  },
  {
    href: '/subscribers',
    icon: PeopleAltOutlined,
    title: 'Subscribers'
  },
  {
    href: '/account_groups',
    icon: AccountBoxOutlined,
    title: 'Account Groups'
  },
  {
    href: '/product_mappings',
    icon: AccountTreeOutlined,
    title: 'Claim Setup'
  },
  // {
  //   href: '/ticket_claims',
  //   icon: ConfirmationNumberOutlined,
  //   title: 'Ticket Claims'
  // },
  {
    href: '/subscription_plans',
    icon: VerticalSplitOutlined,
    title: 'Game Pass Setup'
  },
  // {
  //   href: '/inventory', // create inventory view
  //   icon: DonutSmallOutlinedIcon, // change icon
  //   title: 'Inventory'
  // },

  {
    href: '/settings',
    icon: SettingsOutlined,
    title: 'Team Settings'
  }
];
