import React from 'react';
import { withFormik } from './withFormik';
import { Grid } from '@material-ui/core';
import Switches from './Switches';
import Fields from './Fields';
import Submit from './Submit';

export default withFormik(({ formik, plan }) => {
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        <Fields {...{ formik }} />
      </Grid>
      <Grid container spacing={3} style={{ marginTop: 10 }}>
        <Switches {...{ plan, formik }} />
      </Grid>
      <Grid container spacing={3}>
        <Submit {...{ formik }} />
      </Grid>
    </form>
  );
});
