import React from 'react';
import {
  Box,
  Paper,
  makeStyles,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { NavButton } from './NavButton';
import { SidebarToggler } from 'src/components/DashboardSidebar/SidebarToggler';
import { useSidebar } from 'src/contexts/SidebarContext';
import { NavItemType } from 'src/types';
import { v4 as uuid } from 'uuid';

interface PropTypes {
  nav_items: NavItemType[];
}

export const DashboardSidebar = ({ nav_items }: PropTypes): JSX.Element => {
  const styles = useStyles();
  const { handleInteraction, isOpen, sidebarWidth } = useSidebar();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Paper
      onMouseOver={handleInteraction}
      onMouseLeave={handleInteraction}
      variant="outlined"
      className={styles.paper}
      style={{ width: sidebarWidth }}
      id="app_sidebar"
    >
      {!sm && (
        <Box
          px={1}
          py={1}
          display="flex"
          justifyContent={isOpen ? 'flex-end' : 'center'}
        >
          <SidebarToggler />
        </Box>
      )}

      <Box
        px={1}
        mt={2}
        py={0}
        width="100%"
        display="flex"
        flexDirection="column"
      >
        {nav_items.map(item => (
          <NavButton
            key={uuid()}
            icon={item.icon}
            title={item.title}
            href={item.href}
          />
        ))}
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    height: '100%',
    boxShadow: `0 8px 16px rgb(0 0 0 / 10%)`,
    position: 'relative',
    transition: 'all 0.3s ease',
    overflow: 'hidden',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,

    [theme.breakpoints.down('sm')]: {
      borderRadius: 0
    }
  }
}));
