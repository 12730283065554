import React, { ReactElement, useState, cloneElement } from 'react';
import {
  Paper,
  Box,
  Divider,
  Typography,
  makeStyles,
  Avatar,
  Button,
  CircularProgress
} from '@material-ui/core';
import { ITeamSettings } from 'src/redux/model';
import { SaveOutlined } from '@material-ui/icons';

type PropTypes = {
  children: ReactElement;
  title: string;
  icon: ReactElement;
  handleSave: Function;
  values: ITeamSettings;
  touched: boolean;
  settingsLoading: boolean;
  isSubmitting: boolean;
};

export default function FieldsPanel({
  children,
  title,
  icon,
  handleSave,
  values,
  touched,
  settingsLoading,
  isSubmitting
}: PropTypes): ReactElement {
  const styles = useStyles();

  return (
    <Paper variant="outlined" className={styles.paper}>
      <Box p={2} py={1} className={styles.titleWrapper}>
        <Avatar variant="rounded" className={styles.avatar}>
          {settingsLoading || isSubmitting ? (
            <CircularProgress size={16} color="primary" />
          ) : (
            icon
          )}
        </Avatar>
        <Box>
          <Typography variant="h5" color="textPrimary">
            {title}
          </Typography>
        </Box>
        <Box flexGrow={1} />
        <Box>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            size="small"
            disabled={!touched || isSubmitting}
            onClick={() => handleSave(values)}
            startIcon={<SaveOutlined className={styles.buttonIcon} />}
          >
            Save
          </Button>
        </Box>
      </Box>
      <Divider />
      <Box p={3}>{children}</Box>
    </Paper>
  );
}

const useStyles = makeStyles(theme => ({
  titleWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  avatar: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.divider}`,
    marginRight: 15,
    width: 32,
    height: 32,
    borderRadius: 2,
    '& .MuiSvgIcon-root': {
      fontSize: 24,
      color: theme.palette.primary.main
    }
  },
  paper: {
    height: '100%',
    transition: 'all 0.3s ease',
    borderRadius: 8,
    boxShadow: '0 8px 16px rgb(0 0 0 / 10%)',
    '&:hover': {
      transform: 'translateY(-5px)'
    }
  },
  buttonIcon: {
    fontSize: '24px !important'
  }
}));
