import React, { useState, useEffect } from 'react';
import {
  Paper,
  Box,
  Grid,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import { SectionHeader } from 'src/components/SectionHeader';
import PeopleAltOutlined from '@material-ui/icons/PeopleAltOutlined';
import { useAsyncState } from 'src/hooks/useAsyncState';
import { SubscriberTable } from 'src/components/SubscriberTable';
import { useSelector } from 'react-redux';
import { ReduxState } from 'src/types';
import Empty from 'src/components/Empty';
import { AsyncEmpty } from 'src/components/AsyncEmpty';

interface PropType {}

export const SubscriberSection = ({}: PropType) => {
  const { subscribers } = useSelector((state: ReduxState) => state);
  const status = useAsyncState('subscribers');
  const haveSubscribers = subscribers.length > 0;

  return (
    <Paper variant="outlined">
      <SectionHeader
        title="Subscribers"
        icon={<PeopleAltOutlined />}
        loading={status.loading}
      />

      <AsyncEmpty {...{ status }} haveData={haveSubscribers} />

      {(status.idle || status.success) && haveSubscribers && (
        <Box p={2}>
          <Grid container spacing={3}>
            <SubscriberTable {...{ subscribers }} />
          </Grid>
        </Box>
      )}
    </Paper>
  );
};
