import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Paper,
  MenuItem,
  makeStyles,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Box,
  Typography,
  Divider,
  DialogTitle,
  Grid,
  colors,
  Select,
  CircularProgress
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  updateAssignments,
  updateInventoryDetails,
  assignSeatByName
} from 'src/redux/actions';
import { useSnackbar } from 'notistack';
import { v4 as uuid } from 'uuid';
import EmptyImage from 'src/img/empty.png';
import Empty from 'src/components/Empty';

// Pop up modal used for assign seat option
// props passed from DottedMenu component
export default function AssignDialog({
  assignDialogOpen,
  handleAssignDialog,
  inventoryDetails,
  rowData
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar: showAlert } = useSnackbar();
  const { activeTeam, selectedPlan, selectedEvent } = useSelector(
    state => state
  );
  const [pods, setPods] = useState([]);
  const [dropdownValue, setDropdownValue] = useState('');
  const [loading, setLoading] = useState(false);

  // Grouping seats by pods
  useEffect(() => {
    if (inventoryDetails) {
      // console.log(inventoryDetails);
      let groups = groupSeats(inventoryDetails);
      setPods(groups);
    }
  }, [inventoryDetails]);

  // Helper function to group seats by pod
  const groupSeats = seatData => {
    let podIds = [];
    let groups = [];
    seatData.forEach(seat => {
      if (!podIds.includes(seat.pod_id) && !seat.is_assigned) {
        podIds.push(seat.pod_id);
      }
    });

    podIds.forEach(id => {
      let seats = seatData.filter(seat => seat.pod_id === id);
      groups.push(seats);
    });
    return groups;
  };

  // Filter seats by pods, triggered from dropdown
  const handleDropDown = event => {
    let seatCount = event.target.value;
    setDropdownValue(seatCount);
    if (seatCount === 'all') {
      let groups = groupSeats(inventoryDetails);
      setPods(groups);
    } else {
      let seatDetails = [...inventoryDetails].filter(
        seat => seat.num_seats == seatCount
      );
      let groups = groupSeats(seatDetails);
      setPods(groups);
    }
  };

  // Assign seats handler

  const handleAssignSeat = async (podId, unassignPrev = true) => {
    setLoading(true);
    try {
      let response = await assignSeatByName({
        podId,
        teamId: activeTeam.team_id,
        eventCode: rowData.event_code,
        assignmentId: rowData.ss_id,
        unassignPrev
      });

      if (response.status === 200) {
        updateAssignments(activeTeam.team_id, rowData.event_code);

        await dispatch(
          updateInventoryDetails(activeTeam.team_id, rowData.event_code)
        );
        showAlert('Seat assigned successfully', { variant: 'success' });
      }
    } catch (error) {
      showAlert('Something went wrong, please try again', { variant: 'error' });
    }
    handleAssignDialog('close');
    setLoading(false);
  };

  return (
    <Dialog
      open={assignDialogOpen}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
      className={classes.assignDialog}
    >
      <DialogTitle
        className={classes.dialogTitle}
        id="scroll-dialog-title"
        disableTypography={true}
      >
        <Box>
          <Typography variant="h4" color="textPrimary">
            Available seats
          </Typography>

          {loading && (
            <Typography
              variant="caption"
              color="textSecondary"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              Assigining seats{' '}
              <CircularProgress size={10} style={{ marginLeft: 3 }} />
            </Typography>
          )}
        </Box>

        <FormControl variant="outlined" size="small">
          <InputLabel htmlFor="seat-select">Select pod size</InputLabel>
          <Select
            className={classes.dropdown}
            id="seat-select"
            onChange={handleDropDown}
            value={dropdownValue}
          >
            <MenuItem value="all">all</MenuItem>
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="3">3</MenuItem>
            <MenuItem value="4">4</MenuItem>
          </Select>
        </FormControl>
      </DialogTitle>
      <Divider style={{ marginBottom: 10 }} />
      <DialogContent className={classes.dialogContent}>
        {pods.length > 0 ? (
          pods
            .filter(seats => !seats[0].is_assigned)
            .map(seats => {
              return (
                <Grid
                  container
                  spacing={2}
                  className={classes.podContainer}
                  // style={{ backgroundColor: colors.grey['200'] }}
                  key={uuid()}
                >
                  <Grid item xs={12} className={classes.podTitle}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography
                        variant="h6"
                        color="textPrimary"
                        style={{ fontWeight: 'bold' }}
                      >
                        POD : {seats[0].pod_id}
                      </Typography>
                      <div>
                        <Button
                          disabled={loading}
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => {
                            handleAssignSeat(seats[0].pod_id);
                          }}
                        >
                          Assign
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                          disabled={loading}
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => {
                            handleAssignSeat(seats[0].pod_id, false);
                          }}
                        >
                          Forced Assign
                        </Button>
                      </div>
                    </Box>
                  </Grid>

                  {seats.map(seat => (
                    <Grid item lg={3} key={uuid()}>
                      <Box>
                        <Paper variant="outlined">
                          <Box
                            p={2}
                            pb={1}
                            pt={1}
                            display="flex"
                            alignItems="flex-start"
                            flexDirection="column"
                          >
                            <Typography
                              variant="caption"
                              style={{ fontWeight: 'bold', fontSize: 12 }}
                            >
                              {seat.available_seat}
                            </Typography>
                            {seat.is_assigned ? (
                              <Typography
                                variant="caption"
                                className={classes.assignBadge}
                              >
                                Assigned
                              </Typography>
                            ) : (
                              ''
                            )}
                          </Box>
                          <Divider />
                          <Box p={2}>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Box>
                                <Typography variant="h6" color="textPrimary">
                                  Class Name
                                </Typography>

                                <Typography variant="h6" color="textPrimary">
                                  Section Name
                                </Typography>

                                <Typography variant="h6" color="textPrimary">
                                  Quality
                                </Typography>

                                <Typography variant="h6" color="textPrimary">
                                  Number of seats
                                </Typography>

                                <Typography variant="h6" color="textPrimary">
                                  Price
                                </Typography>
                              </Box>

                              <Box textAlign="right">
                                <Typography variant="h6" color="textSecondary">
                                  {seat.class_name}
                                </Typography>

                                <Typography variant="h6" color="textSecondary">
                                  {seat.section_name}
                                </Typography>

                                <Typography variant="h6" color="textSecondary">
                                  {seat.quality}
                                </Typography>

                                <Typography variant="h6" color="textSecondary">
                                  {seat.num_seats}
                                </Typography>
                                <Typography variant="h6" color="textSecondary">
                                  {seat.price_2}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Paper>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              );
            })
        ) : (
          <Empty caption="No seat available" />
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => handleAssignDialog('close')}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles(theme => {
  return {
    dialogTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: 10,
      paddingBottom: 10
    },
    dropdown: {
      '& .MuiSelect-root': {
        height: 20,
        width: 130
      },
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent'
      }
    },

    assignDialog: {
      '& .MuiDialog-paper': {
        maxWidth: '1010px !important',
        minWidth: '1010px !important'
      }
    },
    podContainer: {
      backgroundColor: colors.grey['200'],
      border: `1px solid ${colors.grey['400']}`,
      borderRadius: 3,
      marginBottom: 30,
      overFlow: 'hidden'
    },
    assignBadge: {
      fontSize: 10,
      padding: 5,
      color: colors.red['A700'],
      fontWeight: 'bold',
      paddingLeft: 0,
      paddingTop: 0
    },
    podTitle: {
      // backgroundColor: theme.palette.primary.main,
      borderBottom: `1px solid ${colors.grey['400']}`,
      borderTopLeftRadius: 3,
      borderTopRightRadius: 3
    }
  };
});
