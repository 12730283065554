import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  IconButton,
  makeStyles,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Box,
  Typography,
  Divider,
  DialogTitle,
  useMediaQuery
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  markAsAttending,
  updateAssignments,
  updateInventoryDetails,
  assignSeatByName
} from 'src/redux/actions';
import { useSnackbar } from 'notistack';
import { v4 as uuid } from 'uuid';
import AttendanceDialog from './AttendanceDialog';
import AssignDialog from './AssignDialog';

export const ContextMenu = ({ assignment }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar: showAlert } = useSnackbar();
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef(null);
  const menuOpened = useRef(menuOpen);
  const {
    activeTeam,
    selectedPlan,
    selectedEvent,
    inventoryDetails,
    assignments
  } = useSelector(state => state);
  const rowData = assignment;
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [attendanceDialogOpen, setAttendanceDialogOpen] = useState(false);

  const handleAssignDialog = async type => {
    if (type === 'open') {
      await dispatch(
        updateInventoryDetails(activeTeam.team_id, selectedEvent.eventCode)
      );
      setAssignDialogOpen(true);
    }
    if (type === 'close') setAssignDialogOpen(false);
  };

  const handleAttendanceDialogOpen = () => {
    setAttendanceDialogOpen(true);
  };

  const handleAttendanceDialogClose = () => {
    setAttendanceDialogOpen(false);
  };

  const handleMenuToggle = event => {
    event.stopPropagation();
    setMenuOpen(menuOpened => !menuOpened);
  };

  const handleMenuClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setMenuOpen(false);
  };

  const handleMenuListClick = event => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    if (menuOpened.current === true && menuOpen === false) {
      anchorRef.current.focus();
    }
    menuOpened.current = menuOpen;
  }, [menuOpen]);

  const handleMarkAttending = async () => {
    try {
      let response = await markAsAttending({
        teamId: activeTeam.team_id,
        planCode: selectedPlan.planCode,
        eventCode: rowData.event_code
      });

      if (response.status === 200) {
        await dispatch(
          updateAssignments(
            activeTeam.team_id,
            selectedPlan.planCode,
            selectedEvent.eventCode
          )
        );
        showAlert('Mark as attending successful', { variant: 'success' });
      }
    } catch (error) {
      showAlert('Something went wrong, please try again', { variant: 'error' });
    }
  };

  return (
    <div>
      <IconButton
        size="small"
        ref={anchorRef}
        aria-controls={menuOpen ? `action-menu` : undefined}
        aria-haspopup="true"
        onClick={handleMenuToggle}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>

      <Popper
        open={menuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-start"
        className={classes.popper}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-end' ? 'right top' : 'center bottom'
            }}
          >
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <MenuList
                  autoFocusItem={menuOpen}
                  id={`action-menu`}
                  onKeyDown={handleMenuListClick}
                  dense={true}
                >
                  <MenuItem
                    className={classes.menuItem}
                    onClick={event => {
                      handleMenuToggle(event);
                      handleAttendanceDialogOpen();
                    }}
                  >
                    Edit attendance
                  </MenuItem>

                  {/* {!rowData.assigned_seat && (
                    <MenuItem
                      className={classes.menuItem}
                      onClick={event => {
                        handleMenuToggle(event);
                        handleAssignDialog('open');
                      }}
                    >
                      Assign seat
                    </MenuItem>
                  )} */}

                  {/* <MenuItem
                    className={classes.menuItem}
                    onClick={event => handleMenuToggle(event)}
                  >
                    Pick a seat
                  </MenuItem> */}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <AssignDialog
        {...{ assignDialogOpen, handleAssignDialog, inventoryDetails, rowData }}
      />
      <AttendanceDialog
        open={attendanceDialogOpen}
        handleAttendanceDialogClose={handleAttendanceDialogClose}
        rowData={rowData}
      />
    </div>
  );
};

const useStyles = makeStyles(theme => {
  return {
    popper: {
      zIndex: '999999 !important'
    },
    smsInput: {
      width: 300
    },

    menuItem: {
      fontSize: 14,
      lineHeight: 1.1,
      paddingTop: 3,
      paddingBottom: 3
    },
    assignDialog: {
      '& .MuiDialog-paper': {
        maxWidth: '1050px !important'
      }
    }
  };
});
