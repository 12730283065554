import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  FormControlLabel,
  Checkbox,
  ListItem,
  List,
  Box,
  colors,
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  makeStyles,
  IconButton
} from '@material-ui/core';
import { SortByAlphaRounded } from '@material-ui/icons';
import { updateAssignments } from 'src/redux/actions';
import { getAllClaims } from 'src/redux/actions';

export default function SortMenu({ claims, updateClaims }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeTeam, ticketClaims } = useSelector(state => state);

  // Dropdown menu functionalities
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  // =====================================================================

  // Checkbox toggle functionality
  const [criteria, setCriteria] = useState(null);

  const handleChange = event => {
    if (criteria !== event.target.name) {
      setCriteria(event.target.name);
    } else {
      setCriteria('');
    }
  };

  useEffect(() => {
    if (criteria === 'eventSort') {
      let sorted = [
        ...claims.sort((a, b) => (a.eventCode < b.eventCode ? -1 : 1))
      ];
      updateClaims(sorted);
    }

    if (criteria === '') {
      dispatch(getAllClaims(activeTeam.team_id));
    }
  }, [criteria]);

  return (
    <>
      <IconButton
        ref={anchorRef}
        aria-controls={open ? 'sort-menu' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        size="small"
        className={classes.iconButton}
      >
        <SortByAlphaRounded />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.popper}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <List
                  // autoFocusItem={open}
                  id="sort-menu"
                  onKeyDown={handleListKeyDown}
                >
                  <Box p={1}>
                    <ListItem>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="eventSort"
                            className={classes.checkBox}
                            color="primary"
                            checked={criteria === 'eventSort'}
                            onChange={handleChange}
                          />
                        }
                        label="Sort by Event"
                        className={classes.checkBoxLabel}
                      />
                    </ListItem>
                  </Box>
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  // root: {
  //   display: 'flex'
  // },
  popper: {
    // marginRight: theme.spacing(2)
    zIndex: '9999'
  },
  checkBoxLabel: {
    marginLeft: 0
  },

  checkBox: {
    padding: 2
  },
  iconButton: {
    padding: 0
  }
}));
