import React, { EventHandler, SyntheticEvent } from 'react';
import {
  TextField,
  Box,
  Typography,
  makeStyles,
  colors
} from '@material-ui/core';
import { MatStyleType } from 'src/types';

interface PropTypes {
  name: string;
  label: string;
  placeholder: string;
  value: string | number;
  onChange: EventHandler<SyntheticEvent>;
}

export const MatInput = (props: PropTypes): JSX.Element => {
  const styles: MatStyleType = useStyles();

  return (
    <Box>
      <Box>
        <Typography variant="h6" className={styles.inputLabel}>
          {props.label}
        </Typography>
      </Box>
      <Box>
        <TextField
          size="small"
          variant="outlined"
          fullWidth
          name={props.name}
          value={props.value}
          placeholder={props.placeholder}
          onChange={props.onChange}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  inputLabel: {
    color: colors.grey[600]
  }
}));
