import React, { useState } from 'react';
import {
  Button,
  makeStyles,
  Dialog,
  Box,
  DialogActions,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import { updateAssignments, generateAssignment } from 'src/redux/actions';

export default function GenerateAssignments({ selectedEvent, selectedPlan }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeTeam } = useSelector(state => state);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar: showAlert } = useSnackbar();

  const handleConfirmDialogOpen = type => {
    if (type === 'open') setConfirmOpen(true);
    if (type === 'close') setConfirmOpen(false);
  };

  const handleGenerate = async () => {
    setLoading(true);
    try {
      let response = await generateAssignment(
        activeTeam.team_id,
        selectedPlan.planCode,
        selectedEvent.eventCode
      );
      if (response.status === 200) {
        await updateAssignments(activeTeam.team_id, selectedEvent.eventCode);
        setConfirmOpen(false);
        showAlert('Assignment generated successfully', { variant: 'success' });
      }
    } catch (error) {
      showAlert('Something went wrong, please try again', { variant: 'error' });
    }
    setLoading(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={() => handleConfirmDialogOpen('open')}
      >
        GENERATE ASSIGNMENTS
      </Button>
      <Dialog open={confirmOpen}>
        <Box p={3}>
          <Box mb={3}>
            <Typography variant="h5" color="textPrimary">
              Are you sure want to generate assignments ?
            </Typography>
          </Box>
        </Box>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => handleConfirmDialogOpen('close')}
          >
            No
          </Button>

          <Button disabled={loading} color="primary" onClick={handleGenerate}>
            Yes
            {loading && (
              <CircularProgress
                size={14}
                style={{ marginLeft: 5, color: 'gray' }}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  btn: {
    marginRight: 15
  }
}));
