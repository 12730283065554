import React from 'react';
import { Paper, makeStyles, Box } from '@material-ui/core';
import { TouchAppOutlined } from '@material-ui/icons';
import SectionHeader from 'src/components/SectionHeader';
import { TableTypeSelector } from './TableTypeSelector';
import { EventType, SelectorFn, SubscriptionPlanType } from 'src/types';
import { SubscriptionPlanList } from './SubscriptionPlanList';
import { EventList } from './EventList';

interface PropTypes {
  selectPlan: SelectorFn<SubscriptionPlanType>;
  selectedPlan: SubscriptionPlanType;
  selectedEvent: EventType;
  selectEvent: SelectorFn<EventType>;
}

export const AssignmentSidebar = ({
  selectPlan,
  selectedPlan,
  selectEvent,
  selectedEvent
}: PropTypes): JSX.Element => {
  const styles = useStyles();

  return (
    <Paper variant="outlined">
      <SectionHeader title="Select Options" icon={<TouchAppOutlined />} />
      <Box className={styles.sidebarContainer}>
        <Box p={2}>
          <Box>
            <TableTypeSelector />
          </Box>
          <Box mt={2}>
            <EventList {...{ selectedEvent, selectEvent }} />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  sidebarContainer: {
    height: 750,
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: 500
    }
  }
}));
