import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  Box,
  Typography,
  makeStyles
} from '@material-ui/core';
import { v4 as uuid } from 'uuid';

interface PropTypes {
  options: any[];
  formik: any;
  name: string;
  label: string;
  value?: any;
  onChange?: Function;
  required?: boolean;
}

export const FormikSelect = ({
  options,
  formik,
  name,
  label,
  value,
  onChange,
  required
}: PropTypes): JSX.Element => {
  const styles = useStyles();
  const inputValue = value ? value : formik.values[name];
  const changeHandler = onChange ? onChange : formik.handleChange;
  const haveValue = inputValue && true;
  const error = formik.errors[name];
  const isError = error && formik.submitCount > 0;

  return (
    <>
      <FormControl
        variant="outlined"
        size="small"
        fullWidth
        error={isError}
        required={required}
      >
        <InputLabel>{label}</InputLabel>
        <Select
          native
          name={name}
          value={haveValue ? inputValue : ''}
          onChange={changeHandler}
        >
          <option value=""></option>

          {options.map(option => (
            <option key={uuid()} value={option}>
              {option}
            </option>
          ))}
          {/* 
          <option value="pst">PST</option>
          <option value="pst">America/New_York</option>
          <option value="pst">America/Chicago</option>
          <option value="pst">America/Los_Angeles</option> */}
        </Select>
      </FormControl>
      {isError && (
        <Box>
          <Typography className={styles.errorMessage} variant="caption">
            {error}
          </Typography>
        </Box>
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  errorMessage: {
    color: theme.palette.error.main,
    marginLeft: 3,
    lineHeight: 1
  }
}));
