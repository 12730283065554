import React from 'react';
import { Box } from '@material-ui/core';
import { DataChip } from 'src/components/DataChip';
import { SellSeat } from 'src/components/AssignmentTableV2/SellSeat';
import { ActionItems } from './ActionItems';

const renderCell = ({ value }) =>
  value ? value : <DataChip variant="warning" label="unavailable" />;

const renderYesNo = ({ value }) => (
  <DataChip
    variant={value === 1 ? 'success' : 'error'}
    label={value === 1 ? 'Yes' : 'No'}
  />
);

const renderActionItems = ({ value }) => {
  return <ActionItems id={value} />;
};

// Assignment tab columns
const columnWidth = 150;
export const columns = [
  {
    field: 'id',
    headerName: 'Actions',
    width: columnWidth,
    renderCell: renderActionItems
  },
  {
    field: 'full_name',
    headerName: 'Full Name',
    width: columnWidth,
    renderCell
  },

  {
    field: 'group_id',
    headerName: 'Group ID',
    width: columnWidth,
    renderCell
  },
  {
    field: 'phone_number',
    headerName: 'Phone Number',
    width: columnWidth,
    renderCell
  },
  {
    field: 'seats',
    headerName: 'Seats',
    width: columnWidth,
    renderCell
  },
  {
    field: 'seat_count',
    headerName: 'Seat Count',
    width: columnWidth,
    renderCell
  },
  {
    field: 'is_attending',
    headerName: 'Attending',
    width: columnWidth,
    renderCell: renderYesNo
  },
  {
    field: 'did_send_first_message',
    headerName: 'First Message Sent',
    width: columnWidth,
    renderCell: renderYesNo
  },
  {
    field: 'TM_order_number',
    headerName: 'Sold Order No.',
    width: columnWidth,
    renderCell
  },
  {
    field: 'session_id',
    headerName: 'Reserve Order No.',
    width: columnWidth,
    renderCell
  }
];
