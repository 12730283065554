import React, { Component, useState } from 'react';
import {
  Button,
  makeStyles,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  TextField
} from '@material-ui/core';
import {
  AutorenewRounded,
  PeopleOutlineRounded,
  ReportProblemOutlined,
  TextsmsOutlined
} from '@material-ui/icons';
import { MatStyleType, ReduxState, SubscriptionPlanType } from 'src/types';
import { usePopup } from 'src/contexts/PopupContext';
import { useSelector } from 'react-redux';
import {
  renewAllSubscriber,
  getSubscriptionPlans,
  stopRenewAll
} from 'src/redux/actions';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { sendMassSms } from 'src/redux/actions';
import { ActionButton } from 'src/components/SubscriptionPlanCard/ActionButton';
import { AddSubscriberButton } from './AddSubscriber/AddSubscriberButton';
import { AddSubscriberForm } from './AddSubscriber/AddSubscriberForm';

interface PropTypes {
  plan: SubscriptionPlanType;
  isSelected: boolean;
}

export const PlanActions = ({ plan, isSelected }: PropTypes) => {
  const styles: MatStyleType = useStyles();
  const dispatch = useDispatch();
  const popup = usePopup();
  const { subscriptionPlans: plans, activeTeam } = useSelector(
    (state: ReduxState) => state
  );
  const isRenewing = !!plan?.isRenewing;
  const isRenewable =
    !!plan?.isRecurring && !!plan?.is_current_subscription_plan;
  const nextPlan = plans.find(plan => plan?.is_next_subsciption_plan === 1);

  const handleSendMassSms = async message => {
    const teamId = activeTeam.team_id;
    const planId = plan.id;
    popup.loading(true);
    try {
      await sendMassSms({ teamId, planId, message });
    } catch (error) {
      console.log(error);
    }
    popup.loading(false);
    popup.close();
  };

  const handleRenewAll = async () => {
    const teamId = activeTeam.team_id;
    const planId = plan.id;
    popup.loading(true);
    try {
      await renewAllSubscriber({ teamId, planId });
      await getSubscriptionPlans(teamId);
    } catch (error) {}
    popup.loading(false);
    popup.close();
  };

  const handleStopRenewAll = async () => {
    const teamId = activeTeam.team_id;
    const planId = plan.id;
    popup.loading(true);
    try {
      await stopRenewAll({ teamId, planId });
      await getSubscriptionPlans(teamId);
    } catch (error) {
      console.log(error);
    }
    popup.loading(false);
    popup.close();
  };

  const confirmMassSms = (content: string) => {
    popup.close();
    popup.fireInfo({
      title: `Are you sure want to send this sms to all subscriber of ${plan.planName} ?`,
      description: content
    });
    popup.onConfirm(() => handleSendMassSms(content));
  };

  const massSmsContent = () => {
    popup.fire({
      icon: <TextsmsOutlined color="primary" />,
      heading: 'MASS SMS',
      enableCloseIcon: true,
      description: `Insert SMS content for subscribers of ${plan.planName}.`,
      component: <MassSMSForm {...{ confirmMassSms }} />,
      hideButtons: true
    });
    popup.onConfirm(confirmMassSms);
  };

  const confirmRenew = () => {
    popup.fireWarning({
      title: 'Confirm Renew',
      description: `Are you sure want to renew all subscriber for next ticket type ${nextPlan?.ticketTypeCode}`
    });
    popup.onConfirm(handleRenewAll);
  };

  const confirmStopRenew = () => {
    popup.fireWarning({
      title: 'Confirm Renew',
      description: `Are you sure want to stop renewal of all subscriber for next ticket type ${nextPlan?.ticketTypeCode}`
    });
    popup.onConfirm(handleStopRenewAll);
  };

  return (
    <List dense={true} className={styles.list}>
      {/* <ActionButton
        icon={<TextsmsOutlined />}
        variant="regular"
        text="send mass sms"
        onClick={massSmsContent}
      /> */}

      <AddSubscriberButton {...{ plan, isSelected }} />

      {isRenewable && !isRenewing && (
        <ActionButton
          icon={<AutorenewRounded />}
          variant="regular"
          text="renew all subscriber"
          onClick={confirmRenew}
        />
      )}

      {isRenewing && (
        <ActionButton
          icon={<ReportProblemOutlined />}
          variant="warning"
          text="Stop Renew"
          onClick={confirmStopRenew}
        />
      )}
    </List>
  );
};

const MassSMSForm = ({ confirmMassSms }) => {
  const [message, setMessage] = useState('');
  const popup = usePopup();
  const handleMassSmsInput = event => {
    let value = event.target.value;
    console.log(value);
    setMessage(value);
  };

  const handleConfirm = () => {
    confirmMassSms(message);
  };

  return (
    <Box>
      <TextField
        variant="outlined"
        fullWidth
        multiline
        placeholder="SMS content"
        rows={4}
        value={message}
        onChange={handleMassSmsInput}
      />
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              size="small"
              onClick={() => popup.close()}
            >
              Cancel
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              size="small"
              onClick={handleConfirm}
              disabled={message.length < 1}
            >
              Send
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  list: {
    padding: 0
  }
}));
