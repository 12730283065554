import { useSelector } from 'react-redux';
import { AsyncStateRoot, AsyncStatus, ReduxState } from 'src/types';

type Mapped<T = AsyncStatus> = Record<
  T extends string ? T : string,
  boolean
> & { error: any | null };

const mapAsyncStatus = (entity): Mapped => {
  const types = ['idle', 'loading', 'failed', 'success'];
  const mapped: Mapped = {
    loading: false,
    idle: false,
    success: false,
    failed: false,
    error: null
  };
  for (let type of types) {
    mapped[type] = entity.status === type ? true : false;
  }
  mapped['error'] = entity.error;
  return mapped;
};

export const useAsyncState = (selector: keyof AsyncStateRoot): Mapped => {
  const { asyncState } = useSelector((state: ReduxState) => state);

  if (asyncState[selector] === undefined) {
    console.error(
      `{{{${selector}}}} not found in asyncState. Please update the AsyncStateRoot interface at 'src/types/types.ts' with corresponding data structure.
      `
    );
    return mapAsyncStatus({ status: 'idle', error: null });
  } else {
    return mapAsyncStatus(asyncState[selector]);
  }
};
