import React, { useState, useEffect } from 'react';
import {
  colors,
  FormControl,
  makeStyles,
  Select,
  Typography,
  MenuItem,
  InputLabel
} from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'src/types';
import { updateActiveTeam } from 'src/redux/actions';
import { SportsHandballOutlined, ExpandMoreOutlined } from '@material-ui/icons';

export const TeamSelector = (): JSX.Element => {
  const dispatch = useDispatch();
  const { teams } = useSelector((state: ReduxState) => state);
  const styles = useStyles();
  const [teamId, setTeamId] = useState<number>();

  useEffect(() => {
    teams && teams.length > 0 && setTeamId(teams[0]?.team_id);
  }, [teams]);

  const handleTeamChange = e => {
    let selectedId = Number(e.target.value);
    let selectedTeam = teams.find(({ team_id }) => team_id === selectedId);
    dispatch(updateActiveTeam(selectedTeam));
    setTeamId(selectedId);
  };

  return (
    <>
      {/* <Typography gutterBottom color="textSecondary" variant="h6" >
        Select Team
      </Typography> */}
      <FormControl
        variant="outlined"
        className={styles.formControl}
        fullWidth
        size="small"
      >
        <InputLabel>Select Team</InputLabel>
        <Select
          onChange={handleTeamChange}
          value={teamId ? teamId : ''}
          className={styles.select}
          startAdornment={<SportsHandballOutlined color="primary" />}
          IconComponent={ExpandMoreOutlined}
        >
          {teams &&
            teams.map(team => (
              <MenuItem button value={team.team_id} key={uuid()}>
                {team.team_name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 250
  },
  select: {
    backgroundColor: 'transparent',
    color: colors.grey[600],
    fontSize: 16,

    '& fieldset': {
      borderColor: theme.palette.divider
    },

    '& select:focus': {
      backgroundColor: 'transparent'
    }
  }
}));
