import React from 'react';
import { TextField } from '@material-ui/core';

export default function MatInput({ formik, name, label, ...props }) {
  return (
    <TextField
      fullWidth
      size="medium"
      variant="outlined"
      name={name}
      label={label}
      value={formik.values[name] || ''}
      onChange={formik.handleChange}
      {...props}
    />
  );
}
