import React, {
  ChangeEventHandler,
  ReactElement,
  useEffect,
  useState
} from 'react';
import { Grid } from '@material-ui/core';
import FieldsPanel from './FieldsPanel';
import { FormatListBulletedRounded } from '@material-ui/icons';
import MatInput from './MatInput';
import MatCheckbox from './MatCheckbox';
import { ITeamSettings } from 'src/redux/model';

type PropTypes = {
  values: ITeamSettings;
  handleChange: ChangeEventHandler;
  checkTouched: Function;
  saveTeamSettings: Function;
  settingsLoading: boolean;
};

export default function GeneralFields({
  values,
  handleChange,
  checkTouched,
  saveTeamSettings,
  settingsLoading
}: PropTypes): ReactElement {
  const [touched, setTouched] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSave = async () => {
    setIsSubmitting(true);
    await saveTeamSettings(values);
    setIsSubmitting(false);
  };

  useEffect(() => {
    let touched: boolean = checkTouched([
      'team_name',
      'team_city',
      'team_mascot',
      'is_college',
      'can_sit_with_friend'
    ]);
    setTouched(touched);
  }, [values]);

  return (
    <FieldsPanel
      title="GENERAL"
      icon={<FormatListBulletedRounded />}
      {...{ values, handleSave, settingsLoading, touched, isSubmitting }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MatInput
            name="team_name"
            label="Team Name"
            value={values?.team_name || ''}
            onChange={handleChange}
            disabled={settingsLoading || isSubmitting}
          />
        </Grid>
        <Grid item xs={12}>
          <MatInput
            name="team_city"
            label="Team City"
            value={values?.team_city || ''}
            onChange={handleChange}
            disabled={settingsLoading || isSubmitting}
          />
        </Grid>

        <Grid item xs={12}>
          <MatInput
            name="team_mascot"
            label="Team Mascot"
            value={values?.team_mascot || ''}
            onChange={handleChange}
            disabled={settingsLoading || isSubmitting}
          />
        </Grid>

        <Grid item xs={12}>
          <MatCheckbox
            title="Is this a college team ?"
            subtitle="College teams require a student manifest and $0 payments"
            name="is_college"
            label={values?.is_college ? 'YES' : 'NO'}
            checked={!!values?.is_college}
            onChange={handleChange}
            disabled={settingsLoading || isSubmitting}
          />
        </Grid>

        <Grid item xs={12}>
          <MatCheckbox
            title="Can sit with friend ?"
            name="can_sit_with_friend"
            label={values?.can_sit_with_friend ? 'YES' : 'NO'}
            checked={!!values?.can_sit_with_friend}
            onChange={handleChange}
            disabled={settingsLoading || isSubmitting}
          />
        </Grid>
      </Grid>
    </FieldsPanel>
  );
}
