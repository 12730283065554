import React from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles, Box, useTheme, useMediaQuery } from '@material-ui/core';
import { DashboardSidebar } from 'src/components/DashboardSidebar/DashboardSidebar';
import { TopBar } from './TopBar';
import { nav_items } from './nav_items';

export default function DashboardLayout({ children }) {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.root}>
      <Box p={2} pb={0}>
        <TopBar />
      </Box>

      <Box p={sm ? 0 : 2} mt={sm ? 2 : 0} className={classes.contentContainer}>
        <Box height="100%" className={classes.sidebarContainer}>
          <DashboardSidebar {...{ nav_items }} />
        </Box>

        <Box className={classes.content} flexGrow={1}>
          <Outlet />
        </Box>
      </Box>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'hidden',
    backgroundColor: '#FFFFFF'
  },

  contentContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    position: 'relative'
  },
  sidebarContainer: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 999
    }
  },
  content: {
    height: '100%',
    overflow: 'auto',
    border: `1px solid ${theme.palette.divider}`,
    borderLeft: 0,
    // borderRight: 0,
    borderRadius: 3,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    boxShadow: '0 8px 16px rgb(0 0 0 / 10%)',
    // backgroundColor: colors.grey[800],
    backgroundColor: theme.palette.background.dark,

    [theme.breakpoints.down('sm')]: {
      borderRadius: 0
    }
  }
}));
