import React, { ReactElement } from 'react';
import { FormikInput } from 'src/components/FormikInput';
import { Grid, makeStyles } from '@material-ui/core';
import SectionWrapper from './SectionWrapper';
import { TodayOutlined } from '@material-ui/icons';
import { SectionHeader } from 'src/components/SectionHeader';

interface PropTypes {
  formik: any;
}

export default function EventSection({ formik }: PropTypes): ReactElement {
  return (
    <SectionWrapper title="Event and Ticket Type" icon={<TodayOutlined />}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormikInput
            name="eventCode"
            label="Event Code"
            required={true}
            {...{ formik }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikInput label="Game Type" name="gameType" {...{ formik }} />
        </Grid>

        <Grid item xs={12}>
          <FormikInput
            label="Event Title"
            name="eventTitle"
            required={true}
            {...{ formik }}
          />
        </Grid>

        <Grid item xs={12}>
          <FormikInput
            label="Event Date"
            name="eventDate"
            required={true}
            {...{ formik }}
          />
        </Grid>

        <Grid item xs={12}>
          <FormikInput
            label="Account Group Id"
            name="accountGroupId"
            required={true}
            {...{ formik }}
          />
        </Grid>
      </Grid>
    </SectionWrapper>
  );
}

const useStyles = makeStyles(theme => ({
  sectionWrapper: {
    minHeight: 300
  },
  eventSelect: {
    fontFamily: theme.typography.fontFamily
  }
}));
