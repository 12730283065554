import React, { ReactElement, useRef, useState, useEffect } from 'react';
import { FormikInput } from 'src/components/FormikInput';
import { Grid, makeStyles, Box, Paper, useTheme } from '@material-ui/core';
import SectionWrapper from './SectionWrapper';
import { AttachMoneyOutlined } from '@material-ui/icons';

interface PropTypes {
  formik: any;
}

export default function PriceSection({ formik }: PropTypes): ReactElement {
  return (
    <SectionWrapper title="Prices and Inventory" icon={<AttachMoneyOutlined />}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormikInput
            label="Ticket Type Code"
            name="ticketTypeCode"
            required={true}
            {...{ formik }}
          />
        </Grid>

        <Grid item xs={12}>
          <FormikInput
            label="Ticket Type Name"
            name="ticketTypeName"
            required={true}
            {...{ formik }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikInput
            label="Price"
            name="price"
            required={true}
            {...{ formik }}
          />
        </Grid>

        <Grid item xs={12}>
          <FormikInput label="Price Code" name="priceCode" {...{ formik }} />
        </Grid>

        <Grid item xs={12}>
          <FormikInput
            label="Price Code Description"
            name="priceCodeDescription"
            {...{ formik }}
          />
        </Grid>

        <Grid item xs={12}>
          <FormikInput label="Class Name" name="className" {...{ formik }} />
        </Grid>

        <Grid item xs={12}>
          <FormikInput
            label="Service Fee"
            required={true}
            name="serviceFee"
            {...{ formik }}
          />
        </Grid>

        <Grid item xs={12}>
          <FormikInput
            label="Max Seat Count"
            required={true}
            name="maxCount"
            {...{ formik }}
          />
        </Grid>
      </Grid>
    </SectionWrapper>
  );
}

const useStyles = makeStyles(theme => ({
  sectionWrapper: {
    minHeight: 300
  },
  eventSelect: {
    fontFamily: theme.typography.fontFamily
  }
}));
