import { uiStateActionTypes as actionTypes } from './actionTypes';
import { store } from 'src/redux/store';

export const uiStateAction = {
  // Assignment ui actions
  setAssignmentCurrentPage: payload => ({
    type: actionTypes.setAssignmentCurrentPage,
    payload
  }),
  setAssignmentTotalCount: payload => ({
    type: actionTypes.setAssignmentTotalCount,
    payload
  }),
  setAssignmentTotalPageCount: payload => ({
    type: actionTypes.setAssignmentTotalPageCount,
    payload
  }),
  setAssignmentPerPage: payload => ({
    type: actionTypes.setAssignmentPerPage,
    payload
  })
};
