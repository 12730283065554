import React, { ReactElement, useEffect } from 'react';
import {
  Box,
  Dialog,
  Button,
  Typography,
  Grid,
  makeStyles,
  Divider,
  colors,
  Avatar,
  CircularProgress
} from '@material-ui/core';
import clsx from 'clsx';
import { usePopup, PopupPropType } from 'src/contexts/PopupContext';

import {
  CloseRounded,
  DoneAllRounded,
  ReportProblemOutlined,
  ErrorOutlineOutlined,
  InfoOutlined,
  Cancel as CancelIcon,
  CheckCircleOutlined
} from '@material-ui/icons';

export default function ConfirmDialog({
  title,
  description,
  component,
  icon,
  enableCloseIcon,
  heading,
  hideButtons,
  contentPadding,
  maxWidth,
  fullScreen
}: PopupPropType) {
  const styles = useStyles();
  const { isOpen, handleDecline, isLoading, actions } = usePopup();

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      maxWidth={maxWidth ? maxWidth : 'xs'}
      fullWidth
      PaperProps={{
        className: styles.dialogPaper
      }}
    >
      {isLoading && (
        <Box p={2} className={styles.loaderOverlay}>
          <CircularProgress size={28} color="primary" />
        </Box>
      )}
      <Box
        px={2}
        py={1}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid container spacing={1} alignItems="center">
          {icon && (
            <Grid item xs="auto">
              <Avatar variant="rounded" className={styles.avatar}>
                {icon === 'success' && (
                  <CheckCircleOutlined
                    className={clsx(styles.successIcon, styles.icon)}
                  />
                )}

                {icon === 'warning' && (
                  <ReportProblemOutlined className={styles.warningIcon} />
                )}

                {icon === 'info' && (
                  <InfoOutlined
                    className={clsx(styles.infoIcon, styles.icon)}
                  />
                )}

                {icon === 'error' && (
                  <ErrorOutlineOutlined className={styles.errorIcon} />
                )}
                {typeof icon === 'object' && <>{icon}</>}
              </Avatar>
            </Grid>
          )}

          {heading && (
            <Grid item xs="auto">
              <Typography variant="h4" color="textPrimary">
                {heading}
              </Typography>
            </Grid>
          )}
        </Grid>
        {enableCloseIcon && (
          <>
            <Box flexGrow={1} />
            <Box>
              <CancelIcon
                onClick={handleDecline}
                className={styles.closeIcon}
              />
            </Box>
          </>
        )}
      </Box>
      {(heading || icon) && <Divider />}

      <Box p={contentPadding > -1 ? contentPadding : 2}>
        <Box>
          <Typography color="textPrimary" variant="h5">
            {title}
          </Typography>
        </Box>

        <Box>
          <Typography color="textSecondary" variant="body1">
            {description}
          </Typography>
        </Box>
        <Box>{component}</Box>
      </Box>

      {!hideButtons && (
        <>
          <Divider />
          <Box px={2} py={1}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  className={styles.button}
                  disableElevation
                  variant="contained"
                  startIcon={<CloseRounded />}
                  onClick={handleDecline}
                >
                  cancel
                </Button>
              </Grid>

              <Grid item xs={6}>
                <Button
                  fullWidth
                  className={styles.button}
                  disableElevation
                  variant="contained"
                  color="primary"
                  startIcon={<DoneAllRounded />}
                  onClick={() => actions.submit()}
                >
                  confirm
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Dialog>
  );
}

const useStyles = makeStyles(theme => ({
  button: {},
  dialogPaper: {
    position: 'relative'
  },
  loaderOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255, 0.8)',
    zIndex: 15
  },

  avatar: {
    backgroundColor: 'transparent',
    width: 32,
    height: 32,
    border: `1px solid ${theme.palette.divider}`
  },
  icon: {
    fontSize: 28
  },
  warningIcon: {
    color: theme.palette.warning.main
  },
  errorIcon: {
    color: theme.palette.error.main
  },
  infoIcon: {
    color: theme.palette.info.main
  },
  successIcon: {
    color: theme.palette.success.main
  },
  closeIcon: {
    color: colors.grey[400],
    transition: 'all 0.2s ease',

    '&:hover': {
      color: colors.grey[500]
    }
  }
}));
