import React, { ReactElement } from 'react';
import {
  Box,
  PropTypes,
  makeStyles,
  lighten,
  colors,
  Button
} from '@material-ui/core';
import { NavLink as RouterLink } from 'react-router-dom';

interface PropTypes {
  icon: React.FC;
  title: string;
  href: string;
}

export const NavButton = ({
  icon: Icon,
  title,
  href
}: PropTypes): JSX.Element => {
  const styles = useStyles();

  return (
    <RouterLink
      className={styles.routerLink}
      to={href}
      activeClassName={styles.buttonActive}
    >
      <Button
        variant="text"
        className={styles.navButton}
        fullWidth
        disableRipple
        disableElevation
      >
        <Box data-iconshape={true} className={styles.iconShape}>
          <Icon />
        </Box>

        <Box className={styles.buttonText} data-buttontext={true}>
          {title}
        </Box>
      </Button>
    </RouterLink>
  );
};

const useStyles = makeStyles(theme => ({
  routerLink: {
    '&:focus-visible': {
      outline: 'none',

      '& .MuiButtonBase-root': {
        backgroundColor: theme.palette.divider
      }
    }
  },
  navButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 8px',
    justifyContent: 'flex-start',
    borderRadius: 6,
    border: `1px solid transparent`,
    transition: 'none',
    height: 50,
    minWidth: 48,
    overflow: 'hidden'
  },
  navButtonSm: {
    justifyContent: 'center',
    paddingLeft: 0,
    paddingRight: 0
  },
  iconShape: {
    minWidth: 32,
    minHeight: 32,
    backgroundColor: lighten(theme.palette.primary.main, 0.95),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    transition: 'all 0.3s ease',

    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
      fontSize: 20,
      transition: 'all 0.2s ease',
      transitionDelay: '0.1s'
    }
  },
  buttonText: {
    fontFamily: 'Roboto',
    marginLeft: 12,
    color: colors.grey[600],
    fontWeight: 500,
    fontSize: 12,
    whiteSpace: 'nowrap',
    transition: 'all 0.3s ease'
  },

  buttonActive: {
    '& .MuiButtonBase-root': {
      backgroundColor: '#ffffff',
      boxShadow: `0 8px 16px rgb(0 0 0 / 10%)`,
      border: `1px solid ${theme.palette.divider}`
    },

    '& .MuiBox-root[data-buttontext=true]': {
      color: theme.palette.primary.main
    },

    '& .MuiBox-root[data-iconshape=true]': {
      backgroundColor: theme.palette.primary.main,

      '& svg': {
        color: '#ffffff'
      }
    }
  }
}));
