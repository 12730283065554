import React from 'react';
import { Grid } from '@material-ui/core';
import MatInput from './MatInput';

export default function Fields({ formik }) {
  let { values } = formik;
  return (
    <>
      <Grid item xs={6}>
        <MatInput name="planName" label="Plan Name" {...{ formik }} />
      </Grid>

      <Grid item xs={6}>
        <MatInput name="planCode" label="Plan Code" {...{ formik }} />
      </Grid>
      <Grid item xs={6}>
        <MatInput name="planCost" label="Plan Cost" {...{ formik }} />
      </Grid>
      <Grid item xs={6}>
        <MatInput
          name="maxEventCount"
          label="Max Event Count"
          {...{ formik }}
        />
      </Grid>

      <Grid item xs={6}>
        <MatInput name="serviceCharge" label="Service Charge" {...{ formik }} />
      </Grid>

      <Grid item xs={6}>
        <MatInput
          name="seatPermutation"
          label="Seat Permutation"
          {...{ formik }}
        />
      </Grid>
      <Grid item xs={6}>
        <MatInput
          name="ticketTypeCode"
          label="Ticket Type Code"
          {...{ formik }}
        />
      </Grid>
      <Grid item xs={6}>
        <MatInput name="sectionName" label="Section Name" {...{ formik }} />
      </Grid>

      <Grid item xs={6}>
        <MatInput name="bannerImage" label="Banner Image URL" {...{ formik }} />
      </Grid>

      {values.isExclusive === 1 && (
        <Grid item xs={6}>
          <MatInput name="lookupEvent" label="Lookup Event" {...{ formik }} />
        </Grid>
      )}

      <Grid item xs={12}>
        <MatInput
          name="planDescription"
          label="Plan Details"
          multiline
          rows={3}
          {...{ formik }}
        />
      </Grid>
    </>
  );
}
