import React, { ChangeEventHandler, ReactElement } from 'react';
import { Box, FormControlLabel, Checkbox, Typography } from '@material-ui/core';

type PropTypes = {
  title?: string;
  subtitle?: string;
  label: string;
  name: string;
  checked: boolean;
  onChange: ChangeEventHandler;
  disabled?: boolean;
};

export default function MatCheckbox({
  title,
  subtitle,
  name,
  checked,
  label,
  onChange,
  disabled
}: PropTypes): ReactElement {
  return (
    <Box>
      {title && (
        <Typography variant="h5" color="textPrimary" gutterBottom>
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography variant="body2" color="textSecondary">
          {subtitle}
        </Typography>
      )}

      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            name={name}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
          />
        }
        label={label}
      />
    </Box>
  );
}
