import React, { ReactElement, useState } from 'react';
import { FormikInput } from 'src/components/FormikInput';
import { FormikSelect } from 'src/components/FormikSelect';
import { Grid, makeStyles, Box } from '@material-ui/core';
import SectionWrapper from './SectionWrapper';
import { ScheduleOutlined } from '@material-ui/icons';
import { toSqlFormat } from 'src/utils/datetime';
import { FormikCheckbox } from 'src/components/FormikCheckbox';
import { DateRangeSelector } from './DateRangeSelector';

interface PropTypes {
  formik: any;
}

export default function ClaimSection({ formik }: PropTypes): ReactElement {
  const styles = useStyles();
  const changeDateTime = event => {
    const fieldName = event.target.name;
    const sqlDate = toSqlFormat(event.target.value);
    const value = sqlDate.includes('NaN') ? '' : sqlDate;
    formik.setFieldValue(fieldName, value);
  };

  const changeClaimDateRange = values => {
    const { startDate, endDate } = values;
    const startSqlDate = toSqlFormat(startDate);
    const endSqlDate = toSqlFormat(endDate);
    formik.setFieldValue('claimStartDate', startSqlDate);
    formik.setFieldValue('claimEndDate', endSqlDate);
  };

  const resetClaimDateRange = () => {
    formik.setFieldValue('claimStartDate', '');
    formik.setFieldValue('claimEndDate', '');
  };

  return (
    <SectionWrapper title="Claim Details" icon={<ScheduleOutlined />}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormikInput
            label="Claim Plan Code"
            name="claimPlanCode"
            required={true}
            {...{ formik }}
          />
        </Grid>

        <Grid item xs={12}>
          <FormikSelect
            name="claimTimeZone"
            label="Claim Time Zone"
            options={[
              'pst',
              'America/New_York',
              'America/Chicago',
              'America/Los_Angeles'
            ]}
            {...{ formik }}
          />
        </Grid>

        <Grid item xs={12}>
          <DateRangeSelector
            startDate={formik.values['claimStartDate']}
            endDate={formik.values['claimEndDate']}
            onConfirm={changeClaimDateRange}
            onReset={resetClaimDateRange}
            {...{ formik }}
          />
        </Grid>

        <Grid item xs={12}>
          <Box p={1}>
            <FormikCheckbox
              title="Is GA ?"
              label={formik.values['isGA'] ? 'YES' : 'NO'}
              name="isGA"
              checked={formik.values['isGA'] ? true : false}
              onChange={e => {
                formik.setFieldValue('isGA', formik.values['isGA'] ? 0 : 1);
              }}
            />
            {formik.errors['isGA']}
          </Box>
        </Grid>
      </Grid>
    </SectionWrapper>
  );
}

const useStyles = makeStyles(theme => ({
  sectionWrapper: {
    minHeight: 300
  },
  eventSelect: {
    fontFamily: theme.typography.fontFamily
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginLeft: 3,
    lineHeight: 1
  }
}));
