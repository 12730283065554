import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box,
  FormControl,
  OutlinedInput,
  InputLabel,
  Typography,
  Button,
  Select,
  MenuItem,
  CircularProgress,
  Paper
} from '@material-ui/core';
import { updateAssignments, markAsAttending } from 'src/redux/actions';
import { useSnackbar } from 'notistack';

function AttendanceDialog({ open, handleAttendanceDialogClose, rowData }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar: showAlert } = useSnackbar();
  const { activeTeam, selectedPlan, selectedEvent } = useSelector(
    state => state
  );
  const [isAttending, setIsAttending] = useState(rowData?.is_attending);
  const [loading, setLoading] = useState(false);

  const handleChange = event => {
    setIsAttending(event.target.value);
  };

  const handleAttendance = async () => {
    setLoading(true);
    console.log(rowData);
    console.log(selectedPlan);
    console.log(rowData.event_code);
    try {
      let response = await markAsAttending({
        teamId: activeTeam.team_id,
        eventCode: rowData.event_code,
        subscriberId: rowData.user_id_fk,
        isSubscriber: rowData.is_subscriber,
        isAttending
      });

      if (response.status === 200) {
        updateAssignments(activeTeam.team_id, rowData.event_code);
        handleAttendanceDialogClose();
        showAlert('Attendance edited successfull', { variant: 'success' });
      }
    } catch (error) {
      console.log(error);
      showAlert('Something went wrong, please try again', { variant: 'error' });
    }
    setLoading(false);
  };

  return (
    <Dialog open={open}>
      <DialogTitle disableTypography>
        <Typography variant="h5" color="textPrimary">
          Set Attendance
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Paper elevation={0} variant="outlined">
          <Box width={300} p={2} display="flex" justifyContent="space-between">
            <Box textAlign="left">
              <Typography variant="subtitle2" color="textPrimary">
                Name
              </Typography>
              <Typography variant="subtitle2" color="textPrimary">
                Phone
              </Typography>
              <Typography variant="subtitle2" color="textPrimary">
                Assigned Seat
              </Typography>
              <Typography variant="subtitle2" color="textPrimary">
                Attending
              </Typography>
              <Typography variant="subtitle2" color="textPrimary">
                Sold order no.
              </Typography>
              <Typography variant="subtitle2" color="textPrimary">
                Reserved order no.
              </Typography>
            </Box>
            <Box textAlign="right">
              <Typography variant="subtitle2" color="textSecondary">
                {rowData.full_name ? rowData.full_name : 'unavailable'}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {rowData.phone_number ? rowData.phone_number : 'unavailable'}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {rowData.assigned_seat ? rowData.assigned_seat : 'unavailable'}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {rowData.is_attending ? 'Yes' : 'No'}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {rowData.seat_sold_order_number
                  ? rowData.seat_sold_order_number
                  : 'unavailable'}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {rowData.seat_reserve_order_number
                  ? rowData.seat_reserve_order_number
                  : 'unavailable'}
              </Typography>
            </Box>
          </Box>
        </Paper>
        <Box mt={2}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel htmlFor="edit-attendance">Is attending</InputLabel>
            <Select
              id="edit-attendance"
              defaultValue={isAttending}
              onChange={handleChange}
            >
              <MenuItem value={1}>Yes</MenuItem>
              <MenuItem value={0}>No</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleAttendanceDialogClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleAttendance}>
          save
          {loading && (
            <CircularProgress
              size={12}
              color="primary"
              style={{ marginLeft: 4, marginBottom: 2 }}
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default AttendanceDialog;
