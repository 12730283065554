import React, { forwardRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

// const Page = forwardRef((props, ref) => {
//   const title = props.title;
//   const children = props.children;
//   return (
//     <div ref={ref} {...props}>
//       <Helmet>
//         <title>{title}</title>
//       </Helmet>
//       {children}
//     </div>
//   );
// });

const Page = props => {
  const title = props.title;
  const children = props.children;

  return (
    <div {...props}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
};

// Page.propTypes = {
//   children: PropTypes.node.isRequired,
//   title: PropTypes.string
// };

export default Page;
