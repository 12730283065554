const ACTION_TYPES = {
  updateDashboardSummary: 'UPDATE_DASHBOARD_SUMMARY',
  updateTeams: 'UPDATE_TEAMS',
  updateActiveTeam: 'UPDATE_ACTIVE_TEAM',
  updateAssignments: 'UPDATE_ASSIGNMENTS',
  updateSubscribers: 'UPDATE_SUBSCRIBERS',

  updateSelectedPlan: 'UPDATE_SELECTED_PLAN',
  updateSelectedEvent: 'UPDATE_SELECTED_EVENT',

  updateTeamSettings: 'UPDATE_TEAM_SETTINGS',
  sellSeat: 'SELL_SEAT',
  updateInventorySummary: 'UPDATE_INVENTORY_SUMMARY',
  updateInventoryDetails: 'UPDATE_INVENTORY_DETAILS',
  updateSportTeams: 'UPDATE_SPORT_TEAMS',
  getAllClaims: 'GET_ALL_CLAIMS',

  getAllEvents: 'GET_ALL_EVENTS',
  getAllTicketTypes: 'GET_ALL_TICKET_TYPES',
  getAllAccountGroups: 'GET_ALL_ACCOUNT_GROUPS',
  getAllProductMappings: 'GET_ALL_PRODUCT_MAPINGS',
  deleteProductMappings: 'DELETE_PRODUCT_MAPPINGS',

  // Events action types

  // Subscription plan action types
  setSubscriptionPlans: 'subscriptionPlans/set',

  // Events action type
  setEvents: 'events/set',
  setAllEvents: 'allEvents/set'
};

export default ACTION_TYPES;
