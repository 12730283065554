import React from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import clsx from 'clsx';

interface ActionButtonPropType {
  variant: 'regular' | 'warning';
  onClick: Function;
  text: string;
  icon: JSX.Element;
}

export const ActionButton = ({
  variant,
  onClick,
  text,
  icon
}: ActionButtonPropType) => {
  const styles = useStyles();
  return (
    <ListItem
      button={true}
      className={clsx(styles.listItem, styles[variant])}
      onClick={event => {
        event.stopPropagation();
        onClick();
      }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItem>
        <ListItemText primary={text} />
      </ListItem>
    </ListItem>
  );
};

const useStyles = makeStyles(theme => ({
  listItem: {
    paddingLeft: 4,
    paddingRight: 4,

    '& .MuiListItemIcon-root': {
      minWidth: 24,

      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.primary.main
      }
    },
    '& .MuiListItem-root': {
      padding: 0,
      '& .MuiListItemText-root': {
        margin: 0,

        '& .MuiTypography-root': {
          fontWeight: 500,
          textTransform: 'uppercase',
          fontSize: 12,
          color: theme.palette.primary.main
        }
      }
    }
  },

  regular: {},
  warning: {
    '& .MuiListItemIcon-root': {
      '& .MuiSvgIcon-root': {
        color: theme.palette.warning.main
      }
    },
    '& .MuiListItem-root': {
      '& .MuiListItemText-root': {
        '& .MuiTypography-root': {
          color: theme.palette.warning.main
        }
      }
    }
  }
}));
