import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  makeStyles,
  Container,
  Grid,
  Paper,
  Divider
} from '@material-ui/core';
import Page from 'src/components/Page';
import ClaimTable from './ClaimTable';

export default function TicketClaimsView() {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Ticket Claims">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ClaimTable />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));
