import React from 'react';
import { Box } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { SubscriberType } from 'src/types';

interface PropTypes {
  subscribers: SubscriberType;
}

const columns = [
  // {
  //   field: 'id',
  //   headerName: 'Actions',
  //   width: 140,
  //   sortable: false
  //    renderCell: params => {
  //      return (
  //        <MappingActions
  //          product={productMappings.find(P => P.id == params.id)}
  //        />
  //      );
  //    }
  // },
  { field: 'id', headerName: 'ID', width: 150 },
  { field: 'fullName', headerName: 'Full Name', width: 200 },
  { field: 'accountNumber', headerName: 'Account Number', width: 200 },
  { field: 'createdAt', headerName: 'createdAt', width: 200 },
  {
    field: 'reserveOrderNumber',
    headerName: 'Reserve Order Number',
    width: 200
  },
  { field: 'phoneNumber', headerName: 'Phone Number', width: 200 },
  { field: 'emailAddress', headerName: 'emailAddress', width: 200 },
  { field: 'seatCount', headerName: 'Seat Count', width: 200 },
  { field: 'status', headerName: 'Status', width: 200 }
];

export const SubscriberTable = ({ subscribers }) => {
  return (
    <Box display="flex" height="600px" width="100%">
      <Box flexGrow={1}>
        <DataGrid
          style={{ border: 'none' }}
          columns={columns}
          rows={subscribers}
        />
      </Box>
    </Box>
  );
};
