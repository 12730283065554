import React from 'react';
import { Button, makeStyles, lighten } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

interface PropTypes {
  dataCount: number;
}

export const TotalDataCountViewer = ({ dataCount }: PropTypes): JSX.Element => {
  const styles = useStyles();

  return (
    <Button
      size="small"
      variant="outlined"
      className={styles.button}
      startIcon={<InfoOutlined />}
    >
      TOTAL DATA COUNT : {dataCount}
    </Button>
  );
};

const useStyles = makeStyles(theme => ({
  button: {
    pointerEvents: 'none',
    height: 26,
    fontSize: 12,
    fontWeight: 400,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: lighten(theme.palette.primary.main, 0.95),
    color: theme.palette.primary.main
  }
}));
