import React from 'react';
import {
  Box,
  Grid,
  Avatar,
  Typography,
  Divider,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import { MatStyleType } from 'src/types';
import { IconProps } from '@material-ui/core';

interface PropType {
  title: string;
  icon?: JSX.Element;
  loading?: boolean;
  secondaryElement?: JSX.Element;
}
export const SectionHeader = ({
  title,
  icon,
  loading,
  secondaryElement
}: PropType) => {
  const styles: MatStyleType = useStyles();

  return (
    <>
      <Box
        p={2}
        py={1}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Box>
          <Grid container spacing={2} alignItems="center">
            {icon && (
              <Grid item xs="auto">
                <Avatar className={styles.avatar} variant="rounded">
                  {loading ? <CircularProgress size={18} /> : <>{icon}</>}
                </Avatar>
              </Grid>
            )}

            <Grid item xs="auto">
              <Typography variant="h5" color="textPrimary">
                {title}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {secondaryElement && (
          <>
            <Box flexGrow={1} />
            {secondaryElement}
          </>
        )}
      </Box>
      <Divider />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: 'transparent',
    width: 32,
    height: 32,
    border: `1px solid ${theme.palette.divider}`,

    '& .MuiSvgIcon-root': {
      fontSize: 24,
      color: theme.palette.primary.main
    }
  }
}));

export default SectionHeader;
