import axios from 'axios';
import { endpoints } from './endpoints';

export const getEventsBySelectedPlan = async (
  teamId: string,
  subscriptionPlanId: number
) => {
  const response = await axios.post(endpoints.events, {
    teamId,
    subscriptionPlanId
  });
  const { events } = response.data;
  return events;
};

export const getAllEvents = async teamId => {
  const response = await axios.post(endpoints.getAllEvents, {
    teamId
  });
  const { events } = response.data;
  console.log(events);
  return events;
};

export const eventApi = {
  getEventsBySelectedPlan,
  getAllEvents
};
