import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import Page from 'src/components/Page';
import PageHeader from 'src/components/PageHeader';
import { PlanSection } from './PlanSection';
import { SubscriberSection } from './SubscriberSection';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionPlans, updateSubscribers } from 'src/redux/actions';
import { SubscriptionPlanType, ReduxState, SelectorFn } from 'src/types';

export default function SubscriberV2View(): JSX.Element {
  const { activeTeam, subscriptionPlans } = useSelector(
    (state: ReduxState) => state
  );
  const dispatch = useDispatch();
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlanType>();
  const handleSelectPlan: SelectorFn<SubscriptionPlanType> = plan =>
    setSelectedPlan(plan);

  const init = () => {
    const plans = subscriptionPlans;
    const isSelected = !!selectedPlan;
    if (isSelected) return;
    if (plans.length > 0) handleSelectPlan(plans[0]);
  };

  const getPlans = () => {
    (async () => {
      if (!activeTeam) return;
      const plans = await getSubscriptionPlans(activeTeam.team_id);
    })();
  };

  const getSubscribers = () => {
    (async () => {
      if (!selectedPlan) return;
      const subscribers = updateSubscribers({
        teamId: activeTeam.team_id,
        subscriberPlanId: selectedPlan.id
      });
      await dispatch(subscribers);
    })();
  };

  useEffect(init, [subscriptionPlans]);
  useEffect(getPlans, [activeTeam]);
  useEffect(getSubscribers, [selectedPlan]);

  return (
    <Page title="Subscribers">
      <Box p={4}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <PageHeader title="Subscribers" />
          </Grid>
          <Grid item xs={12}>
            <PlanSection {...{ selectedPlan, handleSelectPlan }} />
          </Grid>
          <Grid item xs={12}>
            <SubscriberSection />
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
}
