import React, { ReactElement, useState } from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import Page from 'src/components/Page';
import { ProductMappingTable } from 'src/components/ProductMappingTable/ProductMappingTable';
import { ProductMappingForm } from 'src/components/ProductMappingForm';

export default function ProductMappingsView(): ReactElement {
  return (
    <Page title="Product Mappings">
      <Box p={4}>
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <Box>
                <Typography variant="h3" color="textPrimary">
                  Single Game Sales Setup
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" color="textSecondary">
                  Configure product and account group mappings to enable single
                  game sales.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box mt={4}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ProductMappingForm title="ADD NEW MAPPING" />
            </Grid>

            <Grid item xs={12}>
              <ProductMappingTable />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Page>
  );
}
