import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  makeStyles,
  Grid,
  Avatar,
  Divider
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { getAllClaims } from 'src/redux/actions';
import Empty from 'src/components/Empty';
import DeleteProdMap from 'src/components/DeleteProdMap';
import { getAllProductMapping } from 'src/redux/actions';
import MappingActions from './MappingActions';
import { DataGrid } from '@mui/x-data-grid';
import { EventNoteOutlined } from '@material-ui/icons';
import { useAsyncState } from 'src/hooks/useAsyncState';
import SectionHeader from 'src/components/SectionHeader';
import { AsyncEmpty } from 'src/components/AsyncEmpty';

export const ProductMappingTable = () => {
  const status = useAsyncState('productMappings');
  const styles = useStyles();
  const dispatch = useDispatch();
  const { activeTeam, productMappings } = useSelector(state => state);
  const haveProductMappings = productMappings.length > 0;

  const updateData = async () => {
    if (!activeTeam) return;
    await dispatch(getAllProductMapping(activeTeam.team_id));
  };

  useEffect(() => {
    updateData();
  }, [activeTeam]);

  const columns = [
    {
      field: 'id',
      headerName: 'Actions',
      width: 140,
      sortable: false,
      renderCell: params => {
        return (
          <MappingActions
            product={productMappings.find(P => P.id == params.id)}
          />
        );
      }
    },
    // { field: 'id', headerName: 'ID', width: 100 },
    { field: 'accountGroupId', headerName: 'Account Group', width: 200 },
    { field: 'eventCode', headerName: 'Event Code', width: 200 },
    { field: 'ticketTypeCode', headerName: 'Ticket Type Code', width: 200 },
    { field: 'ticketTypeName', headerName: 'Ticket Type Name', width: 200 },
    { field: 'price', headerName: 'Price', width: 200 },
    { field: 'serviceFee', headerName: 'Service Fee', width: 200 },
    { field: 'priceCode', headerName: 'Price Code', width: 200 },
    { field: 'priceCodeDescription', headerName: 'Price Desc.', width: 200 },
    { field: 'className', headerName: 'Class Name', width: 200 },
    { field: 'maxCount', headerName: 'Seat Count', width: 200 },
    { field: 'claimPlanCode', headerName: 'Claim Plan Code', width: 200 },
    { field: 'gameType', headerName: 'Game Type', width: 200 },
    { field: 'eventTitle', headerName: 'Event Title', width: 200 },
    { field: 'eventDate', headerName: 'Event DateTime', width: 200 },
    { field: 'claimStartDate', headerName: 'Claim Start', width: 200 },
    { field: 'claimEndDate', headerName: 'Claim End', width: 200 },
    { field: 'claimTimeZone', headerName: 'Time Zone', width: 200 },
    { field: 'isGA', headerName: 'Is GA', width: 150 }
  ];

  return (
    <Paper variant="outlined" className={styles.paper}>
      <SectionHeader
        title="ALL PRODUCT MAPPINGS"
        icon={<EventNoteOutlined />}
      />

      {!haveProductMappings && (
        <Box
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <AsyncEmpty {...{ status }} haveData={haveProductMappings} />
        </Box>
      )}
      {(status.idle || status.success) && haveProductMappings && (
        <Box display="flex" height="750px">
          <Box flexGrow={1}>
            <DataGrid
              style={{ border: 'none' }}
              columns={columns}
              rows={productMappings}
            />
          </Box>
        </Box>
      )}
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    height: 800
  },
  icon: {
    fontSize: 24
  },
  avatar: {
    backgroundColor: 'transparent',
    width: 32,
    height: 32,
    border: `1px solid ${theme.palette.divider}`
  }
}));
