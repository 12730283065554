const twoPad = (s: number | string) => {
  if (s.toString().length < 2) return '0' + s.toString();
  else return s;
};
export const toSqlFormat = (dateString: string): string => {
  const date = new Date(dateString);
  const d = date.getDate(),
    m = twoPad(date.getMonth() + 1),
    y = date.getFullYear(),
    H = twoPad(date.getHours()),
    M = twoPad(date.getMinutes()),
    S = twoPad(date.getSeconds());
  return `${y}-${m}-${d} ${H}:${M}:${S}`;
};

export const parseSqlDate = (dateString: string): string => {
  const date = new Date(dateString);
  const d = date.getDate(),
    m = twoPad(date.getMonth() + 1),
    y = date.getFullYear(),
    H = twoPad(date.getHours()),
    M = twoPad(date.getMinutes());
  return `${y}-${m}-${d}T${H}:${M}`;
};
