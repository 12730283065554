import React, { useState } from 'react';
import {
  Box,
  Dialog,
  Button,
  CircularProgress,
  colors,
  Typography,
  Divider,
  makeStyles,
  DialogActions
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

export default function QButton({
  name,
  api,
  dialogTitle,
  apiCall,
  generateQstatus,
  handleQDialog
}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  let { enqueueSnackbar: toast } = useSnackbar();

  // Toggle dialog
  const handleDialog = open => {
    setOpen(open);
  };

  const handleConfirm = async () => {
    setLoading(true);
    await apiCall(api, res => toast(res.data.message, { variant: 'success' }));
    // await generateQstatus();
    handleDialog(false);
    handleQDialog(false);
    setLoading(false);
  };

  return (
    <>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        size="small"
        onClick={() => handleDialog(true)}
        style={{
          whiteSpace: 'nowrap'
        }}
      >
        {name}
      </Button>
      <Dialog open={open}>
        <Box p={3}>
          <Box mb={3}>
            <Typography variant="h5" color="textPrimary">
              {dialogTitle}
            </Typography>
          </Box>
        </Box>
        <DialogActions>
          <Button color="primary" onClick={() => handleDialog(false)}>
            No
          </Button>

          <Button disabled={loading} color="primary" onClick={handleConfirm}>
            Yes
            {loading && (
              <CircularProgress
                size={14}
                style={{
                  marginLeft: 5,
                  color: 'gray'
                }}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const useStyles = makeStyles(() => ({}));
