import React from 'react';
import {
  Box,
  Divider,
  Grid,
  Paper,
  makeStyles,
  Typography,
  Avatar,
  colors
} from '@material-ui/core';
import ActionRow from './ActionRow';
import { withFormik } from './withFormik';
import EventSection from './EventSection';
import PriceSection from './PriceSection';
import ClaimSection from './ClaimSection';
import { AccountTreeOutlined } from '@material-ui/icons';
import { IProductMapping } from 'src/redux/model';
import SectionHeader from 'src/components/SectionHeader';

type PropTypes = {
  isUpdate?: boolean;
  title: string;
  product?: IProductMapping;
};

export const ProductMappingForm = ({
  title,
  isUpdate,
  product
}: PropTypes): JSX.Element => {
  const styles = useStyles();

  // created a higher order component version of forms
  // with formik in the state, in this way we have the
  // formik functionality in separate file and inputs
  // are separated also
  const FormikForm = withFormik(({ formik }) => (
    <form onSubmit={formik.handleSubmit} {...{ formik }}>
      <Box p={3}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} lg={4}>
            <EventSection {...{ formik }} />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <PriceSection {...{ formik }} />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <ClaimSection {...{ formik }} />
          </Grid>
        </Grid>
      </Box>
      <ActionRow {...{ formik, isUpdate }} />
    </form>
  ));

  return (
    <>
      {/* If it is update action triggered from mapping table,
        then only render the form withou titles, because title
        will be handled by the popup
      */}
      {isUpdate && <FormikForm {...{ isUpdate, product }} />}

      {/* If it is not update then rendering decorated version of form
          with titles and paper
        */}
      {!isUpdate && (
        <Paper variant="outlined" className={styles.paper}>
          {/* <Box
            p={2}
            py={1}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs="auto">
                <Avatar className={styles.avatar} variant="rounded">
                  <AccountTreeOutlined
                    color="primary"
                    className={styles.icon}
                  />
                </Avatar>
              </Grid>
              <Grid item xs="auto">
                <Typography variant="h4" color="textPrimary">
                  {title}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Divider /> */}
          <SectionHeader title={title} icon={<AccountTreeOutlined />} />
          <FormikForm />
        </Paper>
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    overflow: 'hidden'
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,0.8)',
    zIndex: 99
  },
  icon: {
    fontSize: 24
  },
  avatar: {
    backgroundColor: 'transparent',
    width: 32,
    height: 32,
    border: `1px solid ${theme.palette.divider}`
  },

  closeIcon: {
    fontSize: 24,
    color: colors.grey[400],
    transition: 'all 0.2s ease',

    '&:hover': {
      color: colors.grey[500]
    }
  }
}));
