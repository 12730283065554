import React, {
  ChangeEventHandler,
  ReactElement,
  SyntheticEvent,
  useEffect,
  useState
} from 'react';
import Page from 'src/components/Page';
import { Box, Typography, Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getTeamSettings, updateTeamSettings } from 'src/redux/actions';
import GeneralFields from './GeneralFields';
import { RootState, ITeamSettings } from 'src/redux/model';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import SmsSettingsFields from './SmsSettingsFields';
import CommunicationFields from './CommunicationFields';
import AddNewTeam from './AddNewTeam';

export default function SettingsV2View(): ReactElement {
  const dispatch = useDispatch();
  const [settingsLoading, setSettingsLoading] = useState(false);
  const { activeTeam, teamSettings } = useSelector((state: RootState) => state);

  const loadTeamSettings = async () => {
    setSettingsLoading(true);
    activeTeam && (await dispatch(getTeamSettings(activeTeam.team_id)));
    setSettingsLoading(false);
  };

  //Loading team settings from server
  useEffect(() => {
    loadTeamSettings();
  }, [activeTeam]);

  // Save team settings
  const saveTeamSettings = async (values: ITeamSettings) => {
    await updateTeamSettings(values);
    await dispatch(getTeamSettings(activeTeam.team_id));
  };

  // Initializing formik
  const { values, handleSubmit, setFieldValue, initialValues } = useFormik({
    initialValues: teamSettings,
    onSubmit: saveTeamSettings,
    enableReinitialize: true
  });

  const handleChange: ChangeEventHandler = (event: SyntheticEvent): void => {
    let target = event.target as HTMLTextAreaElement;
    let value = target.value;
    let name = target.name;
    target.type === 'checkbox' && setFieldValue(name, values[name] ? 0 : 1);
    target.type !== 'checkbox' && setFieldValue(name, value ? value : null);
  };

  const handleSelect = event => {
    let target = event.target as HTMLTextAreaElement;
    let value = target.value;
    let name = target.name;
    setFieldValue(name, value ? value : null);
  };

  // Own function for checking touched fields as formik.touched is
  // not working.
  const checkTouched = (fields: string[]): boolean => {
    let touched = false;
    let changes = {};
    if (values) {
      for (let key in initialValues) {
        initialValues[key] !== values[key] && (changes[key] = true);
      }

      fields.map(field => {
        changes[field] !== undefined && (touched = true);
      });
    }
    return touched;
  };

  return (
    <Page title="Team Settings">
      <Box p={4}>
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <Box>
                <Typography variant="h3" color="textPrimary">
                  Team Settings
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1" color="textSecondary">
                  Confgure team settings, add new team from here
                </Typography>
              </Box>

              <Box mt={1}>
                <AddNewTeam />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box mt={4}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6} lg={4}>
                <GeneralFields
                  {...{
                    values,
                    handleChange,
                    checkTouched,
                    saveTeamSettings,
                    settingsLoading
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <SmsSettingsFields
                  {...{
                    values,
                    handleChange,
                    checkTouched,
                    saveTeamSettings,
                    settingsLoading
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <CommunicationFields
                  {...{
                    values,
                    handleChange,
                    handleSelect,
                    checkTouched,
                    saveTeamSettings,
                    settingsLoading
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Page>
  );
}
