import React, {
  useContext,
  createContext,
  useState,
  ReactElement,
  ReactEventHandler,
  SyntheticEvent
} from 'react';
import Popup from 'src/components/Popup';

export type PopupPropType = {
  heading?: string;
  title?: string;
  description?: string;
  component?: ReactElement | JSX.Element | React.FC;
  enableCloseIcon?: boolean;
  icon?: 'success' | 'info' | 'error' | 'warning' | ReactElement;
  hideButtons?: boolean;
  contentPadding?: number;
  maxWidth?: 'xs' | 'lg' | 'xl' | 'md';
  fullScreen?: boolean;
};

type ActionsType = {
  submit: Function;
};

type PreConfigType =
  | 'delete'
  | 'update'
  | 'info'
  | 'success'
  | 'error'
  | 'warning';
type DefinedFnType = (type: PreConfigType, config: PopupPropType) => void;
type FireFnType = (config?: PopupPropType) => void;

type ContextType = {
  isOpen: boolean;
  onConfirm: Function;
  handleDecline: ReactEventHandler;
  loading: Function;
  isLoading: boolean;
  actions: ActionsType;
  fire: FireFnType;
  close: Function;
  fireDelete: FireFnType;
  fireError: FireFnType;
  fireSuccess: FireFnType;
  fireInfo: FireFnType;
  fireWarning: FireFnType;
  fireUpdate: FireFnType;
};

// Declaring context
const PopupContext = createContext<ContextType | null>(null);

// Defining some default action Ex : default submit
const defaultActions: ActionsType = {
  submit: () => console.log('No submit registered')
};

export const PopupProvider = ({ children }): ReactElement => {
  // Popup state
  const [popupProps, setPopupProps] = useState<PopupPropType>();

  // Context level state and handlers
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [actions, setActions] = useState<ActionsType>(defaultActions);
  const fire = (config: PopupPropType) => {
    config && setPopupProps(config);
    setIsOpen(true);
  };
  const close = () => {
    setIsOpen(false);
    setActions(defaultActions);
  };
  const onConfirm = (action: Function) =>
    setActions({ ...actions, submit: action });

  const handleDecline = () => {
    setIsOpen(false);
    setActions(defaultActions);
  };
  const loading = (loading: boolean) => setIsLoading(loading);

  // Predefined popups with exposable function
  const defined: DefinedFnType = (
    type: PreConfigType,
    config: PopupPropType
  ) => {
    const withType = Object.assign(preConfigs[type], config);
    setPopupProps(withType);
    setIsOpen(true);
  };

  // Exposable predefined trigger function
  const fireDelete = (config: PopupPropType) => defined('delete', config);
  const fireError = (config: PopupPropType) => defined('error', config);
  const fireSuccess = (config: PopupPropType) => defined('success', config);
  const fireWarning = (config: PopupPropType) => defined('warning', config);
  const fireInfo = (config: PopupPropType) => defined('info', config);
  const fireUpdate = (config: PopupPropType) => defined('update', config);

  return (
    <PopupContext.Provider
      value={{
        isOpen,
        fire,
        close,
        handleDecline,
        loading,
        isLoading,
        onConfirm,
        actions,
        fireDelete,
        fireError,
        fireSuccess,
        fireInfo,
        fireUpdate,
        fireWarning
      }}
    >
      {children}
      <Popup {...popupProps} />
    </PopupContext.Provider>
  );
};

export const usePopup = () => {
  const props = useContext(PopupContext);
  return props as ContextType;
};

const preConfigs = {
  delete: {
    icon: 'warning',
    heading: 'Confirm Delete',
    title: 'Are you sure want to delete ?',
    description: 'This action is irreversible.',
    enableCloseIcon: true
  },

  update: {
    icon: 'warning',
    heading: 'Confirm Update',
    title: 'Are you sure want to Update ?',
    description: 'This action is irreversible.',
    enableCloseIcon: true
  },

  error: {
    icon: 'error',
    heading: 'Error',
    title: 'Something went wrong',
    description: 'Please try again',
    enableCloseIcon: true
  },
  warning: {
    icon: 'warning',
    heading: 'Warning',
    title: 'Are you sure about this ?',
    description: 'This action is irreversible.',
    enableCloseIcon: true
  },
  info: {
    icon: 'info',
    heading: 'Info',
    title: 'Some Information',
    description: 'Check and confirm info.',
    enableCloseIcon: true
  },
  success: {
    icon: 'success',
    heading: 'Success',
    title: 'Successfully completed',
    description: 'You can close this popup now.',
    enableCloseIcon: true
  }
};
