import React, { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  Container,
  CircularProgress,
  Paper
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { getAllAccountGroups } from 'src/redux/actions';
import AccountGroupsTable from './AccountGroupsTable';
import Empty from 'src/components/Empty';

export default function AccountGroupsView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeTeam, accountGroups } = useSelector(state => state);

  const getAccountGroups = () => {
    activeTeam && dispatch(getAllAccountGroups(activeTeam.team_id));
  };

  useEffect(getAccountGroups, [activeTeam]);

  return (
    <Page className={classes.root} title="Account Groups">
      <Container maxWidth={false}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box>
              <Typography variant="h3" color="textPrimary">
                Account Groups
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Paper variant="outlined">
              {accountGroups.length > 0 ? (
                <AccountGroupsTable {...{ accountGroups }} />
              ) : (
                <Empty caption="No account groups found" />
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  circularLoading: {
    color: '#ffffff',
    marginLeft: 10
  }
}));
