import React from 'react';
import { Box, Typography, FormControlLabel, Checkbox } from '@material-ui/core';

export default function MatSwitch({ title, label, checked, onChange, name }) {
  return (
    <Box>
      <Typography variant="h5" color="textPrimary">
        {title}
      </Typography>
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            name={name}
            color="primary"
            checked={checked}
            onChange={onChange}
          />
        }
      />
    </Box>
  );
}
