import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DashboardView from 'src/views/DashboardView';
import AssignmentView from 'src/views/AssignmentView';
import SubscriptionPlansView from 'src/views/SubscriptionPlansView';
// import SettingsView from 'src/views/SettingsView';
import InventoryView from 'src/views/InventoryView';
import LoginView from 'src/views/Auth/LoginView';
import SubscriberDetails from 'src/views/SubscriberDetails';
import ProductMappingsView from 'src/views/ProductMappingsView';
import TicketClaimsView from 'src/views/TicketClaimsView';
import AccountGroupsView from 'src/views/AccountGroupsView';
// import InventoryDetails from 'src/views/InventoryDetails';
import SettingsV2View from 'src/views/SettingsV2View';
import SubscribersV2View from 'src/views/SubscribersV2View';

const routes = [
  { path: 'login', element: <LoginView /> },
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      { path: '/', element: <Navigate to="/assignments" /> },
      { path: 'assignments', element: <AssignmentView /> },
      { path: 'subscribers', element: <SubscribersV2View /> },
      { path: 'subscription_plans', element: <SubscriptionPlansView /> },
      { path: 'inventory', element: <InventoryView /> },
      { path: 'settings', element: <SettingsV2View /> },
      { path: 'subscriberdetails', element: <SubscriberDetails /> },
      { path: 'product_mappings', element: <ProductMappingsView /> },
      { path: 'ticket_claims', element: <TicketClaimsView /> },
      { path: 'account_groups', element: <AccountGroupsView /> }
    ]
  }
];

export default routes;
