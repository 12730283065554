import React from 'react';
import { Box, Button, ButtonGroup, Typography } from '@material-ui/core';
import { ListAlt } from '@material-ui/icons';

export const TableTypeSelector = (): JSX.Element => {
  return (
    <Box>
      <Box>
        <Typography variant="h6" color="textSecondary">
          Table Type
        </Typography>
      </Box>
      <Box mt={1}>
        <ButtonGroup fullWidth disableElevation>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<ListAlt />}
          >
            Assignments
          </Button>
          <Button variant="outlined" color="primary" size="small" disabled>
            Inventories
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};
