import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dialog,
  DialogActions,
  Button,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  Box,
  Select,
  CircularProgress
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { updateAssignments, sendMassMessage } from 'src/redux/actions';

export default function SendMassSms({ selectedEvent, selectedPlan }) {
  const dispatch = useDispatch();
  const { activeTeam } = useSelector(state => state);
  const { enqueueSnackbar: showAlert } = useSnackbar();
  const [smsDialogOpen, setSmsDialogOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [smsContent, setSmsContent] = useState('');

  const [loading, setLoading] = useState(false);

  const handleSmsDialog = type => {
    if (type === 'open') setSmsDialogOpen(true);
    if (type === 'close') setSmsDialogOpen(false);
  };

  const handleConfirmDialog = type => {
    if (type === 'open') setConfirmOpen(true);
    if (type === 'close') setConfirmOpen(false);
  };

  const handleChange = event => {
    setSmsContent(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    handleSmsDialog('close');
    handleConfirmDialog('open');
  };

  const handleSendMassSms = async () => {
    setLoading(true);

    try {
      let response = await sendMassMessage(
        activeTeam.team_id,
        smsContent,
        selectedPlan.planCode,
        selectedEvent.eventCode
      );

      if (response.status === 200) {
        await updateAssignments(activeTeam.team_id, selectedEvent.eventCode);
        showAlert('Sending mass sms successful', { variant: 'success' });
        handleConfirmDialog('close');
      }
    } catch (error) {
      showAlert('Something went wrong, please try again', { variant: 'error' });
    }
    setLoading(false);
  };

  return (
    <>
      <Button
        size="small"
        variant="contained"
        disableElevation
        color="primary"
        onClick={() => handleSmsDialog('open')}
      >
        Send mass sms
      </Button>

      <Dialog
        onClose={() => handleSmsDialog('close')}
        open={smsDialogOpen}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <form onSubmit={handleSubmit}>
          <Box p={2} pb={1}>
            <Box mb={2}>
              <Typography variant="h5" color="textPrimary">
                SEND MASS SMS
              </Typography>
            </Box>

            <Box mb={2}>
              <FormControl variant="outlined" required size="small" fullWidth>
                <InputLabel htmlFor="select-audience">
                  Select audience
                </InputLabel>
                <Select native={true} id="select-audience">
                  <option>All fans</option>
                  <option>Opted in</option>
                  <option>Opted in only</option>
                </Select>
              </FormControl>
            </Box>

            <Box>
              <FormControl
                variant="outlined"
                required
                size="small"
                style={{ width: 300 }}
              >
                <InputLabel htmlFor="mass-sms-text">
                  Enter your sms content
                </InputLabel>
                <OutlinedInput
                  multiline={true}
                  rows={4}
                  value={smsContent}
                  onChange={handleChange}
                />
              </FormControl>
            </Box>
          </Box>

          <DialogActions>
            <Button onClick={() => handleSmsDialog('close')} color="primary">
              cancel
            </Button>
            <Button type="submit" color="primary" autoFocus>
              Send
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        open={confirmOpen}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
      >
        <Box p={2}>
          <Box mb={3}>
            <Typography variant="h5" color="textPrimary">
              Are you sure want to send this message ?
            </Typography>
          </Box>

          <Box>
            <Typography variant="subtitle2" color="textSecondary">
              {smsContent}
            </Typography>
          </Box>
        </Box>

        <DialogActions>
          <Button color="primary" onClick={() => handleConfirmDialog('close')}>
            No
          </Button>

          <Button
            disabled={loading}
            color="primary"
            onClick={handleSendMassSms}
          >
            Yes
            {loading && (
              <CircularProgress
                color="primary"
                size={12}
                style={{ marginLeft: 5 }}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
