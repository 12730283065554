import React, {
  useState,
  useEffect,
  ReactElement,
  ReactEventHandler
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  IconButton,
  Dialog,
  makeStyles,
  Box,
  Grid,
  useTheme
} from '@material-ui/core';
import {
  DeleteOutline,
  EditOutlined,
  AccountTreeOutlined
} from '@material-ui/icons';
import { usePopup } from 'src/contexts/PopupContext';
import { getAllProductMapping, deleteProductMapping } from 'src/redux/actions';
import { RootState } from 'src/redux/model';
import { ProductMappingForm } from 'src/components/ProductMappingForm';

export default function MappingActions({ product }): ReactElement {
  const styles = useStyles();
  const theme = useTheme();
  const popup = usePopup();
  const dispatch = useDispatch();
  const { activeTeam } = useSelector((state: RootState) => state);

  const handleDelete = async () => {
    const { team_id: teamId } = activeTeam;
    const productMappingId = product.id;
    popup.loading(true);
    await deleteProductMapping({ teamId, productMappingId });
    await dispatch(getAllProductMapping(teamId));
    popup.loading(false);
    popup.close();
  };

  const confirmDelete: ReactEventHandler = () => {
    popup.fireDelete();
    popup.onConfirm(handleDelete);
  };

  const UpdateForm = () => (
    <ProductMappingForm
      title="UPDATE PRODUCT MAPPING"
      isUpdate={true}
      {...{ product }}
    />
  );

  const triggerUpdate: ReactEventHandler = () => {
    popup.fire({
      component: UpdateForm,
      maxWidth: 'lg',
      hideButtons: true,
      heading: 'UPDATE MAPPING',
      icon: <AccountTreeOutlined className={styles.updateIcon} />,
      contentPadding: 0,
      enableCloseIcon: true
    });
  };

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs="auto">
          <IconButton
            size="small"
            onClick={triggerUpdate}
            className={styles.updateIcon}
          >
            <EditOutlined />
          </IconButton>
        </Grid>

        <Grid item xs="auto">
          <IconButton
            size="small"
            onClick={confirmDelete}
            className={styles.deleteIcon}
          >
            <DeleteOutline />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  updateIcon: {
    color: theme.palette.info.main
  },
  deleteIcon: {
    color: theme.palette.warning.main
  }
}));
