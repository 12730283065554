import React from 'react';
import {
  ButtonGroup,
  Button,
  makeStyles,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import {
  KeyboardArrowRightRounded,
  SaveAltOutlined,
  CloseOutlined,
  MenuRounded
} from '@material-ui/icons';
import { useSidebar } from 'src/contexts/SidebarContext';

export const SidebarToggler = (): JSX.Element => {
  const styles = useStyles();
  const { isPinned, pinSidebar, toggleSidebar, isOpen } = useSidebar();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ButtonGroup
      size="small"
      disableElevation
      className={styles.toggleButtonGroup}
    >
      {/* {isOpen && !sm && (
        <Button
          variant={isPinned ? 'contained' : 'outlined'}
          color={isPinned ? 'primary' : 'default'}
          onClick={() => pinSidebar(!isPinned)}
        >
          <SaveAltOutlined />
        </Button>
      )} */}

      <Button
        variant="outlined"
        color={isOpen ? 'primary' : 'default'}
        onClick={() =>
          toggleSidebar(isOpen ? (sm ? 'close' : 'shrink') : 'open')
        }
      >
        {isOpen ? (
          <CloseOutlined />
        ) : (
          <>
            <MenuRounded style={{ marginLeft: 3 }} />
            <KeyboardArrowRightRounded
              style={{ marginLeft: -6, fontSize: 14 }}
            />
          </>
        )}
      </Button>
    </ButtonGroup>
  );
};

const useStyles = makeStyles(theme => ({
  toggleButtonGroup: {
    '& .MuiButtonBase-root': {
      boxShadow: `0 8px 16px rgb(0 0 0 / 10%)`,
      minWidth: 40,
      width: 40,
      height: 28,
      padding: 0,

      '& svg': {
        fontSize: 20
      }
    },
    '& .MuiButtonBase-root:first-child': {
      borderTopLeftRadius: 15,
      borderBottomLeftRadius: 15
    },
    '& .MuiButtonBase-root:last-child': {
      borderTopRightRadius: 15,
      borderBottomRightRadius: 15
    }
  }
}));
