import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Paper,
  Box,
  Button,
  Typography,
  Divider,
  List,
  ListItem,
  makeStyles,
  Icon,
  Chip,
  colors,
  fade,
  ListItemText,
  Dialog,
  InputLabel,
  OutlinedInput,
  FormControl,
  FormControlLabel,
  Checkbox,
  DialogActions,
  CircularProgress
} from '@material-ui/core';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import {
  getSubscriptionPlans,
  updateSelectedPlan,
  newSubscriptionPlan,
  getEvents
} from 'src/redux/actions';

export default function Sidebar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar: showAlert } = useSnackbar();
  const { activeTeam, subscriptionPlans, selectedPlan } = useSelector(
    state => state
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [planName, setPlanName] = useState('');
  const [planCode, setPlanCode] = useState('');
  const [planCost, setPlanCost] = useState('');
  const [planDetails, setPlanDetails] = useState('');
  const [isAvailable, setIsAvailable] = useState(false);
  const [isRecurring, setIsRecurring] = useState(false);
  const [plans, setPlans] = useState(null);

  useEffect(() => {
    if (activeTeam) {
      getSubscriptionPlans(activeTeam.team_id);
    }
  }, [activeTeam]);

  useEffect(() => {
    if (subscriptionPlans) {
      let sortedPlans = subscriptionPlans.sort((a, b) =>
        a.isAvailableForSale ? -1 : 1
      );
      setPlans(sortedPlans);
      dispatch(updateSelectedPlan(subscriptionPlans[0]));
    }
  }, [subscriptionPlans]);

  const resetAllInput = () => {
    setPlanName('');
    setPlanCost('');
    setPlanCode('');
    setPlanDetails('');
    setIsAvailable(false);
    setIsRecurring(false);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleCheckBox = (event, type) => {
    if (type === 'available') setIsAvailable(!isAvailable);
    if (type === 'recurring') setIsRecurring(!isRecurring);
  };

  const handleChange = (event, type) => {
    let value = event.target.value;
    if (type === 'name') setPlanName(value);
    if (type === 'code') setPlanCode(value);
    if (type === 'cost') setPlanCost(value);
    if (type === 'details') setPlanDetails(value);
  };

  const handleUpdateSelectedPlan = (event, plan) => {
    dispatch(updateSelectedPlan(plan));
  };

  const handleNewPlan = async event => {
    event.preventDefault();
    setLoading(true);
    let result = await newSubscriptionPlan(activeTeam.team_id, {
      planName,
      planCode,
      monthlyCost: planCost,
      planDescription: planDetails,
      isAvailableForSale: isAvailable
    });

    if (result.status === 200) {
      let plans = result.data.subscriptionPlans;
      await getSubscriptionPlans(activeTeam.team_id);
      await getEvents(activeTeam.team_id, selectedPlan.id);
      await dispatch(updateSelectedPlan(plans[plans.length - 1]));
      setDialogOpen(false);
      resetAllInput();
      showAlert('Plan created successfully', { variant: 'success' });
    } else {
      showAlert('Something went wrong, please try again', { variant: 'error' });
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <Paper variant="outlined" className={classes.paper}>
        <Box
          p={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h4">All Plans</Typography>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            onClick={handleDialogOpen}
          >
            New
          </Button>
        </Box>
        <Divider />
        <Box p={1} pt={0} pb={0}>
          <List>
            {plans &&
              plans.map(plan => {
                return (
                  <ListItem
                    className={clsx(classes.item)}
                    disableGutters
                    key={plan.id}
                  >
                    <ListItem
                      className={classes.button}
                      button
                      onClick={event => handleUpdateSelectedPlan(event, plan)}
                      selected={selectedPlan && plan.id === selectedPlan.id}
                    >
                      {/* activePlan && plan.id === activePlan.id */}
                      <ListItemText primary={plan.planName} />
                      {/* <span className={classes.title}>{plan.planName}</span> */}

                      {plan.isAvailableForSale ? (
                        <span>
                          <Chip
                            label="Active"
                            size="small"
                            className={classes.chip}
                          />
                        </span>
                      ) : (
                        ''
                      )}
                    </ListItem>
                  </ListItem>
                );
              })}
          </List>
        </Box>
      </Paper>

      <Dialog open={dialogOpen}>
        <form onSubmit={handleNewPlan}>
          <Box p={3}>
            <Box mb={2}>
              <Typography variant="h5">Create new plan</Typography>
            </Box>
            <Box>
              <Box mb={2}>
                <FormControl variant="outlined" fullWidth size="small" required>
                  <InputLabel htmlFor="new-plan-name">Plan name</InputLabel>
                  <OutlinedInput
                    id="new-plan-name"
                    type="text"
                    value={planName}
                    onChange={event => handleChange(event, 'name')}
                  />
                </FormControl>
              </Box>

              <Box mb={2}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  size="small"
                  className={classes.newPlanInput}
                  required
                >
                  <InputLabel htmlFor="new-plan-code">Plan code</InputLabel>
                  <OutlinedInput
                    id="new-plan-code"
                    type="text"
                    value={planCode}
                    onChange={event => handleChange(event, 'code')}
                  />
                </FormControl>
              </Box>

              <Box mb={2}>
                <FormControl variant="outlined" fullWidth size="small" required>
                  <InputLabel htmlFor="new-plan-cost">Plan cost</InputLabel>
                  <OutlinedInput
                    id="new-plan-cost"
                    type="number"
                    value={planCost}
                    onChange={event => handleChange(event, 'cost')}
                  />
                </FormControl>
              </Box>

              <Box mb={1}>
                <FormControl variant="outlined" fullWidth size="small" required>
                  <InputLabel htmlFor="new-plan-details">
                    Plan Description
                  </InputLabel>
                  <OutlinedInput
                    id="new-plan-details"
                    type="text"
                    multiline
                    rows={4}
                    value={planDetails}
                    onChange={event => handleChange(event, 'details')}
                  />
                </FormControl>
              </Box>
              <Box ml={1}>
                <FormControlLabel
                  className={classes.checkBoxLabel}
                  control={
                    <Checkbox
                      color="primary"
                      onChange={event => handleCheckBox(event, 'available')}
                      checked={isAvailable}
                      size="small"
                    />
                  }
                  label="Available for purchase ?"
                />
              </Box>

              <Box ml={1}>
                <FormControlLabel
                  className={classes.checkBoxLabel}
                  control={
                    <Checkbox
                      color="primary"
                      onChange={event => handleCheckBox(event, 'recurring')}
                      checked={isRecurring}
                      size="small"
                    />
                  }
                  label="Recurring subscription ?"
                />
              </Box>
            </Box>
          </Box>
          <DialogActions>
            <Button autoFocus onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              Save
              {loading && (
                <CircularProgress
                  size={12}
                  style={{ marginLeft: 4, color: '#bdbdbd', marginTop: -2 }}
                />
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '5px 9px',
    textTransform: 'none',
    width: '100%',
    borderRadius: 3
  },

  title: {
    marginRight: 'auto',
    fontSize: 16
  },
  paper: {
    height: '100%'
  },
  newPlanInput: {
    minWidth: 250
  },
  checkBoxLabel: {
    color: colors.grey[600],

    '& .MuiFormControlLabel-label': {
      fontSize: 14
    },

    '& .MuiIconButton-root': {
      padding: 4
    }
  }
}));
