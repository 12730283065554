import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

const PageHeader: React.FC<{
  title: string;
  PageActions?: React.ElementType;
}> = ({ title, PageActions }) => (
  <Grid container>
    <Grid item xs={12}>
      <Box>
        <Typography variant="h3" color="textPrimary">
          {title}
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={12}>
      {PageActions && <PageActions />}
    </Grid>
  </Grid>
);

export default PageHeader;
