import React from 'react';
import { Box, colors, makeStyles, Typography } from '@material-ui/core';
import LogoImage from 'src/img/logo.png';

interface PropTypes {
  hideText?: boolean;
}

export const TextLogo = ({ hideText }: PropTypes): JSX.Element => {
  const styles = useStyles();

  return (
    <Box display="flex" alignItems="center" flexWrap="nowrap">
      <Box>
        <img src={LogoImage} className={styles.logoImage} />
      </Box>
      {!hideText && (
        <Box ml={1}>
          <Typography
            noWrap
            color="textSecondary"
            className={styles.name}
            variant="h4"
          >
            SEASON SHARE
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  logoImage: {
    width: 28,
    height: 28
  },

  name: {
    fontWeight: 700,
    fontSize: 18
  }
}));
