import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Paper,
  Typography,
  Divider
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import SortMenu from './SortMenu';
import { getAllClaims } from 'src/redux/actions';
import Empty from 'src/components/Empty';

export default function ClaimTable() {
  const { activeTeam, ticketClaims } = useSelector(state => state);
  const [claims, setClaims] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeTeam) {
      dispatch(getAllClaims(activeTeam.team_id));
    }
  }, [activeTeam]);

  useEffect(() => {
    updateClaims(ticketClaims);
  }, [ticketClaims]);

  const updateClaims = claims => {
    setClaims([...claims]);
  };

  return (
    <Paper>
      <Box
        p={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4" color="textPrimary">
          Ticket Claims
        </Typography>
        {claims.length > 0 && <SortMenu {...{ updateClaims, claims }} />}
      </Box>
      <Divider />

      {claims.length > 0 ? (
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Full Name</TableCell>
                <TableCell>Account Number</TableCell>
                <TableCell>Seat Count</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Email Address</TableCell>
                <TableCell>Event Code</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Updated At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {claims.map(claim => (
                <TableRow key={uuid()}>
                  {/* Full name */}
                  <TableCell>
                    <Typography variant="subtitle2" color="textSecondary">
                      {claim.fullName}
                    </Typography>
                  </TableCell>

                  {/* Account Number */}
                  <TableCell>
                    <Typography variant="subtitle2" color="textSecondary">
                      {claim.accountNumber}
                    </Typography>
                  </TableCell>

                  {/* Seat Count */}
                  <TableCell>
                    <Typography variant="subtitle2" color="textSecondary">
                      {claim.seatCount}
                    </Typography>
                  </TableCell>

                  {/* Phone Number */}
                  <TableCell>
                    <Typography variant="subtitle2" color="textSecondary">
                      {claim.phoneNumber}
                    </Typography>
                  </TableCell>

                  {/* Email Address */}
                  <TableCell>
                    <Typography variant="subtitle2" color="textSecondary">
                      {claim.emailAddress}
                    </Typography>
                  </TableCell>

                  {/* Event Code */}
                  <TableCell>
                    <Typography variant="subtitle2" color="textSecondary">
                      {claim.eventCode}
                    </Typography>
                  </TableCell>

                  {/* Created At */}
                  <TableCell>
                    <Typography variant="subtitle2" color="textSecondary">
                      {claim.createdAt
                        ? moment(claim.createdAt).format('MMM Do YYYY')
                        : 'unavailable'}
                    </Typography>
                  </TableCell>

                  {/* updated At */}
                  <TableCell>
                    <Typography variant="subtitle2" color="textSecondary">
                      {claim.updatedAt
                        ? moment(claim.updatedAt).format('MMM Do YYYY')
                        : 'unavailable'}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      ) : (
        <Empty caption="No ticket claim found" />
      )}
    </Paper>
  );
}
