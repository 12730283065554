import React from 'react';
import {
  makeStyles,
  Paper,
  Box,
  Button,
  Typography,
  Divider,
  useTheme,
  colors
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  btnWrapper: {
    width: '100%',
    minHeight: 250,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  button: {
    padding: '0px 0px',
    visibility: 'hidden'
  }
}));

function Actions() {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Paper variant="outlined" className={classes.root}>
      <Box
        p={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5" color="textPrimary">
          Actions
        </Typography>

        {/* Invisible button to fix style */}
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          size="small"
        >
          save
        </Button>
      </Box>
      <Divider />
      <Box className={classes.btnWrapper}>
        <Box mb={2}>
          <Button
            color="primary"
            style={{ minWidth: 200 }}
            variant="contained"
            size="large"
          >
            Send sms
          </Button>
        </Box>
        <Box>
          <Button
            style={{
              backgroundColor: colors.pink['600'],
              color: 'white',
              minWidth: 200
            }}
            variant="contained"
            size="large"
          >
            Deactive
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}
export default Actions;
