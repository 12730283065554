import React from 'react';
import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles
} from '@material-ui/core';
import MatSwitch from './MatSwitch';

export default function Switches({ formik, plan }) {
  let { values, setFieldValue } = formik;

  const toggleBooleanValues = event => {
    event.persist();
    let name = event.target.name;
    let boolean = event.target.checked;
    setFieldValue(name, boolean ? 1 : 0);
  };

  return (
    <>
      <Grid item xs={6}>
        <MatSwitch
          name="isRecurring"
          title="Recurring Subscription ?"
          label={values.isRecurring ? 'Yes' : 'No'}
          checked={values.isRecurring}
          onChange={formik.handleChange}
        />
      </Grid>

      <Grid item xs={6}>
        <MatSwitch
          name="isAvailableForSale"
          title="Available for purchase ?"
          label={values.isAvailableForSale ? 'Available' : 'Not Available'}
          checked={values.isAvailableForSale ? true : false}
          onChange={toggleBooleanValues}
        />
      </Grid>

      <Grid item xs={6}>
        <MatSwitch
          name="isExclusive"
          title="Is exculsive ?"
          label={values.isExclusive ? 'Exclusive' : 'Not Exclusive'}
          checked={values.isExclusive ? true : false}
          onChange={toggleBooleanValues}
        />
      </Grid>
      <Grid item xs={6}>
        <MatSwitch
          name="portalVisibility"
          title="Visible on portal ?"
          label={values.portalVisibility ? 'Visible' : 'Not Visible'}
          checked={values.portalVisibility ? true : false}
          onChange={toggleBooleanValues}
        />
      </Grid>
    </>
  );
}
