import React, { useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Avatar,
  makeStyles,
  colors,
  Button,
  Divider,
  Checkbox
} from '@material-ui/core';
import {
  AutorenewRounded,
  CheckBox,
  VerticalSplitOutlined
} from '@material-ui/icons';
import { MatStyleType, SubscriptionPlanType, SelectorFn } from 'src/types';
import clsx from 'clsx';
import { PlanActions } from './PlanAction';

interface PropTypes {
  plan: SubscriptionPlanType;
  selectedPlan: SubscriptionPlanType;
  handleSelectPlan: SelectorFn<SubscriptionPlanType>;
}

const SubscriptionPlanCard = ({
  plan,
  selectedPlan,
  handleSelectPlan
}: PropTypes) => {
  const styles: MatStyleType = useStyles();
  const isSelected = plan?.id === selectedPlan?.id;

  /** Calculate whether the plan is recurring or not, if both of them are null then status is not defined */
  const isRecurring: { (arg: SubscriptionPlanType): string } = plan => {
    const monthly = plan.monthlyCost;
    const oneTime = plan.oneTimeCost;

    if (monthly > 0) return 'yes';
    if (oneTime > 0) return 'no';
    return 'not_defined';
  };

  return (
    <Paper
      className={clsx(styles.planPaper, isSelected ? styles.selected : '')}
      onClick={() => handleSelectPlan(plan)}
    >
      <Box p={1} display="flex" alignItems="center">
        <Box>
          <Checkbox
            color="primary"
            checked={isSelected}
            className={styles.checkbox}
          />
          {/* <Avatar className={styles.avatar} variant="rounded">
            <VerticalSplitOutlined color="primary" className={styles.icon} />
          </Avatar> */}
        </Box>
        <Box ml={1} className={styles.planNameWrap}>
          <Typography variant="h6" noWrap={true}>
            {plan.planName}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box p={1} className={styles.planContent}>
        <Box style={{ marginTop: 4 }}>
          <Typography
            variant="body2"
            color="textSecondary"
            className={styles.planMeta}
          >
            Plan Code : <span className={styles.planCode}>{plan.planCode}</span>
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="body2"
            color="textSecondary"
            className={styles.planMeta}
          >
            Recurring :{' '}
            <span className={styles['recurring_' + isRecurring(plan)]}>
              {isRecurring(plan).toUpperCase()}
            </span>
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box p={1}>
        <PlanActions {...{ plan, isSelected }} />
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  planPaper: {
    transition: 'all 0.3s ease',
    height: '100%',
    borderRadius: 8,
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: '0 8px 16px rgb(0 0 0 / 10%)',
    cursor: 'pointer',
    '&:hover': {
      transform: 'translateY(-5px)'
    }
  },

  planNameWrap: {
    width: '100%',
    overflow: 'hidden'
  },

  selected: {
    border: `2px solid ${theme.palette.primary.main}`,
    transform: 'translateY(-5px)',
    boxShadow: '0 4px 8px rgb(0 0 0 / 10%)'
  },

  checkbox: {
    padding: 0
  },

  icon: {
    fontSize: 18
  },
  avatar: {
    backgroundColor: 'transparent',
    width: 24,
    height: 24,
    border: `1px solid ${theme.palette.divider}`
  },
  planContent: {
    // display: 'grid',
    // gridTemplateColumns: '40px 1fr',
    // alignItems: 'flex-start'
  },

  planMeta: {
    color: colors.grey[500]
  },

  planCode: {
    color: theme.palette.primary.main
  },
  recurring_yes: {
    color: theme.palette.success.main
  },

  recurring_no: {
    color: theme.palette.warning.main
  },
  recurring_not_defined: {
    color: theme.palette.error.main
  }
}));

export default SubscriptionPlanCard;
