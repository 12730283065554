import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TableRow,
  TableCell,
  Typography,
  Grid,
  Button,
  DialogActions,
  Dialog,
  Chip,
  IconButton,
  Box,
  OutlinedInput,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
  TextField,
  makeStyles,
  fade,
  Divider,
  Paper,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@material-ui/core';
import moment from 'moment';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { editEvent, getEvents } from 'src/redux/actions';
import { useSnackbar } from 'notistack';
import ImageLogo from 'src/img/image-icon.png';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import { v4 as uuid } from 'uuid';
import { OptInOption } from './EventOptions/OptInOption';

function EventTableRow({ data }) {
  const classes = useStyles();
  const [editEventDialogOpen, setEditEventDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar: showAlert } = useSnackbar();
  const { activeTeam, selectedPlan, sportTeams } = useSelector(state => state);
  const [eventCode, setEventCode] = useState(data.eventCode);
  const [isCurrentEvent, setCurrentEvent] = useState(data.isCurrentEvent);
  const [description, setDescription] = useState(data.description);
  const [date, setEventDate] = useState(data.date);
  const [time, setEventTime] = useState(data.time);
  const [loading, setLoading] = useState(false);
  // const [OptInWindowStartTime, setOptInWindowStartTime] = useState(
  //   data.OptInWindowStartTime
  // );
  // const [OptInWindowEndTime, setOptInWindowEndTime] = useState(
  //   data.OptInWindowEndTime
  // );

  const [logoUrl, setLogoUrl] = useState(data.logoUrl);

  const triggerUpload = event => {
    event.stopPropagation();
    let uploader = document.getElementById('image-upload');
    uploader.click();
  };

  const handleChange = (event, type) => {
    let value = event.target.value;
    if (type === 'event-code') setEventCode(value);
    if (type === 'current-event') setCurrentEvent(value);
    if (type === 'description') setDescription(value);
    if (type === 'event-date') setEventDate(value);
    if (type === 'event-time') setEventTime(value);
    // if (type === 'sms-start-date-time') setOptInWindowStartTime(value);
    // if (type === 'sms-end-date-time') setOptInWindowEndTime(value);
    if (type === 'logo-url') setLogoUrl(value);
  };

  const handleEdit = async event => {
    event.preventDefault();
    setLoading(true);
    try {
      let response = await editEvent(activeTeam.team_id, {
        eventCode,
        isCurrentEvent,
        description,
        date,
        time,
        eventId: data.id,
        // OptInWindowStartTime,
        // OptInWindowEndTime,
        logoUrl,
        SubscriptionPlanId: data.SubscriptionPlanId
      });

      if (response.status === 200) {
        await getEvents(activeTeam.team_id, selectedPlan.id);
        showAlert('Event update successful', { variant: 'success' });
        setEditEventDialogOpen(false);
      }
    } catch (error) {
      showAlert(
        error?.response?.data?.message ||
          error?.response?.data ||
          'Something went wrong, please try again',
        { variant: 'error' }
      );
    }
    setLoading(false);
  };

  const resetFields = () => {
    setEventCode(data.eventCode);
    setCurrentEvent(data.isCurrentEvent);
    setDescription(data.description);
    setEventDate(data.date);
    setEventTime(data.time);
    // setOptInWindowStartTime(data.OptInWindowStartTime);
    // setOptInWindowEndTime(data.OptInWindowEndTime);
    setLogoUrl(data.logoUrl);
  };

  const handleEditEventDialogClose = () => {
    setEditEventDialogOpen(false);
  };

  const handleEditEventDialogOpen = () => {
    setEditEventDialogOpen(true);
  };

  return (
    <React.Fragment>
      <TableRow hover key={data.id}>
        <TableCell>
          <Typography color="textSecondary" variant="subtitle2">
            {data.eventCode ? data.eventCode : 'unavailable'}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography color="textSecondary" variant="subtitle2">
            {data.description ? data.description : 'unavailable'}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography color="textSecondary" variant="subtitle2">
            {data.date
              ? moment(data.date).format('MMM Do YYYY')
              : 'unavailable'}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography color="textSecondary" variant="subtitle2">
            {data.time ? data.time : 'unavailable'}
          </Typography>
        </TableCell>

        <TableCell>
          {data.isCurrentEvent === 1 ? (
            <Chip label="YES" size="small" />
          ) : data.isCurrentEvent === 0 ? (
            <Chip label="NO" size="small" color="primary" />
          ) : (
            <Typography color="textSecondary" variant="subtitle2">
              unavailable
            </Typography>
          )}
        </TableCell>

        <TableCell>
          <OptInOption event={data} />
        </TableCell>

        <TableCell align="center">
          {/* {!data.isCurrentEvent && (
            <IconButton size="small">
              <DeleteOutlineIcon fontSize="small" />
            </IconButton>
          )} */}

          <IconButton
            size="small"
            onClick={() => {
              resetFields();
              handleEditEventDialogOpen();
            }}
          >
            <EditOutlinedIcon fontSize="small" />
          </IconButton>

          {/* {data && sportTeams.length > 0 && (
            <EditEvent event={data} {...{ sportTeams }} />
          )} */}
        </TableCell>
      </TableRow>

      <Dialog
        open={editEventDialogOpen}
        onClose={() => handleEditEventDialogClose()}
      >
        <form onSubmit={handleEdit}>
          <Box p={2}>
            <Box mb={3}>
              <Typography color="textPrimary" variant="h4">
                Edit Events
              </Typography>
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper variant="outlined" className={classes.paper}>
                  <Box width="100%">
                    <Box pt={1} pb={1} p={3}>
                      <Typography variant="h6">LOGO</Typography>
                    </Box>
                    <Divider />

                    <Box p={2}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        className={classes.uploader}
                        p={4}
                      >
                        <Box mb={1}>
                          {logoUrl ? (
                            <img
                              src={logoUrl}
                              className={classes.logo}
                              alt="logo"
                            />
                          ) : (
                            <img
                              src={ImageLogo}
                              alt="upload image"
                              className={classes.image}
                            />
                          )}
                        </Box>

                        <Box width="100%">
                          <FormControl
                            variant="outlined"
                            fullWidth
                            size="small"
                          >
                            <InputLabel htmlFor="select-logo">
                              Select or Change logo
                            </InputLabel>
                            <Select
                              id="select-logo"
                              value={logoUrl}
                              className={classes.selectLogo}
                              onChange={event =>
                                handleChange(event, 'logo-url')
                              }
                            >
                              {sportTeams.length > 0 &&
                                sportTeams.map(team => (
                                  <MenuItem
                                    key={uuid()}
                                    value={team.team_thumb_img}
                                  >
                                    <ListItemIcon>
                                      <img
                                        className={classes.suggestionImage}
                                        src={team.team_thumb_img}
                                        alt=""
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={team.team_name_full}
                                    />
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box mb={2}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                  >
                    <InputLabel htmlFor="edit-event-code">
                      Event code
                    </InputLabel>
                    <OutlinedInput
                      id="edit-event-code"
                      value={eventCode}
                      onChange={event => handleChange(event, 'event-code')}
                    />
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Box mb={2}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                  >
                    <InputLabel htmlFor="edit-current-event">
                      Current Event
                    </InputLabel>
                    <Select
                      id="edit-current-event"
                      value={isCurrentEvent}
                      onChange={event => handleChange(event, 'current-event')}
                    >
                      <MenuItem value={1}>Yes</MenuItem>
                      <MenuItem value={0}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} lg={6}>
                <FormControl size="small" variant="outlined" fullWidth required>
                  <InputLabel htmlFor="event-date">Event Date</InputLabel>
                  <OutlinedInput
                    id="event-date"
                    type="date"
                    defaultValue={moment(date).format('YYYY-MM-DD')}
                    onChange={event => handleChange(event, 'event-date')}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} lg={6}>
                <FormControl size="small" variant="outlined" fullWidth required>
                  <InputLabel htmlFor="event-time">Event Time</InputLabel>
                  <OutlinedInput
                    id="event-time"
                    type="time"
                    defaultValue={time}
                    onChange={event => handleChange(event, 'event-time')}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Box mb={0}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                  >
                    <InputLabel htmlFor="edit-event-description">
                      Event Description
                    </InputLabel>
                    <OutlinedInput
                      id="edit-event-description"
                      value={description}
                      multiline={true}
                      rows={3}
                      onChange={event => handleChange(event, 'description')}
                    />
                  </FormControl>
                </Box>
              </Grid>

              {/* <Grid item xs={12}>
                <Box>
                  <Typography variant="h6">First sms settings</Typography>
                </Box>
              </Grid> */}

              {/* <Grid item xs={12} lg={6}>
                <Box>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel shrink={true} htmlFor="sms-start-date-time">
                      Start date & time
                    </InputLabel>
                    <OutlinedInput
                      id="sms-start-date-time"
                      type="datetime-local"
                      value={OptInWindowStartTime}
                      onChange={event =>
                        handleChange(event, 'sms-start-date-time')
                      }
                    />
                  </FormControl>
                </Box>
              </Grid> */}

              {/* <Grid item xs={12} lg={6}>
                <Box>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel shrink={true} htmlFor="sms-end-date-time">
                      End date & time
                    </InputLabel>
                    <OutlinedInput
                      id="sms-end-date-time"
                      type="datetime-local"
                      value={OptInWindowEndTime}
                      onChange={event =>
                        handleChange(event, 'sms-end-date-time')
                      }
                    />
                  </FormControl>
                </Box>
              </Grid> */}
            </Grid>
          </Box>
          <DialogActions>
            <Button onClick={handleEditEventDialogClose} color="primary">
              Cancel
            </Button>
            <Button color="primary" type="submit">
              Save
              {loading && (
                <CircularProgress
                  color="primary"
                  size={12}
                  style={{ marginLeft: 5 }}
                />
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  image: {
    width: 80
  },
  uploader: {
    // backgroundColor: fade('#009FE8', 0.05),
    border: `3px dashed ${fade('#009FE8', 0.3)}`
  },
  paper: {
    height: '100%'
  },
  logo: {
    maxWidth: 120
  },

  selectLogo: {
    '& .MuiListItemIcon-root': {
      display: 'none'
    }
  },
  suggestionImage: {
    width: 48
  }
}));

export default EventTableRow;
