import React from 'react';
import { Box, styled, Typography, makeStyles } from '@material-ui/core';
import EmptyImage from 'src/img/empty.png';
import { AsyncStatus, MatStyleType } from 'src/types';

interface PropTypes {
  status: Record<AsyncStatus | 'error', boolean>;
  haveData: boolean;
}

const parseError = error => {
  return (
    error?.response?.data?.message ||
    error?.response?.data ||
    'Something went wrong, please try again'
  );
};

export const AsyncEmpty = ({ status, haveData }: PropTypes) => {
  const styles: MatStyleType = useStyles();
  return (
    <>
      {status.success && haveData ? (
        <></>
      ) : (
        <Box width="100%" textAlign="center" p={4}>
          <img src={EmptyImage} style={{ width: 96 }} />
          <Typography gutterBottom variant="body1" color="textSecondary">
            {(status.loading || status.idle) && (
              <span className={styles.infoText}>Loading data, please wait</span>
            )}
            {status.failed && (
              <span className={styles.errorText}>
                {parseError(status.error)}
              </span>
            )}

            {status.success && !haveData && (
              <span className={styles.warningText}>No data found.</span>
            )}
          </Typography>
        </Box>
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  errorText: {
    color: theme.palette.error.main
  },
  infoText: {
    color: theme.palette.primary.main
  },
  warningText: {
    color: theme.palette.warning.main
  }
}));
