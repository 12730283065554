import React, { useState, useEffect, EffectCallback } from 'react';
import {
  Container,
  Grid,
  Typography,
  Box,
  makeStyles
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import Page from 'src/components/Page';
import PageActions from './PageActions';
import { AssignmentTableV2 } from 'src/components/AssignmentTableV2/AssignmentTableV2';
import { AssignmentSidebar } from 'src/components/AssignmentSidebar/AssignmentSidebar';
import { EventType, ReduxState, SubscriptionPlanType } from 'src/types';
import {
  getSubscriptionPlans,
  getEvents,
  updateAllEvents
} from 'src/redux/actions';

const AssignmentView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeTeam } = useSelector((state: ReduxState) => state);
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlanType>();
  const [selectedEvent, setSelectedEvent] = useState<EventType>();

  const getPlans = () => {
    (async () => {
      if (!activeTeam) return;
      const { team_id } = activeTeam;
      await getSubscriptionPlans(team_id);
    })();
  };

  const getEventsByPlanId = () => {
    (async () => {
      if (!activeTeam || !selectedPlan) return;
      setSelectedEvent(undefined);
      const { team_id } = activeTeam;
      const planId = selectedPlan.id;
      await getEvents(team_id, planId);
    })();
  };

  const getAllEvents = () => {
    (async () => {
      if (!activeTeam) return;
      setSelectedEvent(undefined);
      const { team_id } = activeTeam;
      await updateAllEvents(team_id);
    })();
  };

  const selectPlan = (plan: SubscriptionPlanType) => setSelectedPlan(plan);
  const selectEvent = (event: EventType) => setSelectedEvent(event);

  useEffect(getAllEvents, [activeTeam]);

  return (
    <Page className={classes.root} title="Assignments">
      <Box p={4}>
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <Box>
                <Typography variant="h3" color="textPrimary">
                  Seat Assignments
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <PageActions {...{ selectedEvent, selectedPlan }} />
            </Grid>
          </Grid>
        </Box>
        <Box mt={4}>
          <Grid container>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={3}>
                <AssignmentSidebar
                  {...{ selectPlan, selectedPlan, selectedEvent, selectEvent }}
                />
              </Grid>
              <Grid item xs={12} md={8} lg={9}>
                <AssignmentTableV2 {...{ selectedEvent, selectedPlan }} />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Page>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%'
  },
  circularLoading: {
    color: '#ffffff',
    marginLeft: 10
  }
}));

export default AssignmentView;
