import { asyncState } from './asyncState';
import { uiState } from './uiState';

// Global state
export const globalState = {
  teams: null,
  activeTeam: null,
  assignments: [],
  subscribers: [],
  subscriptionPlans: [],
  selectedPlan: null,
  selectedEvent: null,
  events: [],
  teamSettings: null,
  inventorySummary: null,
  inventoryDetails: [],
  sportTeams: [],
  ticketClaims: [],
  allEvents: [],
  ticketTypes: [],
  accountGroups: [],
  productMappings: [],
  asyncState,
  uiState
};
