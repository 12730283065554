import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  colors,
  makeStyles
} from '@material-ui/core';
import { spacing } from '@material-ui/system';
import Page from 'src/components/Page';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  getInventorySummary,
  updateInventoryDetails,
  getSubscriptionPlans,
  getEvents,
  updateSubscribers,
  updateAssignments,
  updateSelectedEvent,
  updateSelectedPlan
} from 'src/redux/actions';
import ACTION_TYPES from 'src/redux/actionTypes';
import Plans from './Plans';
import EventTable from './EventTable';
import SummaryTable from './SummaryTable';
import DetailsTable from './DetailsTable';
import { IInventory, RootState } from 'src/redux/model';

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  btnWrapper: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start'
    }
  },
  btn: {
    marginRight: 15
  }
}));

const InventoryView = ({ ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar: showAlert } = useSnackbar();
  const {
    activeTeam,
    subscriptionPlans,
    events,
    inventoryDetails,
    selectedEvent,
    selectedPlan
  } = useSelector((state: RootState) => state);

  const [plans, setPlans] = useState(null);

  // If active team available then first update plans
  useEffect(() => {
    if (activeTeam) {
      getSubscriptionPlans(activeTeam.team_id);
    }
  }, [activeTeam]);

  // If plans available then update states to generate sidebar
  // and default selected plan
  useEffect(() => {
    if (subscriptionPlans) {
      let sortedPlans = subscriptionPlans.sort((a, b) => {
        if (a.isAvailableForSale) {
          return -1;
        } else {
          return 1;
        }
      });
      setPlans(sortedPlans);
      dispatch(updateSelectedPlan(subscriptionPlans[0]));
    }
  }, [subscriptionPlans]);

  // If selected plan available then update events
  // on that plan
  useEffect(() => {
    if (selectedPlan) {
      dispatch(getEvents(activeTeam.team_id, selectedPlan.id));
    }
  }, [selectedPlan]);

  // Update events state to generate event table
  useEffect(() => {
    if (events) {
      dispatch(updateSelectedEvent(events[0]));
    }
  }, [events]);

  useEffect(() => {
    if (activeTeam && selectedEvent) {
      getDetails(activeTeam.team_id, selectedEvent.eventCode);
    }
  }, [activeTeam, selectedEvent]);

  const getDetails = async (teamId, eventCode) => {
    await dispatch(updateInventoryDetails(teamId, eventCode));
  };

  const handlePlanSelection = selection => {
    dispatch(
      updateSelectedPlan(
        subscriptionPlans.find(plan => plan.id === selection.id)
      )
    );
  };

  const handleEventSelection = selection => {
    dispatch(
      updateSelectedEvent(events.find(event => event.id === selection.id))
    );
  };

  return (
    <Page className={classes.root} title="Subscribers">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Box>
              <Typography variant="h3" color="textPrimary">
                Inventory
              </Typography>
            </Box>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              {plans && (
                <Plans
                  plans={plans}
                  selectedPlan={selectedPlan}
                  handlePlanSelection={handlePlanSelection}
                />
              )}
            </Grid>

            <Grid item xs={12} lg={9}>
              {events && selectedEvent && (
                <EventTable
                  events={events}
                  selectedPlan={selectedPlan}
                  selectedEvent={selectedEvent}
                  handleEventSelection={handleEventSelection}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {/* {inventorySummary && inventorySummary.length > 0 && (
                <SummaryTable inventorySummary={inventorySummary} />
              )} */}

              {inventoryDetails && inventoryDetails.length > 0 && (
                <DetailsTable detailsData={inventoryDetails} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default InventoryView;
