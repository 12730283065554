import React, { useEffect } from 'react';
import {
  Box,
  Paper,
  makeStyles,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector, useDispatch } from 'react-redux';
import { EventType, ReduxState, SubscriptionPlanType } from 'src/types';
import { SectionHeader } from 'src/components/SectionHeader';
import { ListAlt } from '@material-ui/icons';
import { columns } from './column_renderer';
import { useAsyncState } from 'src/hooks/useAsyncState';
import { AsyncEmpty } from 'src/components/AsyncEmpty';
import { updateAssignments } from 'src/redux/actions';
import { uiStateAction } from 'src/redux/uiState';
import { CustomPagination } from './CustomPagination/CustomPagination';

interface PropTypes {
  selectedEvent: EventType;
  selectedPlan: SubscriptionPlanType;
}

export const AssignmentTableV2 = ({
  selectedEvent,
  selectedPlan
}: PropTypes) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const status = useAsyncState('assignments');
  const { activeTeam, assignments } = useSelector((state: ReduxState) => state);
  const haveAssignments = assignments.length > 0;
  const { setAssignmentCurrentPage } = uiStateAction;

  const getAssignments = () => {
    (async () => {
      if (!activeTeam || !selectedEvent) return;
      const { team_id } = activeTeam;
      const { eventCode } = selectedEvent;
      dispatch(setAssignmentCurrentPage(1));
      await updateAssignments(team_id, eventCode);
    })();
  };

  useEffect(getAssignments, [selectedEvent]);

  return (
    <Paper variant="outlined" className={styles.paper}>
      <SectionHeader
        title="Assignments"
        icon={<ListAlt />}
        secondaryElement={!md && <CustomPagination {...{ selectedEvent }} />}
      />

      {!haveAssignments && (
        <Box
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <AsyncEmpty {...{ status }} haveData={haveAssignments} />
        </Box>
      )}

      {(status.idle || status.success) && haveAssignments && (
        <>
          <Box display="flex" height="750px" width="100%">
            <Box flexGrow={1}>
              <DataGrid
                style={{ border: 'none' }}
                columns={columns}
                rows={assignments}
                hideFooterPagination
                hideFooter
              />
            </Box>
          </Box>
        </>
      )}
      {md && (
        <Box p={1}>
          <CustomPagination {...{ selectedEvent }} />
        </Box>
      )}
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    height: 800,

    [theme.breakpoints.down('md')]: {
      height: 'fit-content'
    }
  }
}));
