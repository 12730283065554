import React, { ReactElement, ReactEventHandler, useEffect } from 'react';
import { TextField, Box, makeStyles, Typography } from '@material-ui/core';

type PropTypes = {
  label: string;
  name: string;
  endAdornment?: ReactElement;
  type?: string;
  InputLabelProps?: any;
  value?: any;
  formik?: any;
  onChange?: Function;
  required?: boolean;
};

export const FormikInput = ({
  name,
  label,
  formik,
  value,
  type,
  InputLabelProps,
  onChange,
  required
}: PropTypes): JSX.Element => {
  const styles = useStyles();
  const inputValue = value ? value : formik.values[name];
  const haveValue = inputValue && true;
  const error = formik.errors[name];
  const isError = error && formik.submitCount > 0;
  const inputType = type ? type : 'text';
  const inputChangeHandler = onChange ? onChange : formik.handleChange;

  // useEffect(() => console.log(formik), [formik]);

  return (
    <Box>
      <Box>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label={label}
          name={name}
          type={inputType}
          value={haveValue ? inputValue : ''}
          onChange={inputChangeHandler}
          error={isError}
          InputLabelProps={InputLabelProps}
          required={required}
        />
      </Box>
      {isError && (
        <Box>
          <Typography className={styles.errorMessage} variant="caption">
            {error}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  errorMessage: {
    color: theme.palette.error.main,
    marginLeft: 3,
    lineHeight: 1
  }
}));
