import { endpoints } from './endpoints';
import axios from 'axios';

export const getAssignments = async (teamId: number, eventCode: string) => {
  const response = await axios.post(endpoints.assignments, {
    teamId,
    eventCode
  });
  const { assignments } = response.data;
  return assignments;
};

export const getPaginatedAssignments = async (payload: {
  teamId: number;
  eventCode: string;
  currentPage: number;
  assignmentsPerPage: number;
}) => {
  const response = await axios.post(endpoints.paginatedAssignment, {
    ...payload
  });

  return response;
};

export const assignmentsApi = { getAssignments };
