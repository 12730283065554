import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  OutlinedInput,
  FormControl,
  FormControlLabel,
  InputLabel,
  Button,
  Checkbox,
  Paper,
  makeStyles,
  colors,
  Divider,
  Switch,
  Hidden,
  InputAdornment,
  CircularProgress,
  IconButton,
  Dialog,
  DialogActions
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { useSelector, useDispatch } from 'react-redux';
import {
  updatePlan,
  getSubscriptionPlans,
  updateSelectedPlan,
  getEvents,
  deleteSubscriptionPlan
} from 'src/redux/actions';
import { useSnackbar } from 'notistack';
import PlanDetailsForm from './PlanDetailsForm';

const useStyles = makeStyles(theme => {
  return {
    input: {
      '& .MuiInputLabel-root': {
        '&.MuiInputLabel-shrink': {
          backgroundColor: theme.palette.common.white,
          paddingLeft: 5,
          paddingRight: 5
        }
      }
    },
    hiddenButton: {
      visibility: 'hidden',
      pointerEvents: 'none'
    }
  };
});

const PlanDetails = () => {
  const classes = useStyles();
  const { selectedPlan, activeTeam } = useSelector(state => state);
  const dispatch = useDispatch();
  const [planName, setPlanName] = useState('');
  const { enqueueSnackbar: showAlert } = useSnackbar();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(() => console.log(selectedPlan), [selectedPlan]);

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleUpdatePlan = async plan => {
    let result = await updatePlan(activeTeam.team_id, plan);
    if (result.status === 200) {
      let plans = result.data.subscriptionPlans;
      let teamId = activeTeam.team_id;
      let selectedPlanId = selectedPlan.id;
      await getSubscriptionPlans(teamId);
      await getEvents(teamId, selectedPlanId);
      await dispatch(
        updateSelectedPlan(plans.find(p => p.id === selectedPlanId))
      );
    }
    return Promise.resolve(result);
  };

  const handlePlanDelete = async () => {
    setDeleteLoading(true);
    let result = await deleteSubscriptionPlan(
      activeTeam.team_id,
      selectedPlan.id
    );
    if (result.status === 200) {
      let plans = result.data.subscriptionPlans;
      await getSubscriptionPlans(activeTeam.team_id);
      await dispatch(updateSelectedPlan(plans[0]));
      showAlert('Plan deleted', { variant: 'warning' });
    } else {
      showAlert('Something went wrong, please try again', { variant: 'error' });
    }
    setDeleteDialogOpen(false);
    setDeleteLoading(false);
  };

  return (
    <Grid item xs={12}>
      <Paper variant="outlined">
        <Box>
          <Box
            p={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">Plan Details</Typography>
            <IconButton size="small" onClick={handleDeleteDialogOpen}>
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </Box>
          <Divider />

          <Box p={3}>
            <PlanDetailsForm {...{ handleUpdatePlan }} />
          </Box>
        </Box>
      </Paper>

      <Dialog onClose={handleDeleteDialogClose} open={deleteDialogOpen}>
        <Box p={3} pb={3}>
          <Typography variant="h4">Delete plan</Typography>
        </Box>
        <Box p={3} pt={0} display="flex">
          <Typography variant="body1" color="textSecondary">
            Are you sure want to delete{' '}
            <span style={{ fontWeight: 'bold' }}>{planName}</span> ?
          </Typography>
        </Box>

        <DialogActions>
          <Button autoFocus onClick={handleDeleteDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handlePlanDelete}
            color="primary"
            disabled={deleteLoading}
          >
            Delete
            {deleteLoading && (
              <CircularProgress
                size={12}
                style={{ color: '#bdbdbd', marginLeft: 3, marginTop: -2 }}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
export default PlanDetails;
