import axios from 'axios';
import endpoints from './endpoints';
import ACTION_TYPES from './actionTypes';
import { toast } from 'react-toastify';
import { asyncAction } from './asyncState';
import { api } from 'src/api';
import { store } from 'src/redux/store';
import actionTypes from 'src/redux/actionTypes';
import {
  getAssignments,
  getPaginatedAssignments
} from 'src/api/assignments.api';
import { uiStateAction } from './uiState/actions';

const { loading, idle, failed, success } = asyncAction;

/** Helper fn, plain dispatch function.
 * @param type action type to perform store update
 * @param payload payload for the given action type
 */
const dispatch = (type, payload) => store.dispatch({ type, payload });

/** Helper fn to dispatch async action
 * @param action object, should pass function call of async action
 * @example asyncState(loading('subscriptionPlan'))
 */
const asyncStatus = ({ type, payload }) => store.dispatch({ type, payload });

/** Helper fn to dispatch uiState actions
 * @param action object, should pass function call of async action
 * @example uiState(setAssignmentCurrentPage(1))
 */
const uiStateDispatch = ({ type, payload }) =>
  store.dispatch({ type, payload });

/** Helper fn, show a toast message based provided error object.
 * @param error - javascript error object
 */
export function errorToast(error) {
  return toast.error(
    error?.response?.data?.message ||
      error?.response?.data ||
      'Something went wrong, please try again'
  );
}

// ================================================
// Redux actions
// ================================================
export function updateActiveTeam(teamId) {
  return dispatch =>
    dispatch({
      type: ACTION_TYPES.updateActiveTeam,
      payload: teamId
    });
}

export function updateSelectedPlan(plan) {
  return {
    type: ACTION_TYPES.updateSelectedPlan,
    payload: plan
  };
}

export function updateSelectedEvent(event) {
  return {
    type: ACTION_TYPES.updateSelectedEvent,
    payload: event
  };
}

export function updateTeams() {
  return async dispatch => {
    try {
      let teams = await axios.get(endpoints.teams).then(res => res.data.teams);
      let team999 = teams.find(team => team.team_id == 999);
      team999 && teams.unshift(team999);
      return dispatch({
        type: ACTION_TYPES.updateTeams,
        payload: teams
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export const updateAssignments = async (teamId, eventCode) => {
  const {
    setAssignmentTotalCount,
    setAssignmentTotalPageCount
  } = uiStateAction;
  const { updateAssignments } = actionTypes;
  const { uiState } = store.getState();
  const { assignmentPagination } = uiState;
  const { currentPage, dataPerPage } = assignmentPagination;

  try {
    asyncStatus(loading('assignments'));
    dispatch(updateAssignments, []); // Cleaning up state before setting new data
    const response = await getPaginatedAssignments({
      teamId,
      eventCode,
      currentPage,
      assignmentsPerPage: dataPerPage
    });
    const { paginatedAssignments, totalPages, assignmentCount } = response.data;
    dispatch(updateAssignments, paginatedAssignments);
    uiStateDispatch(setAssignmentTotalCount(assignmentCount));
    uiStateDispatch(setAssignmentTotalPageCount(totalPages));
    asyncStatus(success('assignments'));
  } catch (error) {
    asyncStatus(failed('assignments', error));
    errorToast(error);
  }
};

export const updateAllEvents = async teamId => {
  const { setAllEvents } = actionTypes;
  try {
    asyncStatus(loading('allEvents'));
    dispatch(setAllEvents, []); // cleaning up state before adding new data
    const allEvents = await api.getAllEvents(teamId);
    dispatch(setAllEvents, allEvents);
    asyncStatus(success('allEvents'));
  } catch (error) {
    asyncStatus(failed('allEvents', error));
    errorToast(error);
  }
};

// export const setPaginatedAssignments = async payload => {
//   const { setAssignmentCurrentPage } = uiStateAction;
//   try {
//     store.getState
//     uiStateDispatch(setAssignmentCurrentPage(1));
//   } catch (error) {
//     errorToast(error);
//   }
// };

export function updateSubscribers({ teamId, subscriberPlanId }) {
  return async dispatch => {
    dispatch(loading('subscribers'));
    try {
      let response = await axios.post(endpoints.subscribers, {
        teamId,
        subscriberPlanId
      });

      if (response.status === 200) {
        let { subscribers } = response.data;
        dispatch(success('subscribers'));
        return dispatch({
          type: ACTION_TYPES.updateSubscribers,
          payload: subscribers
        });
      }
    } catch (error) {
      dispatch({
        type: ACTION_TYPES.updateSubscribers,
        payload: []
      });
      dispatch(failed('subscribers'));
    }
  };
}

export async function sendFirstSms(teamId, eventCode, planCode) {
  let response = await axios.post(endpoints.sendfirstsms, {
    teamId,
    eventCode
    // planCode
  });
  return response;
}

export const getSubscriptionPlans = async teamId => {
  const { setSubscriptionPlans } = actionTypes;
  try {
    asyncStatus(loading('subscriptionPlans'));
    dispatch(setSubscriptionPlans, []); // Cleaning up before setting new data
    const subscriptionPlans = await api.getSubscriptionPlans(teamId); // getting data from api
    dispatch(setSubscriptionPlans, subscriptionPlans);
    asyncStatus(success('subscriptionPlans'));
    return subscriptionPlans;
  } catch (error) {
    asyncStatus(failed('subscriptionPlans', error));
    errorToast(error);
  }
};

export const getEvents = async (teamId, subscriptionPlanId) => {
  const { setEvents } = actionTypes;
  try {
    asyncStatus(loading('events'));
    dispatch(setEvents, []); // Cleaning up before setting new data
    const events = await api.getEventsBySelectedPlan(
      teamId,
      subscriptionPlanId
    );
    dispatch(setEvents, events);
    asyncStatus(success('events'));
  } catch (error) {
    asyncStatus(failed('events', error));
    errorToast(error);
  }
};

export function getTeamSettings(teamId) {
  return async dispatch => {
    try {
      let settings = await axios
        .get(`${endpoints.teamsettings}/${teamId}`)
        .then(res => res.data.settings[0]);
      dispatch({
        type: ACTION_TYPES.updateTeamSettings,
        payload: settings
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export async function sellSeat({ teamId, assignmentId, eventCode, planCode }) {
  let response = await axios.post(endpoints.sellseat, {
    teamId,
    assignmentId,
    eventCode
    // planCode
  });
  return response;
}

export async function newSubscriptionPlan(teamId, plan) {
  let response = axios.post(endpoints.newsubscriptionplan, { teamId, plan });
  return response;
}

export async function updateTeamSettings(newSettings) {
  try {
    let response = await axios.put(endpoints.updateTeamSettings, {
      ...newSettings
    });
    response.status === 200 && toast.success(response.data.message);
    return response;
  } catch (error) {
    console.log(error);
    errorToast(error);
  }
}

export async function updatePlan(teamId, plan) {
  try {
    let response = await axios.put(endpoints.updatePlan, { teamId, plan });
    response.status === 200 && toast.success('Plan details saved successfully');
    return Promise.resolve(response);
  } catch (error) {
    errorToast(error);
    return Promise.reject(error);
  }
}

export async function deleteSubscriptionPlan(teamId, planId) {
  let plans = await axios({
    method: 'delete',
    url: endpoints.deletesubscriptionplan,
    data: {
      teamId,
      planId
    }
  });
  return plans;
}

export async function getPreviewEvent(teamId, eventCode) {
  let preview = await axios.post(endpoints.previewevent, { teamId, eventCode });
  return preview;
}

export async function addNewEvent(teamId, eventCode, SubscriptionPlanId) {
  let events = await axios.post(endpoints.addnewevent, {
    teamId,
    SubscriptionPlanId,
    eventCode
  });
  return events;
}

export async function editEvent(teamId, event) {
  let response = await axios.post(endpoints.editevent, { teamId, ...event });
  return response;
}

export async function getInventorySummary(teamId, eventCode) {
  let response = await axios.post(endpoints.inventorysummary, {
    teamId,
    eventCode
  });

  return response;
}

export function updateInventoryDetails(teamId, eventCode) {
  return async dispatch => {
    try {
      let response = await axios.post(endpoints.inventorydetails, {
        teamId,
        eventCode
      });
      if (response.status === 200) {
        dispatch({
          type: ACTION_TYPES.updateInventoryDetails,
          payload: response.data.inventory
        });
      }
    } catch (error) {
      dispatch({
        type: ACTION_TYPES.updateInventoryDetails,
        payload: []
      });
    }
  };
}

export async function loginUser({ email, password }) {
  let response = await axios.post(endpoints.login, { email, password });
  return response;
}

export async function sendMassMessage(
  teamId,
  messageContent,
  planCode,
  eventCode
) {
  let response = await axios.post(endpoints.massmessage, {
    teamId,
    messageContent,
    planCode,
    eventCode
  });
  return response;
}

export async function generateAssignment(teamId, planCode, eventCode) {
  let response = await axios.post(endpoints.generateassignment, {
    teamId,
    planCode,
    eventCode
  });
  return response;
}

export async function markAsAttending({
  teamId,
  eventCode,
  subscriberId,
  isSubscriber,
  isAttending
}) {
  let response = await axios.post(endpoints.markasattending, {
    teamId,
    // planCode,
    eventCode,
    subscriberId,
    isSubscriber,
    isAttending
  });
  return response;
}

export async function assignSeatByName({
  podId,
  eventCode,
  teamId,
  assignmentId,
  unassignPrev
}) {
  let response = await axios.post(endpoints.assignseat, {
    podId,
    eventCode,
    // planCode,
    teamId,
    assignmentId,
    unassignPrev
  });
  return response;
}

export function getSportTeams() {
  return async dispatch => {
    let response = await axios.get(endpoints.sportteams);
    dispatch({
      type: ACTION_TYPES.updateSportTeams,
      payload: response.data.teams
    });
  };
}

export function getAllClaims(teamId) {
  return async dispatch => {
    try {
      let response = await axios.post(endpoints.getAllClaims, { teamId });

      if (response.status === 200) {
        let ticketClaims = response.data.ticketClaims;
        dispatch({
          type: ACTION_TYPES.getAllClaims,
          payload: ticketClaims
        });
      }
    } catch (error) {
      errorToast(error);
      dispatch({
        type: ACTION_TYPES.getAllClaims,
        payload: []
      });
    }
  };
}

export function getAllEvents(teamId) {
  return async dispatch => {
    try {
      let response = await axios.post(endpoints.getAllEvents, { teamId });

      if (response.status === 200) {
        let allEvents = response.data.events;
        dispatch({
          type: ACTION_TYPES.getAllEvents,
          payload: allEvents
        });
      }
    } catch (error) {
      errorToast(error);
      dispatch({
        type: ACTION_TYPES.getAllEvents,
        payload: []
      });
    }
  };
}

export function getAllTicketTypes(teamId) {
  return async dispatch => {
    try {
      let response = await axios.post(endpoints.getAllTicketTypes, { teamId });

      if (response.status === 200) {
        let ticketTypes = response.data.ticketTypes;
        dispatch({
          type: ACTION_TYPES.getAllTicketTypes,
          payload: ticketTypes
        });
      }
    } catch (error) {
      errorToast(error);
      dispatch({
        type: ACTION_TYPES.getAllTicketTypes,
        payload: []
      });
    }
  };
}

export function getAllAccountGroups(teamId) {
  return async dispatch => {
    try {
      let response = await axios.post(endpoints.getAllAccountGroups, {
        teamId
      });

      if (response.status === 200) {
        let accountGroups = response.data.accountGroups;
        dispatch({
          type: ACTION_TYPES.getAllAccountGroups,
          payload: accountGroups
        });
      }
    } catch (error) {
      errorToast(error);
      dispatch({
        type: ACTION_TYPES.getAllAccountGroups,
        payload: []
      });
    }
  };
}

export async function addNewProductMapping(mappings) {
  try {
    const response = await axios.post(endpoints.addNewProductMapping, {
      ...mappings
    });
    if (response.status === 200) {
      toast.success(response.data.message);
    }
    return Promise.resolve(response);
  } catch (error) {
    errorToast(error);
    return Promise.reject(error);
  }
}

export function getAllProductMapping(teamId) {
  return async dispatch => {
    asyncStatus(loading('productMappings'));
    try {
      dispatch({
        type: ACTION_TYPES.getAllProductMappings,
        payload: []
      }); // cleaning up state before pushing new data
      const response = await axios.post(endpoints.getAllProductMappings, {
        teamId
      });
      if (response.status === 200) {
        const { productMappings } = response.data;

        // bug : if isGA is null, it creates validation error while updating prod mapping
        // fix : ensure isGA is 0 instead of null before storing in redux
        for (let mapping of productMappings) {
          if (!mapping.isGA) mapping.isGA = 0;
        }

        dispatch({
          type: ACTION_TYPES.getAllProductMappings,
          payload: productMappings
        });
        asyncStatus(success('productMappings'));
      }
    } catch (error) {
      dispatch({
        type: ACTION_TYPES.getAllProductMappings,
        payload: []
      });
      errorToast(error);
      asyncStatus(failed('productMappings'));
    }
  };
}

export async function deleteProductMapping({ teamId, productMappingId }) {
  try {
    const response = await axios.delete(endpoints.deleteProductMappings, {
      data: { teamId, productMappingId }
    });
    if (response.status === 200) {
      const { message } = response.data;
      toast.warn(message);
      return Promise.resolve(response);
    }
  } catch (error) {
    errorToast(error);
    return Promise.reject(error);
  }
}

export async function updateProductMapping(updateValues) {
  try {
    const response = await axios.put(
      endpoints.updateProductMappings,
      updateValues
    );
    if (response.status === 200) {
      const { message } = response.data;
      toast.success(message);
      return Promise.resolve(response);
    }
  } catch (error) {
    errorToast(error);
    return Promise.reject(error);
  }
}

export async function markAllAttending({ teamId, eventCode }) {
  try {
    let response = await axios.post(endpoints.markallattending, {
      teamId,
      eventCode
    });
    if (response.status === 200) {
      toast.success('Mark all as attending success');
      return Promise.resolve(response);
    }
  } catch (error) {
    errorToast(error);
    return Promise.resolve(error);
  }
}

export async function sellAllSeats({ teamId, eventCode }) {
  try {
    let response = await axios.post(endpoints.sellAllSeats, {
      teamId,
      eventCode
    });
    if (response.status === 200) {
      toast.success(response.data.message);
      return Promise.resolve(response);
    }
  } catch (error) {
    errorToast(error);
    return Promise.resolve(error);
  }
}

export async function buildAssignment({ teamId, eventCode }) {
  try {
    let response = await axios.post(endpoints.buildAssignment, {
      teamId,
      eventCode
    });
    if (response.status === 200) {
      toast.success(response.data.message);
      return Promise.resolve(response);
    }
  } catch (error) {
    errorToast(error);
    return Promise.resolve(error);
  }
}

export const renewAllSubscriber = async ({ teamId, planId }) => {
  try {
    const response = await axios.post(endpoints.renewAllSubscribers, {
      teamId,
      planId
    });

    // const mock = { status: 200, data: { message: 'Renewal Started' } };
    // const response = await (() =>
    //   new Promise(resolve => setTimeout(() => resolve(mock), 3000)))();

    if (response.status === 200) {
      toast.success(response.data.message);
      return Promise.resolve(response);
    }
  } catch (error) {
    errorToast(error);
    return Promise.reject(error);
  }
};

export const stopRenewAll = async ({ teamId, planId }) => {
  try {
    const response = await axios.post(endpoints.stopRenewAllSubscriber, {
      teamId,
      planId
    });

    // const mock = { status: 200, data: { message: 'Renewal Started' } };
    // const response = await (() =>
    //   new Promise(resolve => setTimeout(() => resolve(mock), 3000)))();

    if (response.status === 200) {
      toast.success(response.data.message);
      return Promise.resolve(response);
    }
  } catch (error) {
    errorToast(error);
    return Promise.reject(error);
  }
};

export const sendMassSms = async ({ teamId, message, planId }) => {
  try {
    let response = await axios.post(endpoints.sendMassSms, {
      teamId,
      SubscriptionPlanId: planId,
      message
    });

    // const mock = {
    //   status: 200,
    //   data: { message: 'Mass SMS sent succesfully' }
    // };
    // const response = await (() =>
    //   new Promise(resolve => setTimeout(() => resolve(mock), 3000)))();

    if (response.status === 200) {
      toast.success(response.data.message);
      return Promise.resolve(response);
    }
  } catch (error) {
    errorToast(error);
    return Promise.reject(error);
  }
};

export const setEventOptIn = async payload => {
  const response = await axios.post(endpoints.setOptIn, payload);
  return response;
};
export const addNewSubscriber = async payload => {
  try {
    const response = await axios.post(endpoints.addNewSubscriber, {
      ...payload
    });
    if (response.status === 200) {
      toast.success(response.data.message);
      return Promise.resolve(response);
    }
  } catch (error) {
    errorToast(error);
    return Promise.reject(error);
  }
};
