import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  CircularProgress,
  Box,
  Typography,
  makeStyles,
  DialogActions,
  Dialog
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { updateAssignments, sellAllSeats } from 'src/redux/actions';

export default function SellAllSeats({ selectedEvent, selectedPlan }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar: showAlert } = useSnackbar();
  const { activeTeam } = useSelector(state => state);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleConfirmDialog = type => {
    if (type === 'open') setConfirmOpen(true);
    if (type === 'close') setConfirmOpen(false);
  };

  const handleSellAllSeats = async () => {
    setLoading(true);
    const teamId = activeTeam.team_id;
    const planCode = selectedPlan.planCode;
    const eventCode = selectedEvent.eventCode;
    await sellAllSeats({ teamId, eventCode });
    await updateAssignments(teamId, eventCode);
    setConfirmOpen(false);
    setLoading(false);
  };

  return (
    <>
      <Box>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleConfirmDialog('open')}
        >
          Sell all Seats
        </Button>
      </Box>

      <Dialog open={confirmOpen}>
        <Box p={3}>
          <Box mb={3}>
            <Typography variant="h5" color="textPrimary">
              Are you sure want to sell all seats ?
            </Typography>
          </Box>
        </Box>
        <DialogActions>
          <Button color="primary" onClick={() => handleConfirmDialog('close')}>
            No
          </Button>

          <Button
            disabled={loading}
            color="primary"
            onClick={handleSellAllSeats}
          >
            Yes
            {loading && (
              <CircularProgress
                size={14}
                style={{ marginLeft: 5, color: 'gray' }}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  btn: {
    marginRight: 15
  }
}));
