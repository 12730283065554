import React from 'react';
import { SubscriptionPlanType } from 'src/types';
import { Grid, Box, Button, CircularProgress } from '@material-ui/core';
import { MatInput } from './MatInput';
import { useFormik } from 'formik';
import { CloseOutlined, SaveRounded } from '@material-ui/icons';
import { usePopup } from 'src/contexts/PopupContext';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState } from 'src/types';
import { addNewSubscriber, updateSubscribers } from 'src/redux/actions';

interface PropTypes {
  plan: SubscriptionPlanType;
  isSelected: boolean;
}

interface FormValueTypes {
  fullName: string;
  emailAddress: string;
  phoneNumber: string;
  accountNumber: string;
  seatCount: string | number;
  ccSequenceId: string | number;
}

export const AddSubscriberForm = (props: PropTypes): JSX.Element => {
  const popup = usePopup();
  const dispatch = useDispatch();
  const { activeTeam } = useSelector((state: ReduxState) => state);

  const handleAddSubscriber = async (formValues: FormValueTypes) => {
    const teamId = activeTeam.team_id;
    const eventCode = props.plan.planCode;
    const isPlanSelected = props.isSelected;
    try {
      await addNewSubscriber({ teamId, eventCode, ...formValues });
      if (isPlanSelected) {
        const subscribers = updateSubscribers({
          teamId,
          subscriberPlanId: props.plan.id
        });
        await dispatch(subscribers);
      }
      popup.close();
    } catch (error) {
      console.log(error);
    }
  };

  const initialValues: FormValueTypes = {
    fullName: '',
    emailAddress: '',
    phoneNumber: '',
    accountNumber: '',
    seatCount: '',
    ccSequenceId: ''
  };

  const formik = useFormik({
    initialValues,
    onSubmit
  });

  async function onSubmit(values: FormValueTypes) {
    formik.setSubmitting(true);
    await handleAddSubscriber(values);
    formik.setSubmitting(false);
  }

  return (
    <Box mt={1}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MatInput
              name="fullName"
              label="Full Name"
              placeholder="Insert Full Name"
              value={formik.values.fullName}
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <MatInput
              name="emailAddress"
              label="Email Address"
              placeholder="Insert email"
              value={formik.values.emailAddress}
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <MatInput
              name="phoneNumber"
              label="Phone Number"
              placeholder="Insert phone number"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <MatInput
              name="accountNumber"
              label="Account Number"
              placeholder="Insert Account Number"
              value={formik.values.accountNumber}
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <MatInput
              name="seatCount"
              label="Seat Count"
              placeholder="Insert seat count"
              value={formik.values.seatCount}
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <MatInput
              name="ccSequenceId"
              label="CC Sequence ID"
              placeholder="Insert cc sequence ID"
              value={formik.values.ccSequenceId}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Box style={{ marginTop: 5 }} />
          </Grid>

          <Grid item xs={6}>
            <Button
              disableElevation
              fullWidth
              variant="contained"
              size="small"
              startIcon={<CloseOutlined />}
              onClick={() => popup.close()}
            >
              Cancel
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button
              disableElevation
              fullWidth
              variant="contained"
              size="small"
              color="primary"
              type="submit"
              startIcon={<SaveRounded />}
              disabled={formik.isSubmitting}
              endIcon={formik.isSubmitting && <CircularProgress size={12} />}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
