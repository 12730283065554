import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';

export const withFormik = Form => ({ handleUpdatePlan }) => {
  // Distructuring props from redux
  const { selectedPlan: plan } = useSelector(s => s); // s = state

  // Initial values for formik
  const initialValues = {
    ...plan,
    isRecurring: plan.monthlyCost > 0,
    planCost: plan.monthlyCost > 0 ? plan.monthlyCost : plan.oneTimeCost
  };

  // Initialize formik
  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true
  });

  const { setFieldValue, setSubmitting, values } = formik;

  useEffect(() => {
    console.log(plan);
  }, [plan]);

  // Submit function
  async function onSubmit() {
    setSubmitting(true);
    setFieldValue('monthlyCost', 0);
    setFieldValue('oneTimeCost', 0);
    let { planCost, isRecurring, ...plan } = values;
    plan.monthlyCost = isRecurring ? planCost : 0;
    plan.oneTimeCost = isRecurring ? 0 : planCost;
    plan.lookupEvent = values.isExclusive ? values.lookupEvent : null;
    await handleUpdatePlan(plan);
    setSubmitting(false);
  }

  return <Form {...{ formik, plan }} />;
};
