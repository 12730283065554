import { endpoints } from './endpoints';
import axios from 'axios';

export const getSubscriptionPlans = async (teamId: number) => {
  const response = await axios.get(`${endpoints.subscriptionplan}/${teamId}`);
  const { subscriptionPlans } = response.data;
  return subscriptionPlans;
};

export const subscriptionPlanApi = { getSubscriptionPlans };
