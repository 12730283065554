import { UiStateRoot } from 'src/types';

export const uiState: UiStateRoot = {
  assignmentPagination: {
    currentPage: 1,
    dataPerPage: 100,
    totalPageCount: 0,
    totalDataCount: 0,
    dataPerPageOptions: [10, 25, 50, 100, 200, 500, 1000]
  }
};
