import React, { useEffect } from 'react';
import { Box, Button, makeStyles } from '@material-ui/core';
import { InputOutlined, TransitEnterexitRounded } from '@material-ui/icons';
import { EventType, ReduxState } from 'src/types';
import clsx from 'clsx';
import { usePopup } from 'src/contexts/PopupContext';
import { useDispatch, useSelector } from 'react-redux';
import { setEventOptIn, errorToast, getEvents } from 'src/redux/actions';
import { toast } from 'react-toastify';

interface PropTypes {
  event: EventType;
}

export const OptInOption = ({ event }: PropTypes): JSX.Element => {
  const styles = useStyles();
  const { activeTeam } = useSelector((state: ReduxState) => state);
  const dispatch = useDispatch();
  const popup = usePopup();
  const isOptInEnabled = !!event.isOptInEnabled;

  const handleOptIn = async (optIn: 0 | 1) => {
    const teamId = activeTeam.team_id;
    const eventCode = event.eventCode;
    const subscriptionPlanId = event.SubscriptionPlanId;
    popup.loading(true);
    try {
      const response = await setEventOptIn({ teamId, eventCode, optIn });
      const events = getEvents(teamId, subscriptionPlanId);
      // await dispatch(events);
      toast.success(response.data.message);
      popup.close();
    } catch (error) {
      errorToast(error);
    }
    popup.loading(false);
  };

  const confirmPopup = (type: 'enable' | 'disable') => {
    popup[type === 'enable' ? 'fireInfo' : 'fireWarning']({
      heading: `${type.toUpperCase()} OPT-IN`,
      title: `Are you sure want to ${type} opt in for ${event.description} ?`,
      description: `This action will ${type} opt-in feature for ${event.description}`
    });
    popup.onConfirm(() => handleOptIn(type === 'enable' ? 1 : 0));
  };

  return (
    <Box>
      {!isOptInEnabled ? (
        <Button
          disableElevation
          variant="contained"
          color="primary"
          size="small"
          startIcon={<InputOutlined />}
          className={styles.button}
          onClick={() => confirmPopup('enable')}
        >
          Enable opt-in
        </Button>
      ) : (
        <Button
          disableElevation
          variant="contained"
          color="primary"
          size="small"
          startIcon={<TransitEnterexitRounded />}
          className={clsx(styles.button, styles.warning)}
          onClick={() => confirmPopup('disable')}
        >
          Disable opt-in
        </Button>
      )}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  button: {
    padding: '2px 8px',
    fontSize: 12
  },
  warning: {
    backgroundColor: theme.palette.warning.main,

    '&:hover': {
      backgroundColor: theme.palette.warning.dark
    }
  }
}));
