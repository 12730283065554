import React, { useState } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Paper,
  Select,
  makeStyles
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { EventType, ReduxState } from 'src/types';
import { uiStateAction } from 'src/redux/uiState';
import { updateAssignments } from 'src/redux/actions';
import { DataPerPageSelector } from './DataPerPageSelector';
import { TotalDataCountViewer } from './TotalDataVCountViewer';

interface PropTypes {
  selectedEvent: EventType;
}

export const CustomPagination = ({ selectedEvent }: PropTypes): JSX.Element => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { uiState, assignments, activeTeam } = useSelector(
    (state: ReduxState) => state
  );
  const haveAssignments = assignments.length > 0;
  const { assignmentPagination } = uiState;
  const {
    currentPage,
    totalDataCount,
    totalPageCount,
    dataPerPage,
    dataPerPageOptions
  } = assignmentPagination;

  const handlePageChange = async page => {
    const { setAssignmentCurrentPage } = uiStateAction;
    const eventCode = selectedEvent.eventCode;
    const teamId = activeTeam.team_id;
    await dispatch(setAssignmentCurrentPage(page));
    await updateAssignments(teamId, eventCode);
  };

  const handleDataPerPageChange = async dataPerPage => {
    const { setAssignmentPerPage, setAssignmentCurrentPage } = uiStateAction;
    const eventCode = selectedEvent.eventCode;
    const teamId = activeTeam.team_id;
    await dispatch(setAssignmentCurrentPage(1));
    await dispatch(setAssignmentPerPage(dataPerPage));
    await updateAssignments(teamId, eventCode);
  };

  return (
    <Box display="flex" flexWrap="wrap">
      <Box>
        <TotalDataCountViewer dataCount={totalDataCount} />
      </Box>
      <Box ml={1}>
        <DataPerPageSelector
          disabled={!haveAssignments}
          options={dataPerPageOptions}
          selectedOption={dataPerPage}
          onChange={handleDataPerPageChange}
        />
      </Box>
      <Box ml={1}>
        <Pagination
          disabled={!haveAssignments}
          page={currentPage}
          count={totalPageCount}
          variant="outlined"
          shape="rounded"
          color="primary"
          size="small"
          onChange={(_, page) => handlePageChange(page)}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  inputLabel: {
    fontSize: 14,
    lineHeight: 1,
    transform: 'translate(0, -50%) scale(1) !important',
    top: '50%',
    backgroundColor: 'transparent',
    left: 10,

    '&.MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'none'
    }
  },
  select: {
    width: 200,

    '& .MuiSelect-root': {
      padding: 0,
      height: 26
    }
  }
}));
