import { uiStateActionTypes as actionTypes } from './actionTypes';

export const uiStateReducer = (state, { type, payload }) => {
  let { assignmentPagination } = state;

  switch (type) {
    case actionTypes.setAssignmentCurrentPage:
      assignmentPagination.currentPage = payload;
      return { assignmentPagination, ...state };
    case actionTypes.setAssignmentPerPage:
      assignmentPagination.dataPerPage = payload;
      return { ...state };
    case actionTypes.setAssignmentTotalCount:
      assignmentPagination.totalDataCount = payload;
      return { ...state };
    case actionTypes.setAssignmentTotalPageCount:
      assignmentPagination.totalPageCount = payload;
      return { ...state };
    default:
      return { ...state };
  }
};
