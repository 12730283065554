import env from 'dotenv';

const isDev = process.env.NODE_ENV === 'development';
const localhost = 'http://localhost:32290';
const envApiBase = process.env.API_BASE_URL;
const prodApiBase = 'https://subsjanitor.seasonshare.com';

export const BASE_URL = isDev ? localhost : envApiBase || prodApiBase;

export const endpoints = {
  // Auth apis
  register: 'auth/register',
  login: 'auth/login',

  // sms apis
  sendfirstsms: 'assignments/firstMessage',
  massmessage: 'admin/assignments/send_mass_message',

  // Team configurations
  teams: 'teams',
  teamsettings: 'teams/settings',
  updateTeamSettings: 'teams/settings',
  sportteams: 'teams/sports',

  // Subscription plans
  subscriptionplan: 'plans',
  updatePlan: 'plans',
  newsubscriptionplan: 'plans',
  deletesubscriptionplan: 'plans',
  renewAllSubscribers: 'subscriptionProduct/renewAllSubscribers',
  stopRenewAllSubscriber: 'subscriptionProduct/stopRenewal',
  setOptIn: 'subscriptionPlan/setOptIn',

  // Assignment apis
  assignments: 'assignments',
  generateassignment: 'assignments/assign',
  markasattending: 'assignments/markAttending',
  assignseat: 'assignments/assign/seat',
  sellseat: 'assignments/sell',
  markallattending: 'assignments/markAllAttending',
  sellAllSeats: 'assignments/sellAll',
  buildAssignment: 'assignments/build',
  paginatedAssignment: 'assignments/paginatedAssignments',

  // Event apis
  events: 'subscriptionPlan/events',
  previewevent: 'events/preview',
  addnewevent: 'events/save',
  editevent: 'events/edit',
  getAllEvents: 'events/all_events',

  // Subscriber api
  subscribers: 'subscribers',
  subscriberDetails: 'subscribers/details',
  sendMassSms: 'subscribers/massmessage',
  addNewSubscriber: 'subscribers/forceAddNew',

  // Inventory apis
  inventorysummary: 'inventory/summary',
  inventorydetails: 'inventory/details',

  // Ticket claim apis
  getAllClaims: 'claim/all_claims',

  // Ticket type apis
  getAllTicketTypes: 'ticketTypes/all',

  // Account group apis
  getAllAccountGroups: 'accountGroups/all',

  // Product mapping apis
  addNewProductMapping: 'productMappings/new',
  getAllProductMappings: 'productMappings/all',
  deleteProductMappings: 'productMappings/delete',
  updateProductMappings: 'productMappings/update'
};

for (let url in endpoints) {
  endpoints[url] = `${BASE_URL}/${endpoints[url]}`;
}

export default endpoints;
