import React from 'react';
import { Button, Grid, CircularProgress } from '@material-ui/core';

export default function Submit({ formik }) {
  let { isSubmitting, dirty } = formik;
  return (
    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button
        disableElevation
        color="primary"
        type="submit"
        variant="contained"
        size="large"
        disabled={isSubmitting || !dirty}
      >
        Save
        {isSubmitting && (
          <CircularProgress
            color="primary"
            size={12}
            style={{ marginLeft: 5 }}
          />
        )}
      </Button>
    </Grid>
  );
}
