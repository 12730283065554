import React, { useEffect } from 'react';
import { PeopleOutlineRounded } from '@material-ui/icons';
import { ActionButton } from 'src/components/SubscriptionPlanCard/ActionButton';
import { AddSubscriberForm } from './AddSubscriberForm';
import { SubscriptionPlanType } from 'src/types';
import { usePopup } from 'src/contexts/PopupContext';

interface PropTypes {
  plan: SubscriptionPlanType;
  isSelected: boolean;
}

export const AddSubscriberButton = ({
  plan,
  isSelected
}: PropTypes): JSX.Element => {
  const popup = usePopup();

  const addSubscriberPopup = () => {
    popup.fire({
      heading: 'Add Subscriber',
      title: `Add new subscriber to ${plan.planName}`,
      description: ``,
      hideButtons: true,
      icon: <PeopleOutlineRounded color="primary" />,
      component: <AddSubscriberForm {...{ plan, isSelected }} />,
      enableCloseIcon: true
    });
  };

  return (
    <ActionButton
      variant="regular"
      text="Add New Subscriber"
      onClick={() => addSubscriberPopup()}
      icon={<PeopleOutlineRounded />}
    />
  );
};
