import React from 'react';
import { makeStyles, lighten, Typography } from '@material-ui/core';
import clsx from 'clsx';

interface PropTypes {
  variant: 'success' | 'warning' | 'error';
  label: string;
}

export const DataChip = ({ variant, label }: PropTypes) => {
  const styles = useStyles(variant);

  return (
    <span className={clsx(styles.chipBody, styles[variant])}>
      <Typography variant="caption">{label}</Typography>
    </span>
  );
};

const useStyles = makeStyles(theme => ({
  chipBody: {
    width: 'fit-content',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
    borderRadius: 4,
    fontSize: 14,
    color: '#ffffff'
  },

  warning: {
    backgroundColor: lighten(theme.palette.warning.main, 0)
  },

  success: {
    backgroundColor: lighten(theme.palette.success.main, 0)
  },
  error: {
    backgroundColor: lighten(theme.palette.error.main, 0)
  }
}));
