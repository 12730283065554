import React, { useEffect, useState } from 'react';
import {
  Button,
  Icon,
  Typography,
  Card,
  Table,
  TableRow,
  TableCell,
  Box,
  TableHead,
  TableBody,
  makeStyles,
  TablePagination,
  Chip
} from '@material-ui/core';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import InsertChartOutlinedRoundedIcon from '@material-ui/icons/InsertChartOutlinedRounded';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { v4 as uuid } from 'uuid';
import { getInventoryDetails } from 'src/redux/actions';
import { useSelector } from 'react-redux';

const DetailsTable = ({ detailsData }) => {
  const { enqueueSnackbar: showAlert } = useSnackbar();
  const { inventoryDetails } = useSelector(state => state);

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <React.Fragment>
      {detailsData && (
        <Box mt={2}>
          <Card>
            <Box p={2} pb={1} display="flex">
              <Icon color="primary" style={{ marginRight: 5 }}>
                <InsertChartOutlinedRoundedIcon />
              </Icon>
              <Typography variant="h5" color="textPrimary">
                Inventory details
              </Typography>
            </Box>

            <PerfectScrollbar>
              <Box minWidth={1050}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Available Seat</TableCell>
                      <TableCell>Class name</TableCell>
                      <TableCell>Section name</TableCell>
                      <TableCell>Event code</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Number of seats</TableCell>
                      <TableCell>Quality</TableCell>
                      <TableCell>Team id</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(limit > 0
                      ? detailsData.slice(page * limit, page * limit + limit)
                      : detailsData
                    ).map((data, index) => (
                      <TableRow hover key={uuid()}>
                        {/* Available seat */}
                        <TableCell>
                          <Typography color="textSecondary" variant="subtitle2">
                            {data.available_seat
                              ? data.available_seat
                              : 'Not available'}
                          </Typography>
                        </TableCell>

                        {/* class name */}
                        <TableCell>
                          <Typography color="textSecondary" variant="subtitle2">
                            {data.class_name
                              ? data.class_name
                              : 'Not available'}
                          </Typography>
                        </TableCell>

                        {/* section name */}
                        <TableCell>
                          <Typography color="textSecondary" variant="subtitle2">
                            {data.section_name
                              ? data.section_name
                              : 'Not available'}
                          </Typography>
                        </TableCell>

                        {/* event code */}
                        <TableCell>
                          <Typography color="textSecondary" variant="subtitle2">
                            {data.event_code
                              ? data.event_code
                              : 'Not available'}
                          </Typography>
                        </TableCell>

                        {/* Price */}
                        <TableCell>
                          <Typography color="textSecondary" variant="subtitle2">
                            {data.price_2 ? data.price_2 : 'Not available'}
                          </Typography>
                        </TableCell>

                        {/* Number of seats */}
                        <TableCell>
                          <Typography color="textSecondary" variant="subtitle2">
                            {data.num_seats ? data.num_seats : 'Not available'}
                          </Typography>
                        </TableCell>

                        {/* Quality */}
                        <TableCell>
                          <Typography color="textSecondary" variant="subtitle2">
                            {data.quality ? data.quality : 'Not available'}
                          </Typography>
                        </TableCell>

                        {/* team id */}
                        <TableCell>
                          <Typography color="textSecondary" variant="subtitle2">
                            {data.team_id ? data.team_id : 'Not available'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
            <TablePagination
              component="div"
              count={detailsData.length}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </Card>
        </Box>
      )}
    </React.Fragment>
  );
};

export default DetailsTable;
