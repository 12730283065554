import React from 'react';
import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import { TextLogo } from 'src/components/TextLogo';
import { TeamSelector } from './TeamSelector';
import { SidebarToggler } from 'src/components/DashboardSidebar/SidebarToggler';

export const TopBar = (): JSX.Element => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Box display="flex" alignItems="center" flexDirection="row">
      <Box pl={sm ? 0 : 2}>
        <TextLogo hideText={xs && true} />
      </Box>

      {sm && <Box flexGrow={1} />}

      <Box pl={2}>
        <TeamSelector />
      </Box>

      {sm && (
        <Box pl={2}>
          <SidebarToggler />
        </Box>
      )}
    </Box>
  );
};
