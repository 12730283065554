import React, { ReactElement, useState, useEffect } from 'react';
import { usePopup } from 'src/contexts/PopupContext';
import {
  Box,
  Divider,
  Button,
  Grid,
  PropTypes,
  CircularProgress
} from '@material-ui/core';
import { RefreshRounded, SaveRounded, CloseRounded } from '@material-ui/icons';

type PropTypes = {
  formik: any;
  isUpdate?: boolean;
};

export default function ActionRow({
  formik,
  isUpdate
}: PropTypes): ReactElement {
  const popup = usePopup();
  const { isSubmitting } = formik;

  return (
    <>
      <Divider />
      <Box
        py={1}
        px={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box flexGrow={1} />

        <Grid container spacing={2} justify="flex-end">
          {isUpdate && (
            <Grid item xs={6} sm={6} md={3} lg={2}>
              <Button
                fullWidth
                disableElevation
                variant="contained"
                size="medium"
                startIcon={<CloseRounded />}
                onClick={() => popup.close()}
                disabled={isSubmitting}
              >
                Close
              </Button>
            </Grid>
          )}
          {!isUpdate && (
            <Grid item xs={6} sm={6} md={3} lg={2}>
              <Button
                fullWidth
                disableElevation
                variant="contained"
                size="medium"
                startIcon={<RefreshRounded />}
                onClick={formik.handleReset}
                disabled={isSubmitting}
              >
                Reset
              </Button>
            </Grid>
          )}

          <Grid item xs={6} sm={6} md={3} lg={2}>
            <Button
              fullWidth
              disableElevation
              variant="contained"
              size="medium"
              color="primary"
              type="submit"
              startIcon={<SaveRounded />}
              endIcon={isSubmitting && <CircularProgress size={14} />}
              disabled={isSubmitting}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
