import React from 'react';
import { Button, Box, CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncState } from 'src/hooks/useAsyncState';
import {
  EventType,
  ReduxState,
  SubscriptionPlanType,
  TeamType
} from 'src/types';
import { updateAssignments } from 'src/redux/actions';

interface PropTypes {
  selectedEvent: EventType;
  selectedPlan: SubscriptionPlanType;
}

export const RefreshAssignment = ({
  selectedEvent,
  selectedPlan
}: PropTypes): JSX.Element => {
  const dispatch = useDispatch();
  const status = useAsyncState('assignments');
  const { activeTeam } = useSelector((state: ReduxState) => state);

  const refreshAssignment = async () => {
    const { team_id: teamId }: TeamType = activeTeam;
    const { planCode }: SubscriptionPlanType = selectedPlan;
    const { eventCode }: EventType = selectedEvent;
    await updateAssignments(teamId, eventCode);
  };

  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      disabled={status.loading}
      startIcon={status.loading && <CircularProgress size={12} />}
      onClick={() => refreshAssignment()}
    >
      Refresh Assignment
    </Button>
  );
};
