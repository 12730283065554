import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import React, { useState, useEffect } from 'react';
import { DateRangePicker } from 'react-date-range';
import DateFnsUtils from '@date-io/date-fns';
import { addDays } from 'date-fns';
import {
  Box,
  Button,
  Dialog,
  makeStyles,
  Typography,
  Divider,
  Grid,
  colors,
  useTheme,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  TextField
} from '@material-ui/core';
import {
  DateRangeOutlined,
  Cancel,
  TouchApp,
  Replay,
  AccessTime,
  Edit
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

interface PropTypes {
  startDate: string;
  endDate: string;
  onConfirm: Function;
  onReset: Function;
  formik?: any;
}

export const DateRangeSelector = ({
  startDate,
  endDate,
  onConfirm,
  onReset,
  formik
}: PropTypes): JSX.Element => {
  const theme = useTheme();
  const styles = useStyles();
  const isError =
    (formik.errors['claimStartDate'] || formik.errors['claimEndDate']) &&
    formik.submitCount > 0;
  const getErrorMessage = () => {
    const startDateMsg = formik.errors['claimStartDate'];
    const endDateMsg = formik.errors['claimEndDate'];
    if (startDateMsg) return startDateMsg;
    if (endDateMsg) return endDateMsg;
  };
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const toggleDialog = (open: boolean) => setDialogOpen(open);
  const [dateRange, setDateRange] = useState<any>([
    {
      startDate: new Date() as any,
      endDate: new Date() as any,
      key: 'selection'
    }
  ]);

  const setInitialRange = () => {
    if (!(startDate || endDate)) return;
    setDateRange([
      {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        key: 'selection'
      }
    ]);
  };

  const confirmDateRange = () => {
    const { startDate, endDate } = dateRange[0];
    onConfirm({ startDate, endDate });
    toggleDialog(false);
  };

  const handleResetDateRange = () => {
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
      }
    ]);
    onReset();
  };

  const handleDateChange = item => {
    const selectedStartDate = new Date(item.selection.startDate);
    const selectedEndDate = new Date(item.selection.endDate);

    const startDate = new Date(dateRange[0]['startDate']);
    const endDate = new Date(dateRange[0]['endDate']);

    // Bug : setting only date using getDate() adding only date, not adding month and year.
    // Fix : adding date, month and year from selection and feed the range array.
    startDate.setDate(selectedStartDate.getDate());
    startDate.setMonth(selectedStartDate.getMonth());
    startDate.setFullYear(selectedStartDate.getFullYear());
    //
    endDate.setDate(selectedEndDate.getDate());
    endDate.setMonth(selectedEndDate.getMonth());
    endDate.setFullYear(selectedEndDate.getFullYear());

    const range = {
      ...dateRange[0],
      startDate: new Date(startDate),
      endDate: new Date(endDate)
    };

    setDateRange([range]);
  };

  const handleTimeChange = (date, fieldName) => {
    const selectedTime = new Date(date).getTime();
    const rangeDateField = new Date(dateRange[0][fieldName]).setTime(
      selectedTime
    );

    const range = {
      ...dateRange[0],
      [fieldName]: new Date(rangeDateField)
    };

    setDateRange([range]);
  };

  useEffect(setInitialRange, [startDate, endDate]);

  return (
    <>
      <Box>
        <Box p={1} className={styles.infoWrapper}>
          {!(startDate && endDate) ? (
            <Box>
              <Box className={styles.infoMsgWrapper} p={2} py={1}>
                <Box>
                  <Typography variant="h6" className={styles.infoTitle}>
                    Claim Start and End dates
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography className={styles.infoDescription}>
                    Please tap the below button to define claim start and end
                    date range
                  </Typography>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box>
              <List className={styles.infoList} dense={true}>
                <ListItem className={styles.infoListItem}>
                  <ListItemAvatar>
                    <DateRangeOutlined />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Claim Start Date"
                    secondary={moment(startDate).format(
                      'MMM DD, YYYY - hh:mm a'
                    )}
                  />
                </ListItem>
                <ListItem className={styles.infoListItem}>
                  <ListItemAvatar>
                    <DateRangeOutlined />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Claim End Date"
                    secondary={moment(endDate).format('MMM DD, YYYY - hh:mm a')}
                  />
                </ListItem>
              </List>
            </Box>
          )}

          <Box mt={1}>
            {!(startDate && endDate) ? (
              <Button
                size="small"
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => toggleDialog(true)}
                disableElevation
                startIcon={<TouchApp />}
              >
                Select date range
              </Button>
            ) : (
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Button
                    className={styles.resetButton}
                    size="small"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => handleResetDateRange()}
                    disableElevation
                    startIcon={<Replay />}
                  >
                    Clear
                  </Button>
                </Grid>

                <Grid item xs={6}>
                  <Button
                    size="small"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => toggleDialog(true)}
                    disableElevation
                    startIcon={<Edit />}
                  >
                    Edit
                  </Button>
                </Grid>
              </Grid>
            )}
          </Box>

          {isError && (
            <Box>
              <Typography className={styles.errorMessage} variant="caption">
                {getErrorMessage()}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Dialog open={dialogOpen} maxWidth="xs">
        <Box position="relative">
          <Cancel
            className={styles.closeIcon}
            onClick={() => toggleDialog(false)}
          />
          <Box p={2} py={1}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs="auto">
                <DateRangeOutlined color="primary" />
              </Grid>
              <Grid item xs="auto">
                <Typography variant="h5" color="textPrimary">
                  Select Claim Start and End date
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box p={1}>
            <Box>
              <DateRangePicker
                className={styles.datePicker}
                onChange={handleDateChange}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={dateRange}
                direction="horizontal"
                //   rangeColors={theme.palette.primary.main}
              />
            </Box>

            <Box mt={2} p={1}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TimePicker
                      style={{
                        maxWidth: '150px'
                      }}
                      // variant="inline"
                      className={styles.timePicker}
                      label="Start Time"
                      value={dateRange[0]['startDate']}
                      onChange={date => handleTimeChange(date, 'startDate')}
                      inputVariant="outlined"
                      size="small"
                      InputProps={{
                        endAdornment: <AccessTime />
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TimePicker
                      // variant="inline"
                      className={styles.timePicker}
                      label="End Time"
                      value={dateRange[0]['endDate']}
                      onChange={date => handleTimeChange(date, 'endDate')}
                      inputVariant="outlined"
                      size="small"
                      InputProps={{
                        endAdornment: <AccessTime />
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="text"
                  color="primary"
                  size="small"
                  className={styles.cancelButton}
                  onClick={() => toggleDialog(false)}
                  disableElevation
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => confirmDateRange()}
                  disableElevation
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  datePicker: {
    fontFamily: 'Roboto',

    '& .rdrDefinedRangesWrapper': {
      display: 'none'
    }
  },

  infoWrapper: {
    border: `1px solid ${colors.grey['300']}`,
    borderRadius: '5px'
  },

  infoMsgWrapper: {
    backgroundColor: colors.grey[100],
    borderRadius: 6
  },
  infoTitle: {
    color: theme.palette.text.secondary,
    fontSize: 16
  },
  infoDescription: {
    color: colors.grey[600],
    fontSize: 14,
    lineHeight: 1.2
  },

  infoList: {
    padding: 0
  },
  infoListItem: {
    backgroundColor: colors.grey[50],
    borderRadius: '4px',
    marginBottom: 8,

    '& .MuiListItemAvatar-root': {
      minWidth: 32,
      color: colors.grey[700]
    },

    '& .MuiListItemText-primary': {
      fontWeight: '600',
      color: colors.grey['600']
    },
    '& .MuiListItemText-secondary': {
      fontWeight: '500',
      color: theme.palette.primary.main
    }
  },

  closeIcon: {
    color: colors.grey['300'],
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    position: 'absolute',
    top: 8,
    right: 8,

    '&:hover': {
      color: colors.grey['400']
    }
  },

  cancelButton: {
    backgroundColor: colors.grey['200'],
    color: colors.grey['600'],

    '&:hover': {
      backgroundColor: colors.grey['300']
    }
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginLeft: 3,
    lineHeight: 1
  },
  resetButton: {
    backgroundColor: colors.grey['200'],
    color: colors.grey['600'],

    '&:hover': {
      backgroundColor: colors.grey['300']
    }
  },
  timePicker: {
    maxWidth: 150
  }
}));
