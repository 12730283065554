import React, { useEffect, useState } from 'react';
import { FormikState, useFormik } from 'formik';
import { addNewProductMapping } from 'src/redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/redux/model';
import { IProductMapping } from 'src/redux/model';
import { updateProductMapping, getAllProductMapping } from 'src/redux/actions';
import { usePopup } from 'src/contexts/PopupContext';
import { validationSchema } from './validationSchema';

// Fromik Initial values

// to do : add new field isGA
const initialValues = {
  // Event and Account group
  eventCode: '', // Checked
  gameType: '', // checked
  eventTitle: '',
  eventDate: '',
  accountGroupId: '', // checked

  // Prices and Ticket Types
  ticketTypeCode: '', // checked
  ticketTypeName: '', // checked
  price: '', // checked
  priceCode: '', // checked
  priceCodeDescription: '', // checked
  className: '', // checked
  serviceFee: '', // checked
  maxCount: '', // checked

  // Claim
  claimPlanCode: '', // checked
  claimStartDate: '',
  claimEndDate: '',
  claimTimeZone: '',
  isGA: 0
};

// Test values
//==========================================
// const initialValues = {
//   accountGroupId: '4654654',
//   eventId: '4654654',
//   eventCode: 'Test',
//   ticketTypeCode: 'update update update',
//   ticketTypeName: 'Test',
//   price: '64564565',
//   serviceFee: '654654',
//   priceCode: 'Test',

//   maxCount: 645646,
//   claimPlanCode: 'Test',
//   gameType: 'Test',
//   gameTitle: 'Test',
//   gameTime: '2021-10-11T08:10',
//   claimStartDate: '2021-10-13T17:15:00.000Z',
//   claimEndDate: '2021-10-24T17:15:00.000Z',
//   claimTimeZone: 'pst'
// };

type PropTypes = {
  isUpdate?: boolean;
  product?: IProductMapping;
};

export const withFormik = Form => ({
  isUpdate,
  product
}: PropTypes): JSX.Element => {
  const popup = usePopup();
  const dispatch = useDispatch();
  const { activeTeam } = useSelector((state: RootState) => state);

  const formik = useFormik({
    initialValues: isUpdate ? product : initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true
  });

  const { setSubmitting } = formik;

  async function onSubmit(values) {
    const teamId = activeTeam.team_id;
    setSubmitting(true);
    //
    if (isUpdate) {
      // Setting only fields that are matched with
      // initialValues schema for update
      const updateValues = {} as IProductMapping;
      for (let item in initialValues) {
        updateValues[item] = values[item];
      }
      popup.close();
      // Getting the mapping id
      const { id: productMappingId } = values;

      // calling the update api
      await updateProductMapping({
        teamId,
        productMappingId,
        ...updateValues
      });
      // refreshing mapping table
      await dispatch(getAllProductMapping(teamId));
    } else {
      await addNewProductMapping({ teamId, ...values });
      await dispatch(getAllProductMapping(teamId));
    }
    //
    setSubmitting(false);
  }

  return <Form {...{ formik }} />;
};
