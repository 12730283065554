import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container,
  Grid,
  Typography,
  Box,
  makeStyles
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { v4 as uuid } from 'uuid';
import Page from 'src/components/Page';
import Subscribers from './Subscribers';
import Revenue from './Revenue';
import Event from './Event';
import OptInRate from './OptInRate';
import QuickActionCard from './QuickActionCard';

import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import EmojiEventsOutlinedIcon from '@material-ui/icons/EmojiEventsOutlined';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const quickActions = [
  {
    title: 'Assign Seats',
    description: 'Make changes to seat assignment',
    media: AssignmentOutlinedIcon,
    mediaClass: 'brown',
    link: '/assignments'
  },
  {
    title: 'View subscriptions',
    description: 'Mange memberships',
    media: PeopleOutlineIcon,
    mediaClass: 'purple',
    link: '/subscribers'
  },
  {
    title: 'Plan Setup',
    description: 'Configure events/plan',
    media: EmojiEventsOutlinedIcon,
    mediaClass: 'blue',
    link: '/subscription_plans'
  }
];

const Dashboard = () => {
  const classes = useStyles();
  const { activeTeam } = useSelector(state => state);
  const [summary, setSummary] = useState(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar: showAlert } = useSnackbar();
  const summaryapi = 'dashboard/summary';
  useEffect(() => {
    if (activeTeam) {
      getDashboardSummary(activeTeam.team_id);
    }
  }, [activeTeam]);

  const getDashboardSummary = async teamId => {
    try {
      let response = await axios.get(summaryapi, { teamId });
      if (response.status === 200) {
        setSummary(response.data);
      }
    } catch (error) {
      showAlert('Something went wrong, please try again', { variant: 'error' });
    }
  };

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          {/* <Grid item sm={6} xl={3} xs={12}>
            <Subscribers apiData={summary.subscriberCount} />
          </Grid>
          <Grid item sm={6} xl={3} xs={12}>
            <Revenue apiData={summary.totalRevenue} />
          </Grid>
          <Grid item sm={6} xl={3} xs={12}>
            <Event apiData={summary.nextEvent} />
          </Grid>
          <Grid item sm={6} xl={3} xs={12}>
            <OptInRate apiData={summary.currentOptInRate} />
          </Grid> */}

          <Grid item xs={12}>
            <Box mt={3}>
              <Typography variant="h3" color="textPrimary">
                Quick Actions
              </Typography>
            </Box>
          </Grid>

          {quickActions.map(action => (
            <Grid item lg={4} sm={6} xl={4} xs={12} key={uuid()}>
              <QuickActionCard action={action} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
