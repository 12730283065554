import React from 'react';
import { Box, Typography, Divider } from '@material-ui/core';
import EmptyImage from 'src/img/empty.png';
export default function Empty({ caption }) {
  return (
    <Box width="100%" textAlign="center" p={4}>
      <img src={EmptyImage} style={{ width: 96 }} />
      <Typography gutterBottom variant="body1" color="textSecondary">
        {caption}
      </Typography>
    </Box>
  );
}
