import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  CircularProgress,
  Box,
  Typography,
  makeStyles,
  DialogActions,
  Dialog
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { updateAssignments, sendFirstSms } from 'src/redux/actions';

export default function({ selectedEvent, selectedPlan }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar: showAlert } = useSnackbar();
  const { activeTeam } = useSelector(state => state);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleConfirmDialog = type => {
    if (type === 'open') setConfirmOpen(true);
    if (type === 'close') setConfirmOpen(false);
  };

  const handleSendFirstSms = async () => {
    setLoading(true);
    try {
      let response = await sendFirstSms(
        activeTeam.team_id,
        selectedEvent.eventCode,
        selectedPlan.planCode
      );
      // loading(false);
      if (response.status === 200) {
        await updateAssignments(activeTeam.team_id, selectedEvent.eventCode);
        setConfirmOpen(false);
        showAlert('First sms sent successfully', { variant: 'success' });
      }
    } catch (error) {
      showAlert('Something went wrong, please try again', { variant: 'error' });
    }
    setLoading(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={() => handleConfirmDialog('open')}
      >
        SEND FIRST SMS
      </Button>

      <Dialog open={confirmOpen}>
        <Box p={3}>
          <Box mb={3}>
            <Typography variant="h5" color="textPrimary">
              Are you sure want to send first sms ?
            </Typography>
          </Box>
        </Box>
        <DialogActions>
          <Button color="primary" onClick={() => handleConfirmDialog('close')}>
            No
          </Button>

          <Button
            disabled={loading}
            color="primary"
            onClick={handleSendFirstSms}
          >
            Yes
            {loading && (
              <CircularProgress
                size={14}
                style={{ marginLeft: 5, color: 'gray' }}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  btn: {
    marginRight: 15
  }
}));
