import React, { ReactElement } from 'react';
import { Alert } from '@material-ui/lab';

export default function ExpiryWarning(): ReactElement {
  return (
    <Alert
      variant="filled"
      severity="warning"
      style={{
        width: 'fit-content',
        height: 30,
        display: 'flex',
        alignItems: 'center'
      }}
    >
      This event is in the past
    </Alert>
  );
}
