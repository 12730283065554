import React, { useEffect } from 'react';
import {
  Box,
  CircularProgress,
  makeStyles,
  Typography
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useAsyncState } from 'src/hooks/useAsyncState';
import { ReduxState } from 'src/types';
import { v4 as uuid } from 'uuid';
import { EventItem } from './EventItem';
import { EventType, SelectorFn } from 'src/types';
import { AsyncEmpty } from 'src/components/AsyncEmpty';

interface PropTypes {
  selectedEvent: EventType;
  selectEvent: SelectorFn<EventType>;
}

export const EventList = ({
  selectedEvent,
  selectEvent
}: PropTypes): JSX.Element => {
  const styles = useStyles();
  const status = useAsyncState('allEvents');
  const { allEvents } = useSelector((state: ReduxState) => state);
  const haveEvents = allEvents.length > 0;

  const handleFirstSelect = () => {
    if (!haveEvents || selectedEvent) return;
    selectEvent(allEvents[0]);
  };

  useEffect(handleFirstSelect, [allEvents]);

  return (
    <Box>
      <Box>
        <Typography variant="h6" color="textSecondary">
          Pass Events
        </Typography>
      </Box>
      <AsyncEmpty {...{ status }} haveData={haveEvents} />
      <Box className={styles.eventContainer} mt={1}>
        {(status.idle || status.success) &&
          haveEvents &&
          allEvents.map(event => (
            <EventItem
              key={uuid()}
              {...{ event, selectedEvent, selectEvent }}
            />
          ))}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  eventContainer: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    paddingLeft: 8
  }
}));
