import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { SellSeat } from './SellSeat';
import { ContextMenu } from './ContextMenu/ContextMenu';
import { useAsyncState } from 'src/hooks/useAsyncState';
import { useSelector } from 'react-redux';
import { ReduxState } from 'src/types';

interface PropTypes {
  id: number;
}

export const ActionItems = ({ id }: PropTypes) => {
  const status = useAsyncState('assignments');
  const { assignments } = useSelector((state: ReduxState) => state);
  const assignment = assignments.find(assignment => assignment.id === id);

  return (
    <>
      {assignment && (
        <>
          <Box>
            <ContextMenu {...{ assignment }} />
          </Box>
          <Box ml={1}>
            <SellSeat {...{ assignment }} />
          </Box>
        </>
      )}
    </>
  );
};
