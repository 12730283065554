import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { reducers } from './reducers';
import { globalState } from './globalState';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__;
  }
}

const initMiddlewares = () => {
  const isDev = process.env.NODE_ENV === 'development';

  const reduxDevtool =
    isDev && window?.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : null;

  return reduxDevtool
    ? compose(applyMiddleware(thunk), reduxDevtool)
    : compose(applyMiddleware(thunk));
};

export const store = createStore(reducers, globalState, initMiddlewares());
