import React, { useEffect } from 'react';
import {
  Box,
  Paper,
  Grid,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import { SectionHeader } from 'src/components/SectionHeader';
import SubscriptionPlanCard from 'src/components/SubscriptionPlanCard';
import { ConfirmationNumberOutlined, EventOutlined } from '@material-ui/icons';
import {
  MatStyleType,
  SubscriptionPlanType,
  ReduxState,
  SelectorFn
} from 'src/types';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { useAsyncState } from 'src/hooks/useAsyncState';
import { AsyncEmpty } from 'src/components/AsyncEmpty';

interface PropTypes {
  selectedPlan: SubscriptionPlanType;
  handleSelectPlan: SelectorFn<SubscriptionPlanType>;
}

export const PlanSection = ({ selectedPlan, handleSelectPlan }: PropTypes) => {
  //
  const styles: MatStyleType = useStyles();
  const { subscriptionPlans } = useSelector((state: ReduxState) => state);
  const status = useAsyncState('subscriptionPlans');
  const havePlans = subscriptionPlans.length > 0;

  return (
    <Paper variant="outlined">
      <SectionHeader
        title="Subscription Plans"
        icon={<ConfirmationNumberOutlined />}
        loading={status.loading}
      />

      <AsyncEmpty {...{ status }} haveData={havePlans} />

      {status.success && havePlans && (
        <Box p={2}>
          <Grid container spacing={3}>
            {subscriptionPlans?.map(plan => (
              <Grid item xs={12} sm={6} md={4} xl={3} key={uuid()}>
                <SubscriptionPlanCard
                  {...{ plan, selectedPlan, handleSelectPlan }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({}));
