import { AsyncStatus, AsyncStateRoot } from 'src/types';

type ActionTypes = Record<AsyncStatus, string>;

interface AsyncActionPayload {
  fieldName: keyof AsyncStateRoot;
  error?: Record<any, any>;
}

interface AsyncTrigger<R = AsyncStateRoot, P = AsyncActionPayload> {
  (arg: AsyncStatus): (
    fieldName: keyof R,
    error?: any
  ) => {
    type: ActionTypes[AsyncStatus];
    payload: P;
  };
}

// action types
export const actionTypes: ActionTypes = {
  idle: 'asyncState/idle',
  loading: 'asyncState/loading',
  success: 'asyncState/success',
  failed: 'asyncState/failed'
};

// actions
const trigger: AsyncTrigger = (status: AsyncStatus) => (fieldName, error?) => ({
  type: actionTypes[status],
  payload: error ? { fieldName, error } : { fieldName }
});

export const asyncAction = {
  idle: trigger('idle'),
  loading: trigger('loading'),
  success: trigger('success'),
  failed: trigger('failed')
};
