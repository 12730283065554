import React, { ReactElement } from 'react';
import {
  Box,
  makeStyles,
  Paper,
  Divider,
  Avatar,
  Grid,
  Typography
} from '@material-ui/core';

export default function SectionWrapper({
  children,
  title,
  icon
}): ReactElement {
  const styles = useStyles();
  return (
    <Paper className={styles.wrapper}>
      <Box>
        <Box
          p={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs="auto">
                <Avatar variant="rounded" className={styles.sectionNameAvatar}>
                  {icon}
                </Avatar>
              </Grid>
              <Grid item xs="auto">
                <Typography
                  variant="h5"
                  color="textPrimary"
                  className={styles.sectionTitle}
                >
                  {title}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box p={3}>{children}</Box>
    </Paper>
  );
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    minHeight: 300,
    transition: 'all 0.3s ease',
    height: '100%',
    borderRadius: 8,
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: '0 8px 16px rgb(0 0 0 / 10%)',
    '&:hover': {
      transform: 'translateY(-5px)'
    }
  },
  sectionTitle: {
    fontSize: 16
  },

  sectionNameAvatar: {
    backgroundColor: 'transparent',
    width: 32,
    height: 32,
    border: `1px solid ${theme.palette.divider}`,

    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main
    }
  }
}));
