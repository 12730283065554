import React, {
  ChangeEventHandler,
  ReactElement,
  useEffect,
  useState
} from 'react';
import { Grid, Select, FormControl, InputLabel } from '@material-ui/core';
import FieldsPanel from './FieldsPanel';
import { AccountTreeOutlined } from '@material-ui/icons';
import MatInput from './MatInput';
import MatCheckbox from './MatCheckbox';
import { ITeamSettings } from 'src/redux/model';

type PropTypes = {
  values: ITeamSettings;
  handleChange: ChangeEventHandler;
  handleSelect: any;
  checkTouched: Function;
  saveTeamSettings: Function;
  settingsLoading: boolean;
};

export default function CommunicationFields({
  values,
  handleChange,
  handleSelect,
  checkTouched,
  saveTeamSettings,
  settingsLoading
}: PropTypes): ReactElement {
  const [touched, setTouched] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSave = async () => {
    setIsSubmitting(true);
    await saveTeamSettings(values);
    setIsSubmitting(false);
  };
  useEffect(() => {
    let touched: boolean = checkTouched([
      'sms_notification',
      'email_notification',
      'account_manager_link',
      'purchase_confirmation_template',
      'register_link',
      'accept_request_link',
      'assignment_criteria'
    ]);
    setTouched(touched);
  }, [values]);

  return (
    <FieldsPanel
      title="COMMUNICATION"
      icon={<AccountTreeOutlined />}
      {...{ values, handleSave, settingsLoading, touched, isSubmitting }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MatCheckbox
            title="Enable SMS Notification ?"
            name="sms_notification"
            label={values?.sms_notification ? 'ENABLED' : 'DISABLED'}
            checked={!!values?.sms_notification}
            onChange={handleChange}
            disabled={settingsLoading || isSubmitting}
          />
        </Grid>

        <Grid item xs={12}>
          <MatCheckbox
            title="Enable Email Notification ?"
            name="email_notification"
            label={values?.email_notification ? 'ENABLED' : 'DISABLED'}
            checked={!!values?.email_notification}
            onChange={handleChange}
            disabled={settingsLoading || isSubmitting}
          />
        </Grid>

        <Grid item xs={12}>
          <MatInput
            name="account_manager_link"
            label="Account Manager Link"
            value={values?.account_manager_link || ''}
            onChange={handleChange}
            disabled={settingsLoading || isSubmitting}
          />
        </Grid>

        <Grid item xs={12}>
          <MatInput
            name="purchase_confirmation_template"
            label="Purchase Confirmation Email"
            value={values?.purchase_confirmation_template || ''}
            onChange={handleChange}
            disabled={settingsLoading || isSubmitting}
          />
        </Grid>

        <Grid item xs={12}>
          <MatInput
            name="register_link"
            label="Register Link"
            value={values?.register_link || ''}
            onChange={handleChange}
            disabled={settingsLoading || isSubmitting}
          />
        </Grid>

        <Grid item xs={12}>
          <MatInput
            name="accept_request_link"
            label="Accept Request Link"
            value={values?.accept_request_link || ''}
            onChange={handleChange}
            disabled={settingsLoading || isSubmitting}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel htmlFor="assignment-criteria">
              Assignment Criteria
            </InputLabel>
            <Select
              native
              name="assignment_criteria"
              value={values?.assignment_criteria || ''}
              onChange={handleSelect}
              disabled={settingsLoading || isSubmitting}
            >
              <option value=""></option>
              <option value="random">random</option>
              <option value="class_name">class_name</option>
              <option value="price_level">price_level</option>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </FieldsPanel>
  );
}
