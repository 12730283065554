import { AsyncStateRoot } from 'src/types';
import { actionTypes } from './actions';

// initial async state
export const asyncState: AsyncStateRoot = {
  subscriptionPlans: { status: 'idle', error: null },
  subscribers: { status: 'idle', error: null },
  assignments: { status: 'idle', error: null },
  events: { status: 'idle', error: null },
  productMappings: { status: 'idle', error: null },
  allEvents: { status: 'idle', error: null }
};

export const asyncStateReducer = (state, { type, payload }) => {
  switch (type) {
    case actionTypes.idle:
      state[payload.fieldName].status = 'idle';
      return { ...state };
    case actionTypes.loading:
      state[payload.fieldName].status = 'loading';
      return { ...state };
    case actionTypes.success:
      state[payload.fieldName].status = 'success';
      return { ...state };
    case actionTypes.failed:
      state[payload.fieldName].status = 'failed';
      state[payload.fieldName].error = payload.error;
      return { ...state };
    default:
      return { ...state };
  }
};
