import React, { useState, useEffect } from 'react';
import Page from 'src/components/Page';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container,
  Grid,
  Paper,
  Box,
  Typography,
  makeStyles,
  Divider,
  Button
} from '@material-ui/core';
import Sidebar from './Sidebar';
import PlanDetails from './PlanDetails';
import EventTable from './EventTable';
import {
  getSubscriptionPlans,
  updateSelectedPlan,
  getEvents,
  getSportTeams
} from 'src/redux/actions';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SubscriptionsView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeTeam, subscriptionPlans, selectedPlan, events } = useSelector(
    state => state
  );

  useEffect(() => {
    if (activeTeam) {
      getSubscriptionPlans(activeTeam.team_id);
    }
  }, [activeTeam]);

  useEffect(() => {
    if (activeTeam && selectedPlan) {
      getEvents(activeTeam.team_id, selectedPlan.id);
    }
  }, [activeTeam, selectedPlan]);

  return (
    <Page className={classes.root} title="Subscription Plan Setup">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box>
              <Typography variant="h3" color="textPrimary">
                Seat Assignments
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Sidebar />
          </Grid>
          <Grid item xs={12} lg={9}>
            <Grid container spacing={3}>
              {selectedPlan && <PlanDetails />}

              {/* {events && events.length != undefined && events.length > 0 && (
                
              )} */}
              <Grid item xs={12}>
                <EventTable />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default SubscriptionsView;
