import React, { ReactElement, useState } from 'react';
import {
  Button,
  makeStyles,
  Dialog,
  Box,
  DialogActions,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { updateAssignments, buildAssignment } from 'src/redux/actions';
import { RootState } from 'src/redux/model';
import { useAsyncState } from 'src/hooks/useAsyncState';
import { EventType, SubscriptionPlanType } from 'src/types';

interface PropTypes {
  selectedEvent: EventType;
  selectedPlan: SubscriptionPlanType;
}

export default function BuildAssignment({
  selectedEvent
}: PropTypes): ReactElement {
  const status = useAsyncState('assignments');
  const dispatch = useDispatch();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { activeTeam } = useSelector((state: RootState) => state);
  const handleConfirmDialog = (open: boolean) => setConfirmOpen(open);

  const handleGenerate = async () => {
    const teamId = activeTeam.team_id;
    const eventCode = selectedEvent.eventCode;
    setLoading(true);
    await buildAssignment({
      teamId,
      eventCode
    });
    await updateAssignments(teamId, eventCode);
    setLoading(false);
    handleConfirmDialog(false);
  };

  return (
    <>
      <Button
        disableElevation
        variant="contained"
        color="primary"
        size="small"
        disabled={status.loading}
        startIcon={status.loading && <CircularProgress size={12} />}
        onClick={() => handleConfirmDialog(true)}
      >
        Build Assignment
      </Button>
      <Dialog open={confirmOpen}>
        <Box p={3}>
          <Box mb={3}>
            <Typography variant="h5" color="textPrimary">
              Are you sure want to build assignments ?
            </Typography>
          </Box>
        </Box>
        <DialogActions>
          <Button color="primary" onClick={() => handleConfirmDialog(false)}>
            No
          </Button>

          <Button disabled={loading} color="primary" onClick={handleGenerate}>
            Yes
            {loading && (
              <CircularProgress
                size={14}
                style={{ marginLeft: 5, color: 'gray' }}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
