import React, { useEffect } from 'react';
import {
  Paper,
  ListItem,
  ListItemText,
  List,
  Box,
  Button,
  Divider,
  Typography,
  makeStyles,
  Chip
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '5px 9px',
    textTransform: 'none',
    width: '100%',
    borderRadius: 3
  }
}));

function Plans({ plans, selectedPlan, handlePlanSelection }) {
  const classes = useStyles();

  return (
    <Paper variant="outlined" className={classes.root}>
      <Box p={2}>
        <Typography variant="h5">All Plans</Typography>
      </Box>
      <Divider />
      <Box p={1} pt={0} pb={0}>
        <List>
          {plans &&
            plans.map(plan => (
              <ListItem
                key={plan.id}
                disableGutters
                button
                className={classes.button}
                selected={plan.id === selectedPlan.id ? true : false}
                onClick={() => handlePlanSelection(plan)}
              >
                <ListItemText primary={plan.planName} />
                {plan.isAvailableForSale ? (
                  <span>
                    <Chip
                      label="Active"
                      size="small"
                      className={classes.chip}
                    />
                  </span>
                ) : (
                  ''
                )}
              </ListItem>
            ))}
        </List>
      </Box>
    </Paper>
  );
}

export default Plans;
