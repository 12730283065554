import { createMuiTheme, colors, fade } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createMuiTheme({
  palette: {
    background: {
      dark: '#fafafa',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: '#009FE8',
      contrastText: 'rgba(255, 255, 255, 1)'
    },
    secondary: {
      main: colors.indigo[500]
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    }
  },
  overrides: {
    MuiChip: {
      root: {
        backgroundColor: fade(colors.green['A400'], 0.2),
        fontWeight: 'bold',
        color: colors.green['A700'],
        pointerEvents: 'none',
        paddingLeft: 8,
        paddingRight: 8
      },
      colorPrimary: {
        backgroundColor: fade(colors.red['A400'], 0.2),
        color: colors.red['A700'],
        pointerEvents: 'none'
      },
      colorSecondary: {
        backgroundColor: fade(colors.amber['A400'], 0.2),
        color: colors.amber['A700'],
        pointerEvents: 'none'
      }
    },
    MuiInputLabel: {
      shrink: {
        backgroundColor: '#FFFFFF',
        paddingLeft: 8,
        paddingRight: 8
      }
    }
  },
  shadows,
  typography
});

export default theme;
