import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Paper,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Typography,
  Divider,
  Button,
  Dialog,
  OutlinedInput,
  InputLabel,
  FormControl,
  IconButton,
  Icon,
  DialogActions,
  CircularProgress
} from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionPlans } from 'src/redux/actions';
import moment from 'moment';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import axios from 'axios';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  list: {
    paddingTop: 0,
    paddingBottom: 0
  },
  listItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  listItemText: {
    width: '50%'
  },
  button: {
    padding: '0px 0px'
  }
}));

function Basic({ subscriber, getSubscriberDetails }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { subscriptionPlans, activeTeam } = useSelector(state => state);
  const [listData, setListData] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const { enqueueSnackbar: showAlert } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSubmit = async event => {
    setLoading(true);
    event.preventDefault();
    let subscriberId = subscriber.id;
    let teamId = activeTeam.team_id;
    let endpoint = 'subscribers/edit-phone';
    try {
      let response = await axios.post(endpoint, {
        subscriberId,
        teamId,
        phoneNumber
      });

      if (response.status === 200) {
        showAlert(response.data.message, { variant: 'success' });
      }
    } catch (error) {
      showAlert(
        error?.response?.data?.message ||
          error?.response?.data ||
          'Something went wrong, please try again',
        { variant: 'error' }
      );
    }
    setLoading(false);
    getSubscriberDetails(teamId, String(subscriberId));
    handleDialogClose();
  };

  useEffect(() => {
    if (activeTeam) {
      getSubscriptionPlans(activeTeam.team_id);
    }
  }, [activeTeam]);

  useEffect(() => {
    if (subscriber && subscriptionPlans) {
      let planName = subscriptionPlans.find(
        plan => plan.id == subscriber.SubscriptionPlanId
      )?.planName;
      setListData({
        Name: subscriber.fullName,
        'Account #': subscriber.accountNumber,
        'Phone #': subscriber.phoneNumber,
        'Plan Name': planName,
        'Seat Count': subscriber.seatCount,
        'Purchased on': moment(subscriber.updatedAt).format('DD-MM-YYYY')
      });
    }
  }, [subscriber, subscriptionPlans]);

  const renderItems = listData => {
    let keys = Object.keys(listData);
    return keys.map((key, index) => {
      return (
        <React.Fragment key={uuid()}>
          <ListItem className={classes.listItem}>
            <span className={classes.listItemText}>
              <Typography variant="subtitle2" color="textPrimary">
                {key}
              </Typography>
            </span>
            <span
              className={classes.listItemText}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}
            >
              <Typography variant="subtitle2" color="textSecondary">
                {listData[key]}
              </Typography>

              {key === 'Phone #' && (
                <IconButton
                  size="small"
                  style={{ marginLeft: 15 }}
                  onClick={handleDialogOpen}
                >
                  <EditOutlinedIcon style={{ fontSize: 16 }} />
                </IconButton>
              )}
            </span>
          </ListItem>
          {index !== keys.length - 1 && <Divider />}
        </React.Fragment>
      );
    });
  };

  return (
    <Paper variant="outlined">
      <Box
        p={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5" color="textPrimary">
          Basic Info
        </Typography>

        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          size="small"
        >
          save
        </Button>
      </Box>
      <Divider />
      <Box>
        <List className={classes.list}>
          {listData && renderItems(listData)}
        </List>
      </Box>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <form onSubmit={handleSubmit}>
          <Box p={2}>
            <Box mb={2}>
              <Typography variant="h5" color="textPrimary">
                Update phone number
              </Typography>
            </Box>
            <Box>
              <FormControl variant="outlined" fullWidth size="small" required>
                <InputLabel htmlFor="update-phone">
                  Insert phone number
                </InputLabel>
                <OutlinedInput
                  type="text"
                  id="update-phone"
                  value={phoneNumber}
                  onChange={event => setPhoneNumber(event.target.value)}
                />
              </FormControl>
            </Box>
          </Box>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              cancel
            </Button>
            <Button type="submit" color="primary" autoFocus disabled={loading}>
              update
              {loading && (
                <CircularProgress size={12} style={{ marginLeft: 3 }} />
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Paper>
  );
}
export default Basic;
