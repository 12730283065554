import React, { ChangeEventHandler, ReactElement } from 'react';
import { TextField } from '@material-ui/core';

type PropTypes = {
  name: string;
  label: string;
  value: string | number;
  onChange: ChangeEventHandler;
  rows?: number;
  onBlur?: ChangeEventHandler;
  disabled?: boolean;
};

export default function MatInput({
  label,
  value,
  name,
  onChange,
  rows,
  onBlur,
  disabled
}: PropTypes): ReactElement {
  return (
    <TextField
      size="small"
      fullWidth
      variant="outlined"
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      multiline={rows > 0}
      rows={rows}
      onBlur={onBlur}
      disabled={disabled}
    />
  );
}
