import { globalState } from './globalState';
import ACTION_TYPES from './actionTypes';
import { asyncState, asyncStateReducer } from './asyncState';
import { ReduxState } from 'src/types';
import { uiStateReducer } from './uiState';

export const appStateReducer = (state, { type, payload }): ReduxState => {
  switch (type) {
    case ACTION_TYPES.updateTeams:
      state.teams = payload;
      state.activeTeam = payload[0];
      return { ...state };
    case ACTION_TYPES.updateActiveTeam:
      state.activeTeam = payload;
      return { ...state };
    case ACTION_TYPES.updateAssignments:
      state.assignments = payload;
      return { ...state };
    case ACTION_TYPES.updateSubscribers:
      state.subscribers = payload;
      return { ...state };

    case ACTION_TYPES.updateSelectedPlan:
      state.selectedPlan = payload;
      return { ...state };
    case ACTION_TYPES.updateSelectedEvent:
      state.selectedEvent = payload;
      return { ...state };

    case ACTION_TYPES.updateTeamSettings:
      state.teamSettings = payload;
      return { ...state };
    case ACTION_TYPES.updateInventorySummary:
      state.inventorySummary = payload;
      return { ...state };
    case ACTION_TYPES.updateInventoryDetails:
      state.inventoryDetails = payload;
      return { ...state };
    case ACTION_TYPES.updateSportTeams:
      state.sportTeams = payload;
      return { ...state };
    case ACTION_TYPES.getAllClaims:
      state.ticketClaims = payload;
      return { ...state };
    case ACTION_TYPES.getAllEvents:
      state.allEvents = payload;
      return { ...state };
    case ACTION_TYPES.getAllTicketTypes:
      state.ticketTypes = payload;
      return { ...state };
    case ACTION_TYPES.getAllAccountGroups:
      state.accountGroups = payload;
      return { ...state };
    case ACTION_TYPES.getAllProductMappings:
      state.productMappings = payload;
      return { ...state };
    case ACTION_TYPES.deleteProductMappings:
      state.productMappings = payload;
      return { ...state };

    // v2 addition
    // ---------------------
    case ACTION_TYPES.setSubscriptionPlans:
      return { ...state, subscriptionPlans: payload };

    case ACTION_TYPES.setEvents:
      return { ...state, events: payload };

    case ACTION_TYPES.setAllEvents:
      return { ...state, allEvents: payload };

    default:
      return { ...state };
  }
};

/**
 * State shape will remain same. For now asyncState is decoupled
 * and will be handled by it's own reducer. Gradually state should
 * become separated with different entities like
 * {
 *    app : ...
 *    business : ...
 *    ui : ....
 *    asyncState : ...
 * }
 */
const combinedReducers = (state = globalState, action) => {
  return {
    ...appStateReducer(state, action),
    asyncState: asyncStateReducer(state.asyncState, action),
    uiState: uiStateReducer(state.uiState, action)
  };
};

export const reducers = combinedReducers;
