import React, { useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TablePagination
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

export default function AccountGroupsTable({ accountGroups }) {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(100);

  const changePage = (event, index) => setPage(index);
  const changeLimit = event => setLimit(event.target.value);

  return (
    <>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Account group Id</TableCell>
                <TableCell>Account group name</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Updated At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(limit > 0
                ? accountGroups.slice(page * limit, page * limit + limit)
                : accountGroups
              ).map(group => (
                <TableRow>
                  <TableCell>{group.accountGroupId}</TableCell>
                  <TableCell>{group.accountGroupName}</TableCell>
                  <TableCell>{group.createdAt}</TableCell>
                  <TableCell>{group.updatedAt}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={accountGroups.length}
        onChangePage={changePage}
        onChangeRowsPerPage={changeLimit}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[25, 50, 100]}
      />
    </>
  );
}
