import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useState, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import theme from 'src/theme';
import routes from 'src/routes.js';
import { SnackbarProvider } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { updateTeams, updateAssignments, getSportTeams } from './redux/actions';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import DashboardLayout from 'src/layouts/DashboardLayout';
import { PopupProvider } from './contexts/PopupContext';
import { SidebarProvider } from './contexts/SidebarContext';

const App = ({ ...props }) => {
  const routing = useRoutes(routes);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { activeTeam } = useSelector(state => state);
  const [cookie] = useCookies();
  const [routeValidated, setRouteValidated] = useState(false);

  const validateRoute = () => {
    const session = cookie?.session;
    const isLoginRoute = location.pathname.includes('login');
    const jwt = session?.jwtToken;
    !jwt && navigate('/login');
    jwt && isLoginRoute && navigate('/assignments');
    setRouteValidated(true);
  };

  // useEffect(validateRoute, [cookie]);
  useEffect(validateRoute, [location.pathname, cookie]);

  useEffect(() => {
    dispatch(updateTeams());
  }, []);

  useEffect(() => {
    if (activeTeam) {
      dispatch(getSportTeams());
    }
  }, [activeTeam]);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <GlobalStyles />
        <SidebarProvider>
          <PopupProvider>{routeValidated && <>{routing}</>}</PopupProvider>
        </SidebarProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
