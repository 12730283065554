import React from 'react';
import {
  Avatar,
  Box,
  makeStyles,
  colors,
  Typography,
  Checkbox
} from '@material-ui/core';
import { CheckBox, EventRounded } from '@material-ui/icons';
import { EventType, SelectorFn } from 'src/types';
import clsx from 'clsx';

interface PropTypes {
  event: EventType;
  selectedEvent: EventType;
  selectEvent: SelectorFn<EventType>;
}

export const EventItem = ({
  event,
  selectEvent,
  selectedEvent
}: PropTypes): JSX.Element => {
  const styles = useStyles();
  const isSelected = selectedEvent?.id === event.id;

  return (
    <Box
      className={clsx(
        styles.eventItemWrapper,
        isSelected ? styles.selectedEvent : ''
      )}
      onClick={() => selectEvent(event)}
    >
      <Box display="flex" justifyContent="center" alignItems="center">
        {event.logoUrl ? (
          <img
            className={styles.eventImage}
            src={event.logoUrl}
            alt="event image"
          />
        ) : (
          <Avatar variant="circle" className={styles.avatar}>
            <EventRounded className={styles.icon} />
          </Avatar>
        )}
      </Box>
      <Box
        ml={1}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        className={styles.eventNameWrapper}
      >
        <Typography
          variant="h6"
          color="textSecondary"
          className={styles.eventName}
        >
          {event.description}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Checkbox
          className={styles.checkbox}
          color="primary"
          checked={isSelected}
          onClick={() => selectEvent(event)}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  eventItemWrapper: {
    padding: 5,
    display: 'grid',
    gridTemplateColumns: '32px 1fr 40px',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: colors.grey[100]
    }
  },

  selectedEvent: {
    backgroundColor: colors.grey[100]
  },

  eventImage: {
    width: 24,
    height: 24
  },
  eventNameWrapper: {
    overflow: 'hidden'
  },
  eventName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: 1
  },
  avatar: {
    width: 24,
    height: 24,
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.divider}`
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: 14
  },

  checkbox: {
    padding: 0
  }
}));
