import React, { useEffect, useState } from 'react';
import GenerateAssignments from './GenerateAssignments';
import { Box, makeStyles, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useSelector } from 'react-redux';
import SendMassSms from './SendMassSms';
import SendFirstSms from './SendFirstSms';
import QueueSystem from 'src/components/QueueSystem';
import MarkAllAttending from './MarkAllAttending';
import SellAllSeats from './SellAllSeats';
import ExpiryWarning from './ExpiryWarning';
import BuildAssignment from './BuildAssignment';
import { RefreshAssignment } from './RefreshAssignment';

export default function PageActions({ selectedEvent, selectedPlan }) {
  const classes = useStyles();
  const { activeTeam, assignments } = useSelector(state => state);
  const [isEventExpired, setIsEventExpired] = useState();
  const haveAssignments = assignments?.length > 0;

  // Checking event expiration
  useEffect(() => {
    if (selectedEvent && activeTeam) {
      let eventDate = new Date(
        `${selectedEvent.date} ${selectedEvent.time}`
      ).getTime();

      let currentDate = new Date().getTime();

      let dif = (eventDate - currentDate) / (1000 * 60 * 60 * 24);

      if (dif >= 0) {
        setIsEventExpired(true);
      } else {
        setIsEventExpired(false);
      }
    }
  }, [selectedEvent, activeTeam]);

  return (
    <Box
      mt={1}
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      height="100%"
      className={classes.btnWrapper}
    >
      {/* <StartAssignment />
            <StopAssignment />
            <StopQueue /> */}

      {!isEventExpired && <ExpiryWarning />}

      {isEventExpired && (
        <Grid container spacing={1}>
          <Grid item xs="auto">
            <QueueSystem {...{ selectedPlan, selectedEvent }} />
          </Grid>
          <Grid item xs="auto">
            <GenerateAssignments {...{ selectedPlan, selectedEvent }} />
          </Grid>
          {haveAssignments && (
            <Grid item xs="auto">
              <RefreshAssignment {...{ selectedPlan, selectedEvent }} />
            </Grid>
          )}
          {!haveAssignments && (
            <Grid item xs="auto">
              <BuildAssignment {...{ selectedPlan, selectedEvent }} />
            </Grid>
          )}

          <Grid item xs="auto">
            <SendFirstSms {...{ selectedPlan, selectedEvent }} />
          </Grid>
          <Grid item xs="auto">
            <SendMassSms {...{ selectedPlan, selectedEvent }} />
          </Grid>
          <Grid item xs="auto">
            <MarkAllAttending {...{ selectedPlan, selectedEvent }} />
          </Grid>
          <Grid item xs="auto">
            <SellAllSeats {...{ selectedPlan, selectedEvent }} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  btnWrapper: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      minHeight: 40
    }
  }
}));
