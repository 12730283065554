import * as yup from 'yup';

const errors = {
  string: 'This field should be a string',
  number: 'This field should be a number',
  required: 'This field is required',
  time: 'This field should be a valid date and time'
};

export const validationSchema = yup.object().shape({
  // Event and Account group
  eventCode: yup.string().required(errors.required),
  gameType: yup
    .string()
    .nullable()
    .notRequired(),
  eventTitle: yup.string().required(errors.required),
  eventDate: yup.string().required(errors.required),
  accountGroupId: yup
    .number()
    .required(errors.required)
    .typeError(errors.number),

  // Prices and Ticket Types
  ticketTypeCode: yup.string().required(errors.required),
  ticketTypeName: yup.string().required(errors.required),
  price: yup
    .number()
    .required(errors.required)
    .typeError(errors.number),
  priceCode: yup
    .string()
    .nullable()
    .notRequired(),
  priceCodeDescription: yup
    .string()
    .nullable()
    .notRequired(),
  className: yup
    .string()
    .nullable()
    .notRequired(),
  serviceFee: yup
    .number()
    .required(errors.required)
    .typeError(errors.number),
  maxCount: yup
    .number()
    .required(errors.required)
    .typeError(errors.number),

  // Claim
  claimPlanCode: yup.string(errors.string).required(errors.required),
  claimStartDate: yup
    .string()
    .nullable()
    .notRequired(),
  claimEndDate: yup
    .string()
    .nullable()
    .notRequired(),
  claimTimeZone: yup
    .string()
    .nullable()
    .notRequired(),
  isGA: yup.number().oneOf([0, 1])
});
