import React, { useState, useEffect } from 'react';
import {
  Dialog,
  Grid,
  TextField,
  makeStyles,
  Box,
  Typography,
  Divider,
  Button,
  CircularProgress,
  IconButton
} from '@material-ui/core';
import * as yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { CancelOutlined } from '@material-ui/icons';
import { updateTeams } from 'src/redux/actions';
import { useDispatch } from 'react-redux';
import { BASE_URL } from 'src/redux/endpoints';
import { AddOutlined } from '@material-ui/icons';

const validationSchema = yup.object().shape({
  team_id: yup.string().min(2, 'Please insert a valid team id'),
  team_name: yup.string().min(2, 'Please insert a valid team name'),
  team_city: yup.string().min(2, 'Please insert a valid team city'),
  team_mascot: yup.string().min(2, 'Please insert a valid team mascot'),
  account_manager_link: yup
    .string()
    .min(2, 'Please insert a valid account manager link')
});

export default function AddNewTeam() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleDialog = open => setOpen(open);
  const { enqueueSnackbar: showAlert } = useSnackbar();
  const dispatch = useDispatch();
  const initialValues = {
    team_id: '',
    team_name: '',
    team_city: '',
    team_mascot: '',
    account_manager_link: ''
  };
  const onSubmit = values => {
    addNewTeam(values);
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema
  });

  let { handleSubmit, dirty, isValid, resetForm } = formik;

  const addNewTeam = async values => {
    setLoading(true);
    let endpoint = `${BASE_URL}/teams/add`;
    try {
      let response = await axios.post(endpoint, { ...values });

      if (response.status === 200) {
        showAlert(response.data.message, { variant: 'success' });
        resetForm();
        await dispatch(updateTeams());
        handleDialog(false);
      }
    } catch (error) {
      showAlert(
        error?.response?.data?.message ||
          error?.response?.data ||
          'Something went wrong, please try again',
        { variant: 'error' }
      );
    }
    setLoading(false);
  };

  return (
    <>
      <Button
        disableElevation
        size="small"
        variant="contained"
        color="primary"
        startIcon={<AddOutlined />}
        onClick={() => handleDialog(true)}
      >
        Add New Team
      </Button>
      <Dialog open={open}>
        <IconButton
          className={classes.closeIcon}
          onClick={() => handleDialog(false)}
        >
          <CancelOutlined />
        </IconButton>
        <Box p={2}>
          <Typography variant="h5" color="textPrimary">
            ADD NEW TEAM
          </Typography>
        </Box>
        <Divider />
        <Box p={4}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <MatInput label="TEAM ID" name="team_id" {...{ formik }} />
              </Grid>
              <Grid item xs={6}>
                <MatInput label="TEAM NAME" name="team_name" {...{ formik }} />
              </Grid>
              <Grid item xs={6}>
                <MatInput label="TEAM CITY" name="team_city" {...{ formik }} />
              </Grid>
              <Grid item xs={6}>
                <MatInput
                  label="TEAM MASCOT"
                  name="team_mascot"
                  {...{ formik }}
                />
              </Grid>

              <Grid item xs={12}>
                <MatInput
                  label="ACCOUNT_MANAGER_LINK"
                  name="account_manager_link"
                  {...{ formik }}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  size="medium"
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!(isValid && dirty) || loading}
                >
                  Submit
                  {loading && (
                    <CircularProgress
                      size={12}
                      style={{ color: 'white', marginLeft: 5, marginTop: -2 }}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Dialog>
    </>
  );
}

const MatInput = ({ label, name, formik }) => {
  const { values, errors, handleChange } = formik;
  return (
    <TextField
      fullWidth
      required
      variant="outlined"
      name={name}
      label={label}
      size="small"
      value={values[name]}
      error={errors[name] && true}
      helperText={errors[name]}
      onChange={handleChange}
    />
  );
};

const useStyles = makeStyles(theme => ({
  closeIcon: {
    position: 'absolute',
    top: 3,
    right: 8
  }
}));
