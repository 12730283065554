import React, { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  Button,
  CircularProgress,
  colors,
  Typography,
  Divider,
  makeStyles,
  DialogActions,
  IconButton,
  Badge
} from '@material-ui/core';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { ClearAllOutlined, CancelOutlined } from '@material-ui/icons';
import QButton from './QButton';
import { BASE_URL } from 'src/redux/endpoints';
import MailIcon from '@material-ui/icons/Mail';

// queue systems endpoints
const endpoints = {
  qstatus: {
    method: 'post',
    url: `${BASE_URL}/assignments/queueStatus`
  },
  qstart: {
    method: 'post',
    url: `${BASE_URL}/assignments/startQueues`
  },
  qstop: {
    method: 'post',
    url: `${BASE_URL}/assignments/stopQueues`
  },
  assignStart: {
    method: 'post',
    url: `${BASE_URL}/assignments/startAssignments`
  },
  assignStop: {
    method: 'post',
    url: `${BASE_URL}/assignments/stopAssignment`
  },
  replyStart: {
    method: 'post',
    url: `${BASE_URL}/assignments/startReply`
  },
  replyStop: {
    method: 'post',
    url: `${BASE_URL}/assignments/stopReply`
  },
  fillAssignment: {
    method: 'post',
    url: `${BASE_URL}/assignments/fillAssignment`
  },
  emptyReply: {
    method: 'post',
    url: `${BASE_URL}/assignments/emptyReply`
  },
  emptyAssignment: {
    method: 'post',
    url: `${BASE_URL}/assignments/emptyAssignment`
  }
};

// Helper functions to fetch api responses
async function apiCall(options, callback) {
  let { method, url, payload } = options;
  try {
    let response = await axios[method](url, payload);
    if (response.status === 200) {
      return Promise.resolve(callback(response));
    }
  } catch (error) {
    let toast = options.toast;
    toast(
      error?.response?.data?.message ||
        error?.response?.data ||
        'Something went wrong, please try again',
      { variant: 'error' }
    );
  }
}

export default function QueueSystem({ selectedPlan, selectedEvent }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const { activeTeam } = useSelector(state => state);
  const [apis, setApis] = useState(null);
  let { enqueueSnackbar: toast } = useSnackbar();

  // Qstatus state
  const [replyProducer, setReplyProducer] = useState(false);
  const [replyConsumer, setReplyConsumer] = useState(false);
  const [assignProducer, setAssignProducer] = useState(false);
  const [assignConsumer, setAssignConsumer] = useState(false);

  // Qsize state
  const [assignmentSize, setAssignmentSize] = useState(0);
  const [replySize, setReplySize] = useState(0);

  // Toggle dialog
  const handleDialog = open => {
    setDialogOpen(open);
    open && generateQstatus();
  };

  // setting apis with team id and toast;
  useEffect(() => {
    if (activeTeam && selectedPlan && selectedEvent) {
      let teamId = activeTeam.team_id;
      let planCode = selectedPlan.planCode;
      let eventCode = selectedEvent.eventCode;
      for (let key in endpoints) {
        let endpoint = endpoints[key];
        endpoint.payload = { teamId, eventCode };
        endpoint.toast = toast;
      }
      setApis(endpoints);
    }
  }, [activeTeam, selectedPlan, selectedEvent]);

  // Setting queue status states for first time
  // useEffect(() => {
  //   if (apis) {
  //     generateQstatus();
  //   }
  // }, [apis]);

  const generateQstatus = async () => {
    setLoading(true);
    let status = await apiCall(apis.qstatus, res => {
      let {
        assignmentConsumer,
        assignmentProducer,
        replyConsumer,
        replyProducer,
        assignmentQueueSize,
        replyQueueSize
      } = res.data.queueStatus;

      setReplyProducer(Boolean(replyProducer));
      setReplyConsumer(Boolean(replyConsumer));
      setAssignProducer(Boolean(assignmentProducer));
      setAssignConsumer(Boolean(assignmentConsumer));
      setAssignmentSize(assignmentQueueSize);
      setReplySize(replyQueueSize);
      setLoading(false);
    });

    return Promise.resolve(status);
  };

  //   useEffect(() => {
  //     console.log('rp :', replyProducer);
  //     console.log('rc :', replyConsumer);
  //     console.log('ap :', assignProducer);
  //     console.log('ac :', assignConsumer);
  //   }, [replyProducer, replyConsumer, assignProducer, assignConsumer]);

  return (
    <>
      <Box>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          // disabled={loading}
          onClick={() => handleDialog(true)}
        >
          <ClearAllOutlined />
          Queue System
          {/* {loading && (
            <CircularProgress
              size={10}
              style={{ color: colors.grey[600], marginLeft: 5 }}
            />
          )} */}
        </Button>
      </Box>
      <Dialog open={dialogOpen} className={classes.dialog}>
        <Box className={classes.dialogContents}>
          <IconButton
            className={classes.closeButton}
            size="small"
            onClick={() => handleDialog(false)}
          >
            <CancelOutlined />
          </IconButton>
          <Box p={2} pt={1} pb={1}>
            <Typography variant="h4" color="textPrimary">
              Queue System
            </Typography>
          </Box>
          <Divider />

          {loading ? (
            <Box p={4} textAlign="center">
              <CircularProgress color="primary" size={24} />
            </Box>
          ) : (
            <Box p={2}>
              {apis && (
                <>
                  <Box p={2} className={classes.buttonWrapper}>
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      className={classes.sectionTitle}
                    >
                      Queue
                    </Typography>

                    {!replyProducer && !assignProducer && (
                      <Box>
                        <QButton
                          name="Start Queue"
                          api={apis.qstart}
                          dialogTitle="Are you sure want to start queue ?"
                          handleQDialog={handleDialog}
                          {...{ apiCall, generateQstatus }}
                        />
                      </Box>
                    )}

                    {replyProducer && assignProducer && (
                      <Box>
                        <QButton
                          name="Stop Queue"
                          api={apis.qstop}
                          dialogTitle="Are you sure want to stop queue ?"
                          handleQDialog={handleDialog}
                          {...{ apiCall, generateQstatus }}
                        />
                      </Box>
                    )}
                  </Box>

                  <Box mt={4} p={2} className={classes.buttonWrapper}>
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      className={classes.sectionTitle}
                    >
                      Assignment
                      <Badge
                        badgeContent={assignmentSize}
                        color="secondary"
                        variant="standard"
                        showZero={true}
                        className={classes.badge}
                      />
                    </Typography>

                    {assignProducer && !assignConsumer && (
                      <Box>
                        <QButton
                          name="Start Assignment"
                          api={apis.assignStart}
                          dialogTitle="Are you sure want to start assignment ?"
                          handleQDialog={handleDialog}
                          {...{ apiCall, generateQstatus }}
                        />
                      </Box>
                    )}

                    {assignProducer && assignConsumer && (
                      <Box ml={2}>
                        <QButton
                          name="Stop Assignment"
                          api={apis.assignStop}
                          dialogTitle="Are you sure want to stop assignment ?"
                          handleQDialog={handleDialog}
                          {...{ apiCall, generateQstatus }}
                        />
                      </Box>
                    )}
                    {assignProducer && (
                      <>
                        <Box ml={2}>
                          <QButton
                            name="Fill Assignment"
                            api={apis.fillAssignment}
                            dialogTitle="Are you sure want to fill assignment ?"
                            handleQDialog={handleDialog}
                            {...{ apiCall, generateQstatus }}
                          />
                        </Box>
                        <Box ml={2}>
                          <QButton
                            name="Empty Assignment"
                            api={apis.emptyAssignment}
                            dialogTitle="Are you sure want to empty assignment ?"
                            handleQDialog={handleDialog}
                            {...{ apiCall, generateQstatus }}
                          />
                        </Box>
                      </>
                    )}
                  </Box>

                  <Box mt={4} p={2} className={classes.buttonWrapper}>
                    <Typography
                      variant="h5"
                      color="textSecondary"
                      className={classes.sectionTitle}
                    >
                      Reply
                      <Badge
                        badgeContent={replySize}
                        color="secondary"
                        variant="standard"
                        showZero={true}
                        className={classes.badge}
                      />
                    </Typography>

                    {replyProducer && !replyConsumer && (
                      <Box>
                        <QButton
                          name="Start Reply"
                          api={apis.replyStart}
                          dialogTitle="Are you sure want to start reply ?"
                          handleQDialog={handleDialog}
                          {...{ apiCall, generateQstatus }}
                        />
                      </Box>
                    )}
                    {replyProducer && replyConsumer && (
                      <Box>
                        <QButton
                          name="Stop Reply"
                          api={apis.replyStop}
                          dialogTitle="Are you sure want to stop reply ?"
                          handleQDialog={handleDialog}
                          {...{ apiCall, generateQstatus }}
                        />
                      </Box>
                    )}

                    {replyProducer && (
                      <Box ml={2}>
                        <QButton
                          name="Empty Reply"
                          api={apis.emptyReply}
                          dialogTitle="Are you sure want to empty reply ?"
                          handleQDialog={handleDialog}
                          {...{ apiCall, generateQstatus }}
                        />
                      </Box>
                    )}
                  </Box>
                </>
              )}
            </Box>
          )}
        </Box>
      </Dialog>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  buttonWrapper: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 3,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'row'
  },
  sectionTitle: {
    position: 'absolute',
    top: 0,
    left: 15,
    transform: 'translateY(-50%)',
    backgroundColor: 'white'
  },
  dialog: {
    '& .MuiPaper-root': {
      minWidth: '400px !important'
    }
  },
  dialogContents: {
    position: 'relative'
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  badge: {
    marginTop: -5,
    marginLeft: 15
  }
}));
