import React, { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  Box,
  Chip
} from '@material-ui/core';
import axios from 'axios';
import Basic from './Basic';
import Actions from './Actions';
import { ISubscriber, RootState, ITeam } from 'src/redux/model';
import endpoints from 'src/redux/endpoints';

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

function SubscriberDetails() {
  const classes = useStyles();
  const location = useLocation();
  const { enqueueSnackbar: showAlert } = useSnackbar();
  const { activeTeam } = useSelector((state: RootState) => state);
  const [subscriber, setSubscriber] = useState(null);

  useEffect(() => {
    if (location && activeTeam) {
      let params = new URLSearchParams(location.search);
      let id = params.get('subscriberid');
      getSubscriberDetails(activeTeam.team_id, id);
    }
  }, [location, activeTeam]);

  const getSubscriberDetails = async (teamId, subscriberId) => {
    try {
      let subscriber: ISubscriber = await axios
        .post(endpoints.subscriberDetails, {
          teamId,
          subscriberId
        })
        .then(res => res.data.subscriber);
      setSubscriber(subscriber);
    } catch (error) {
      showAlert('Something went wrong, try again', { variant: 'error' });
    }
  };

  return (
    <Page className={classes.root} title="Subscriber Details">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <Typography variant="h3" color="textPrimary">
                Subscriber details {subscriber ? `(ID : ${subscriber.id})` : ''}
              </Typography>
              {subscriber && subscriber.status === 'ACTIVE' && (
                <Chip size="small" style={{ marginLeft: 15 }} label="ACTIVE" />
              )}
              {subscriber && subscriber.status === 'DEACTIVATED' && (
                <Chip
                  size="small"
                  style={{ marginLeft: 15 }}
                  label="DEACTIVATED"
                  color="primary"
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={3}>
            {subscriber && <Basic {...{ getSubscriberDetails, subscriber }} />}
          </Grid>
          <Grid item xs={3}>
            {subscriber && <Actions />}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
export default SubscriberDetails;
