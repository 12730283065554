import React, { useState, useRef } from 'react';
import {
  Popper,
  Grow,
  Paper,
  MenuList,
  ClickAwayListener,
  MenuItem,
  Button,
  makeStyles,
  lighten
} from '@material-ui/core';
import { ExpandMoreRounded } from '@material-ui/icons';
import { v4 as uuid } from 'uuid';

interface PropTypes {
  options: number[];
  selectedOption: number;
  disabled?: boolean;
  onChange?: Function;
}

export const DataPerPageSelector = ({
  options,
  selectedOption,
  disabled,
  onChange
}: PropTypes): JSX.Element => {
  const styles = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const toggleMenu = () => setOpen(!open);

  const onSelect = value => {
    setOpen(false);
    if (value === selectedOption) return;
    onChange && onChange(value);
  };

  return (
    <>
      <Button
        disabled={disabled}
        size="small"
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={() => toggleMenu()}
        className={styles.button}
        endIcon={<ExpandMoreRounded />}
      >
        {selectedOption} PER PAGE
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{
          zIndex: 999
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={toggleMenu}>
                <MenuList autoFocusItem={open} id="menu-list-grow">
                  {options.map(option => (
                    <MenuItem key={uuid()} onClick={() => onSelect(option)}>
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  button: {
    height: 26,
    fontSize: 12,
    fontWeight: 400
    // border: `1px solid ${theme.palette.primary.main}`,
    // backgroundColor: lighten(theme.palette.primary.main, 0.95),
    // color: theme.palette.primary.main
  }
}));
